import React, { ReactNode } from 'react';
import styled from 'styled-components';

const MARGINS = {
  xxlarge: 160,
  xlarge: 96,
  large: 64,
  medium: 48,
  small: 32,
  xsmall: 24,
  xxsmall: 16,
  xxxsmall: 12,
};

const SDiv = styled.div`
  margin: 0 0 ${({ $size }) => MARGINS[$size]}px;
`;

interface Row2Props {
  children: ReactNode;
  element?: 'div' | 'figcaption' | 'header';
  size?: 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | 'xxsmall' | 'xxxsmall';
}

export default function Row2({ element = 'div', size = 'medium', ...props }: Row2Props) {
  return <SDiv as={element} $size={size} {...props} />;
}
