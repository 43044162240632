import React from 'react';

import { Section } from '.';
import { Income } from './Zip';
import { $t } from 'src/utils';

const Prefooter = ({}) => {
  return <></>;
};

export const SlasherPrefooter = ({
  title = $t({
    id: 'slasher/prefooter.title',
    defaultMessage: 'See how much you could save',
  }),
  subtitle = $t({
    id: 'slasher/prefooter.subtitle',
    defaultMessage:
      'Find tax credits you may have missed to lower your health insurance premiums. ',
  }),
}) => {
  return (
    <Section small inset title={title} subtitle={subtitle} dark bg="var(--g-red-text)" center>
      <Income />
    </Section>
  );
};

export default Prefooter;
