import { MarketplaceEligibilityFragment, PxDependentFragment } from 'src/data';
import { $t, formatList } from 'src/utils';

interface CalcEligibilityInputs {
  marketplaceEligibility?: Array<MarketplaceEligibilityFragment>;
  dependents?: Array<PxDependentFragment>;
}

const getEligibilityByMember = ({ marketplaceEligibility, dependents }: CalcEligibilityInputs) => {
  if (!dependents || !marketplaceEligibility) return null;

  return dependents.map((dependent, idx) => ({
    ...dependent,
    ...marketplaceEligibility[idx],

    // chip eligible for people under 19 years old, medicaid for anyone older
    isMedicaid: marketplaceEligibility[idx].isMedicaidChip && dependent.age >= 19,
    isChip: marketplaceEligibility[idx].isMedicaidChip && dependent.age < 19,
  }));
};

export const calcEligibility = ({ marketplaceEligibility, dependents }: CalcEligibilityInputs) => {
  const eligibilityByMember = getEligibilityByMember({ marketplaceEligibility, dependents });

  const csr = marketplaceEligibility
    ? marketplaceEligibility?.filter((person) => person?.csr && person?.csr !== 'NONE')?.length > 0
    : false;
  const aptc = Math.ceil(marketplaceEligibility?.[0]?.aptc || 0);

  // returns a list of roles that are medicaid eligible
  const medicaidChipMembers = eligibilityByMember?.reduce((acc, m) => {
    if (m.isMedicaidChip && m.relation === 'SELF') {
      return [...acc, 'SELF'];
    }

    if (m.isMedicaidChip && m.relation === 'SPOUSE') {
      return [...acc, 'SPOUSE'];
    }

    if (m.isMedicaidChip && m.relation === 'CHILD') {
      if (acc.includes('CHILDREN')) {
        return acc;
      } else if (acc.includes('CHILD')) {
        const filtered = acc.filter((str) => str !== 'CHILD');
        return [...filtered, ['CHILDREN']];
      } else {
        return [...acc, 'CHILD'];
      }
    }

    return acc;
  }, []);

  // one of NONE, SOME, ALL
  const medicaidChipHousehold = eligibilityByMember?.every((m) => m.isMedicaidChip)
    ? 'ALL'
    : eligibilityByMember?.some((m) => m.isMedicaidChip)
      ? 'SOME'
      : 'NONE';

  const isMedicaid = eligibilityByMember?.some((m) => m.isMedicaid);
  const isChip = eligibilityByMember?.some((m) => m.isChip);

  const householdCopy = {
    SELF: $t({ id: 'health-household.SELF', defaultMessage: 'you' }),
    SPOUSE: $t({ id: 'health-household.SPOUSE', defaultMessage: 'your spouse' }),
    CHILD: $t({ id: 'health-household.CHILD', defaultMessage: 'your child' }),
    CHILDREN: $t({ id: 'health-household.CHILDREN', defaultMessage: 'your children' }),
    ALL: $t({ id: 'health-household.ALL', defaultMessage: 'your household' }),
  };

  const formattedMedicaidChipMembers =
    medicaidChipHousehold === 'ALL'
      ? householdCopy.ALL
      : formatList(medicaidChipMembers?.map((m) => householdCopy[m]));
  const formattedMedicaidChipPrograms =
    isMedicaid && isChip ? 'Medicaid/CHIP' : isMedicaid ? 'Medicaid' : isChip ? 'CHIP' : '';

  return {
    aptc,
    isMedicaidChip: isMedicaid || isChip,
    isMedicaid,
    isChip,
    csr,
    // medicaid computations
    medicaidChipHousehold,
    medicaidChipMembers,
    formattedMedicaidChipMembers,
    formattedMedicaidChipPrograms,
  };
};
