import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { AvatarGroup } from '.';

const SPost = styled.div`
  padding: 0 0 var(--side-margin);
`;

const SImgOuter = styled.div`
  width: 100%;
  height: ${(p) => (!p.horizontal ? '400px' : '')};
`;

const SImg = styled.div`
  width: 100%;
  height: ${(p) => (!p.horizontal ? '400px' : '100%')};
  max-width: ${(p) => (!p.horizontal ? '100%' : '500px')};
  background: var(--color-ink-subtle) url(${(p) => p.img}) center/cover;
`;

const GuidePost = ({ title, subtitle, image, authors, link }) => {
  return (
    <SPost>
      <Link to={link}>
        {!!image && (
          <SImgOuter>
            <SImg img={image} />
          </SImgOuter>
        )}
        <h4 className="large  serif">{title}</h4>
        {!!subtitle && (
          <p style={{ margin: 0 }} className="pl n o65">
            {subtitle}
          </p>
        )}
        {!!authors && <AvatarGroup people={authors} />}
      </Link>
    </SPost>
  );
};

export default GuidePost;
