import React from 'react';
import styled from 'styled-components';

import { SinglePlanView } from '../../px';

import { ReactComponent as Close } from 'src/iconic/close.svg';

export const ComparisonChartOriginal = ({ planIds, comparing, setComparing, onInfo }) => {
  return (
    <StyledComparables open={comparing} onClick={() => setComparing(!comparing)}>
      {!!comparing && (
        <div className="close" onClick={() => setComparing(!comparing)}>
          <Close color="var(--c-bg-0)" />
        </div>
      )}
      {planIds?.map((p, idx) => (
        <StyledComparable
          key={p.id}
          open={comparing}
          idx={idx}
          onClick={(e) => e.stopPropagation()}
        >
          <SinglePlanView id={p.id} hide={!comparing} onInfo={onInfo} />
        </StyledComparable>
      ))}
    </StyledComparables>
  );
};

export default ComparisonChartOriginal;

const StyledComparables = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--side-margin);
  padding-left: calc(var(--side-margin) + var(--side-margin) + 32px);
  width: 100vw;
  box-sizing: border-box;
  backdrop-filter: blur(${(p) => (p.open ? 12 : 0)}px);
  will-change: backdrop-filter, background;
  transition: 0.5s cubic-bezier(0.21, 0.49, 0.14, 1);
  pointer-events: ${(p) => (p.open ? 'all' : 'none')};
  overflow: scroll;
  bottom: 0;
  z-index: 9999999999999999999999999999999999999999;
  align-items: stretch;
  justify-content: flex-start;
  background: ${(p) =>
    p.open ? 'linear-gradient(to bottom, white, #dddedfdd 25%)' : 'transparent'};
  @media (max-width: 1080px) {
    padding: var(--side-margin);
    padding-left: var(--side-margin);
  }
  @media (prefers-color-scheme: dark) {
    background: ${(p) =>
      p.open ? 'linear-gradient(to bottom, black, #000000cc 25%)' : 'transparent'};
  }
  .close {
    background: var(--c-fg-0);
    height: 32px;
    width: 32px;
    position: fixed;
    left: var(--side-margin);
    top: var(--side-margin);
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.75;
    z-index: 9999999999999999999999999999999999999999;
    &:hover {
      opacity: 1;
    }
  }
`;

const StyledComparable = styled.div`
  background: var(--bgc);
  top: var(--s-nav-height);
  bottom: 0;
  width: 500px;
  max-width: calc(100vw - 2 * var(--side-margin));
  height: 100%;
  margin-right: 12px;
  overflow: scroll;
  border-radius: var(--br-large);
  flex-shrink: 0;
  transform: translateX(${(p) => (p.open ? '0' : (p.idx + 1) * 15)}vw);
  opacity: ${(p) => (p.open ? 1 : 0)};
  will-change: transform, opacity;
  transition: ${(p) => (p.idx + 1) * 0.05 + 0.25}s cubic-bezier(0.21, 0.49, 0.14, 1);
  .split-sidebar-inner {
    padding: 24px;
  }
  &:last-child {
    margin-right: 0;
  }
  @media (prefers-color-scheme: dark) {
    background: var(--bgca);
  }
`;
