import React from 'react';
import styled from 'styled-components';

export const TextButton = ({ id, text, icon, onPress, disabled, className }) => {
  return (
    <STextButton id={id} onClick={onPress} disabled={disabled} className={`pressable ${className}`}>
      {icon && React.createElement(icon, { stroke: 'var(--c-fg-0)', style: { marginRight: 4 } })}
      {text}
    </STextButton>
  );
};

const STextButton = styled.div`
  color: var(--c-fg-0);
  font-size: var(--fs-b3);
  font-weight: 500;
  cursor: pointer;
  margin: 0 0px;
  border-radius: 12px;
  padding: 0 12px 0 12px;
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 34px;
  box-sizing: border-box;
  line-height: 1;

  &:hover {
    background-color: var(--c-border-light);
  }
  &:active {
    background-color: var(--c-border);
  }
  &:first-of-type {
    margin-left: -12px !important;
  }
`;
