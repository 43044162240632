import React from 'react';
import { $t, formatMedicalName } from 'src/utils';
import { Annotation, Copy } from 'src/types';
import { AnnotationTooltip } from 'src/components';

const makeStr = (array, key, title, type): Copy => {
  if (!array || !key) return '';
  const first = formatMedicalName(array?.[0]?.[key]);
  if (array?.length < 2) {
    return first;
  } else {
    const others = $t({
      id: 'health-plan.others',
      defaultMessage: '{count} {count, plural, one {other} other {others}}',
      data: { count: array?.length - 1 },
    });

    return $t({
      id: 'health-plan.and',
      defaultMessage: '{first} and {rest}',
      data: {
        first,
        rest: (
          <AnnotationTooltip
            type={type}
            title={title}
            link={others}
            items={array.map((item) => formatMedicalName(item[key]))}
          />
        ),
      },
    });
  }
};

export const useAnnotation = (plan) => {
  const annotations: Array<Annotation> = [];

  const docsCovered = plan?.providerCoverage?.filter((i) => !!i.isCovered);
  const docsNotCovered = plan?.providerCoverage?.filter((i) => !i.isCovered);
  const drugsCovered = plan?.drugCoverage?.filter((i) => i.isCovered || i.isGenericCovered);
  const drugsNotCovered = plan?.drugCoverage?.filter((i) => !i.isCovered && !i.isGenericCovered);

  if (plan?.isLowestPremium) {
    annotations.push({
      id: 'lowest-premium',
      type: 'info',
      text: $t({ id: 'health-plan.lowest-premium', defaultMessage: 'Lowest premium' }),
    });
  }

  if (docsCovered?.length > 0) {
    annotations.push({
      id: 'covered-doctors',
      type: 'positive',
      text: $t({
        id: 'health-plan.accepted-by',
        defaultMessage: 'Accepted by {covered}',
        data: { covered: makeStr(docsCovered, 'name', 'Accepted', 'positive') },
      }),
      data: docsCovered,
    });
  }

  if (drugsCovered?.length > 0) {
    annotations.push({
      id: 'covered-drugs',
      type: 'positive',
      text: $t({
        id: 'health-plan.covers',
        defaultMessage: 'Covers {covered}',
        data: { covered: makeStr(drugsCovered, 'name', 'Covered', 'positive') },
      }),
      data: drugsCovered,
    });
  }

  if (docsNotCovered?.length > 0) {
    annotations.push({
      id: 'uncovered-doctors',
      type: 'negative',
      text: $t({
        id: 'health-plan.not-accepted-by',
        defaultMessage: 'Not accepted by {uncovered}',
        data: { uncovered: makeStr(docsNotCovered, 'name', 'Not Accepted', 'negative') },
      }),
      data: docsNotCovered,
    });
  }

  if (drugsNotCovered?.length > 0) {
    annotations.push({
      id: 'uncovered-drugs',
      type: 'negative',
      text: $t({
        id: 'health-plan.doesnt-cover',
        defaultMessage: "Doesn't cover {uncovered}",
        data: { uncovered: makeStr(drugsNotCovered, 'name', 'Not Covered', 'negative') },
      }),
      data: drugsNotCovered,
    });
  }

  return { annotations };
};
