import React, { CSSProperties, forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'src/components';
import { ReactComponent as ArrowRightSvg } from 'iconic/arrow-right.svg';
import { ReactComponent as ChevronRightSvg } from 'src/iconic/chevron-right.svg';

const styles = css`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  font-family: var(--ff-base);
  font-size: ${(p) => (p.large ? 'var(--fs-b1)' : p.small ? 'var(--fs-b3)' : 'var(--fs-b2)')};
  opacity: ${(p) => (p.disabled ? 0.35 : 1)};
  padding: 0;
  transition: 0.35s;
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--c-system);
  }

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      height: ${(p) => (p.large || p.small ? 'auto' : '48px')};
      background-color: ${(p) => (p.color ? p.color : 'var(--c-fg-0)')};
      border-radius: 999px;
      color: ${(p) => (p.textColor ? p.textColor : 'var(--c-bg-0)')} !important;
      font-weight: 500;
      padding: ${(p) => (p.large ? '16px 28px' : p.small ? '12px 20px' : '14px 24px')};
      user-select: none;
      @supports (-webkit-backdrop-filter: none) {
        backdrop-filter: blur(25px);
      }
      @media (max-width: 768px) {
        ${(p) => p.smallMobile && `padding: 12px 20px; font-size:var(--fs-b3)`}
      }
    `}

  ${({ variant }) =>
    variant === 'bar' &&
    css`
      width: 100%;
      background: var(--c-bg-2);
      border-radius: 16px;
      color: var(--c-fg-0);
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 16px 24px;
      text-align: left;
    `}

  ${({ variant }) =>
    variant === 'text' &&
    css`
      color: inherit;
      text-align: left;
      color: var(--c-fg-0);
    `}
`;

const SButton = styled.button`
  ${styles}
  line-height: 1;
`;

const SLink = styled(Link)`
  ${styles}
  // override Section a styling
  font-weight: 500 !important;
`;

const SChevronRightSvg = styled(ChevronRightSvg)`
  width: 25px;
  margin: 0 0 0 auto;
`;

interface ButtonProps {
  ariaLabel?: ReactNode;
  bouncy?: boolean;
  children: ReactNode;
  color?: string;
  disabled?: boolean;
  hex?: string;
  icon?: 'arrow' | 'chevron';
  large?: boolean;
  onPress?: () => void;
  small?: boolean;
  smallMobile?: boolean;
  style?: CSSProperties;
  textColor?: string;
  to?: string;
  type?: 'primary' | unknown;
  variant?: 'bar' | 'primary' | 'text';
}

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      ariaLabel,
      bouncy,
      children,
      color,
      disabled,
      hex,
      icon,
      large,
      onPress,
      small,
      smallMobile,
      style,
      textColor: propsTextColor,
      to,
      type = 'primary',
      variant = 'primary',
    }: ButtonProps,
    ref,
  ) => {
    const textColor = color === 'white' ? 'var(--c-always-dark)' : propsTextColor;
    const commonProps = {
      className: 'button pressable',
      color,
      disabled,
      large,
      small,
      smallMobile,
      textColor,
      variant,
    };
    let body = children;

    if (icon === 'arrow') {
      body = (
        <>
          {children}
          <ArrowRightSvg stroke="var(--c-bg-0)" aria-hidden />
        </>
      );
    }

    if (icon === 'chevron') {
      body = (
        <>
          {children}
          <SChevronRightSvg aria-hidden />
        </>
      );
    }

    if (to) {
      return (
        <SLink {...commonProps} to={to} ref={ref}>
          {body}
        </SLink>
      );
    }

    return (
      <SButton
        {...commonProps}
        aria-label={ariaLabel}
        bouncy={bouncy}
        onClick={onPress}
        hex={hex}
        type={type}
        style={style}
        ref={ref}
      >
        {body}
      </SButton>
    );
  },
);

Button.displayName = 'Button';
export default Button;
