import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Breadcrumbs, Container, SectionTitle } from '.';

const SSection = styled.section`
  margin: ${(p) => (p.inset ? 'var(--side-margin)' : '0')};
  border-radius: ${(p) => (p.inset ? 'var(--br-large)' : '0')};
  clear: both;
  color: var(--fg);
  padding-top: ${(p) => (p.raw ? '0px' : !p.top ? 'var(--side-margin)' : 'var(--height-nav-sm)')};
  padding-left: var(--side-margin);
  padding-right: var(--side-margin);
  text-align: ${(p) => (p.center ? 'center' : '')};
  background: ${(p) => (p.bgLight ? p.bgLight : p.bg ? p.bg : 'var(--c-bg-0)')};
  @media (prefers-color-scheme: dark) {
    background: ${(p) => (p.bg ? p.bg : 'var(--c-bg-0)')};
  }
  border-bottom: 0px dashed #ccc;
  overflow: ${(p) => (p.overflow ? 'visible' : 'hidden')};
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > p {
    margin-left: ${(p) => (p.center ? 'auto' : '')};
    margin-right: ${(p) => (p.center ? 'auto' : '')};
  }

  a {
    font-weight: 600;
  }
`;

const SSectionInner = styled.div`
  z-index: 9;
  pointer-events: ${(p) => (p.disable ? 'none' : 'all')};
  padding: calc(
      ${(p) => (p.raw ? 0 : p.small ? 0.5 : 1)} * var(--padding-section) +
        ${(p) => (p.top ? 'var(--height-nav)' : '0px')}
    )
    0 calc(${(p) => (p.raw ? 0 : p.small ? 0.5 : 1)} * var(--padding-section));
`;

const Section = ({
  children,
  center,
  narrow,
  ultranarrow,
  small,
  color,
  type = 'subtle',
  pretitle,
  nav,
  gradient,
  top,
  title,
  subtitle,
  large,
  id,
  wide,
  raw,
  dark,
  breadcrumbs,
  inset,
  precolor,
  centerTitle,
  texture,
  hex,
  byline,
  narrowTitle,
  url,
  bg,
  creatour,
  abs,
  bgLight,
  overflow,
}) => {
  const Cont = !!wide ? ({ children }) => <>{children}</> : Container;

  return (
    <div className={`${!!dark ? 'dark' : ''}`}>
      <SSection
        overflow={overflow}
        image={texture}
        inset={inset}
        id={id}
        nav={nav}
        center={center}
        small={small}
        color={color}
        type={type}
        gradient={gradient}
        top={top}
        raw={raw}
        hex={hex}
        bg={bg}
        creatour={creatour}
        bgLight={bgLight}
      >
        {abs}
        <SSectionInner breadcrumbs={breadcrumbs} disable={!!abs} raw={raw} small={small}>
          <Cont narrow={narrow} ultranarrow={ultranarrow}>
            {!!breadcrumbs && <Breadcrumbs links={breadcrumbs} />}

            {!!byline && (
              <div
                style={{
                  background: 'var(--g-cerise-light)',
                  marginTop: -48,
                  marginBottom: 48,
                  padding: 12,
                  borderRadius: 12,
                  backdropFilter: 'blur(20px)',
                  pointerEvents: 'all',
                }}
              >
                <fp
                  is="div"
                  style={{
                    margin: 0,
                    color: color,
                    opacity: 0.65,
                    fontWeight: 500,
                  }}
                >
                  <Link to={url}>{byline}</Link>{' '}
                </fp>
              </div>
            )}

            {!!title && (
              <SectionTitle
                precolor={precolor}
                color={color}
                sans={true}
                title={title}
                subtitle={subtitle}
                center={center || centerTitle}
                small={small}
                pretitle={pretitle}
                large={large}
                narrowTitle={narrowTitle}
                creatour={creatour}
              />
            )}
            {children}
          </Cont>
        </SSectionInner>
      </SSection>
    </div>
  );
};

export default Section;
