export { default as en } from './base/en.json';
export { default as es } from './base/es.json';

import english from './compiled/en.json';
import spanish from './compiled/es.json';

export const messages = {
  en: english,
  'en-US': english,
  es: spanish,
};
