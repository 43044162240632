import { Partner } from 'src/types';
import { inIframe } from './browser';

/**
 * Helper function for special logic to handoff to freelancers union
 * We should only handoff in NYC counties when partner is specified and we're in an iframe
 * @param param0
 * @returns
 */
export function shouldHandoffToFreelancersUnion({
  fips,
  partner,
}: {
  fips: string;
  partner: string;
}) {
  const EXCLUDED_FIPS = ['36061', '36005', '36047', '36081', '36085'];
  return EXCLUDED_FIPS.includes(fips) && partner === Partner.FreelancersUnion && inIframe();
}
