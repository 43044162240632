import { gql } from '../generated';

gql(`
  fragment PlanFields on HealthPlan {
    id
    name
    type
    hsaEligible
    benefitsURL
    brochureURL
    metalLevel
    premium
    premiumWithCredit
    ehbPremium
    hasDental
    isStandard
    issuer {
      id
      name
    }
    deductibles {
      amount
      familyCost
      type
      # csr
      # networkTier
    }
    qualityRating {
      available
      globalRating
      clinicalQualityManagementRating
      enrolleeExperienceRating
      planEfficiencyRating
    }
  }
`);

gql(`
  fragment PlanFieldsLong on HealthPlan {
    id
    name
    type
    hsaEligible
    benefitsURL
    networkURL
    metalLevel
    premium
    premiumWithCredit
    hasDental
    hasVision
    # simpleChoice
    isStandard
    # oopc
    issuer {
      id
      name
      individualURL
      # tollFree
      state
    }
    benefits {
      name
      covered
      explanation
      hasLimits
      limitUnit
      limitQuantity
      costSharings {
        coinsuranceOptions
        coinsuranceRate
        copayAmount
        copayOptions
        networkTier
        csr
        displayString
      }
    }
    deductibles {
      amount
      familyCost
      type
      # csr
      # networkTier
    }
    moops {
      amount
      familyCost
      type
      # csr
      # networkTier
    }
    qualityRating {
      available
      globalRating
      clinicalQualityManagementRating
      enrolleeExperienceRating
      planEfficiencyRating
    }
  }
`);

gql(`
  fragment CoverageFields on ScoredHealthPlan {
    providerCoverage {
      npi
      name
      isCovered
    }
    drugCoverage {
      rxcui
      name
      isCovered
      isGenericCovered
    }
  }
`);

export const PLANS = gql(`
  query PublicHealthPlans($id: ID!, $input: PlanSearchInput) {
    getPublicHealthExplorerData(id: $id) {
      id
      dependents {
        ...PXDependent
      }
      marketplaceEligibility {
        ...MarketplaceEligibility
      }
      plans(input: $input) {
        plans {
          id
          isCatchRecommended
          healthPlan {
            id
            ...PlanFields
          }
          ...CoverageFields
          scores {
            score
          }
        }
      }
    }
  }
`);

export const PLAN_FULL = gql(`
  query PublicHealthPlanDetails($id: ID!, $planID: ID!) {
    publicHealthPlanDetails(id: $id, planID: $planID) {
      ...PlanFieldsLong
    }
  }
`);
