import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Star } from 'src/iconic/star.svg';

const Container = styled.span`
  display: flex;
`;

/**
 * Shows set of 5 stars
 * Based on rating, fill or dont fill the icon
 */
const Rating = ({ rating }) => {
  const stars = Array.from(Array(5).keys());

  return (
    <Container>
      {stars.map((star) =>
        React.createElement(Star, {
          fill: star < rating ? '#FFD612' : 'var(--c-border)',
        }),
      )}
    </Container>
  );
};

export default Rating;
