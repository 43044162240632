import React from 'react';
import { metalColors } from 'src/utils';

import { ReactComponent as Standard } from '../../iconic/tag.svg';
import { ReactComponent as Vision } from '../../iconic/eye.svg';
import { ReactComponent as Dental } from '../../iconic/vanity.svg';
import { ReactComponent as Hsa } from '../../iconic/coins.svg';
import { ReactComponent as Network } from '../../iconic/compass.svg';
import { ReactComponent as Metal } from '../../iconic/circle.svg';
import { ReactComponent as Star } from '../../iconic/star.svg';

type IconName =
  | 'Standard'
  | 'Vision'
  | 'Dental'
  | 'HSA'
  | 'Network'
  | 'Metal'
  | 'Abortion'
  | 'Star';

interface IconProps {
  element: any;
  fill: boolean;
}

const ICONS: Record<IconName, IconProps> = {
  Standard: { element: Standard, fill: false },
  Vision: { element: Vision, fill: false },
  Dental: { element: Dental, fill: true },
  HSA: { element: Hsa, fill: false },
  Network: { element: Network, fill: false },
  Metal: { element: Metal, fill: true },
  Star: { element: Star, fill: true },
  Abortion: { element: Standard, fill: false },
  CatchNames: { element: Standard, fill: false },
};

export const PxIcon = ({ name, text, color, ...rest }) => {
  const metalColor = !!color ? null : metalColors[text?.toLowerCase()];
  const fill = ICONS[name]?.fill;

  return React.createElement(ICONS[name]?.element, {
    stroke: !fill ? (metalColor ? metalColor : color ? color : 'var(--c-fg-0)') : 'transparent',
    fill: !!fill ? (metalColor ? metalColor : color ? color : 'var(--c-fg-0)') : 'transparent',

    ...rest,
  });
};
