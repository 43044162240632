import { ENV } from 'src/utils';

export default {
  SIGN_UP: ENV.appSignUpUrl,

  SUPPORT: 'https://help.catch.co',

  FACEBOOK: 'https://facebook.com/catchbenefits',
  INSTAGRAM: 'https://instagram.com/catchbenefits',
  LINKEDIN: 'https://www.linkedin.com/company/catchbenefits',
  TWITTER: 'https://twitter.com/catchbenefits',

  HEALTHCARE_GOV: 'https://healthcare.gov',
};
