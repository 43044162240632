import React, { useId } from 'react';
import styled from 'styled-components';

/* Render a styled list of featured items */

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
`;

const StyledListItem = styled.li`
  border-top: 1px solid var(--c-border);
  list-style: none;
  padding: 16px 0;
  font-weight: 500;
`;

interface FeatureListProps {
  items: (string | React.ReactNode)[];
}

const FeatureList: React.FC<FeatureListProps> = ({ items }) => {
  const uniqueId = useId();
  return (
    <StyledList>
      {items &&
        items.map((item, index) => (
          <StyledListItem key={`${uniqueId}-${index}`}>{item}</StyledListItem>
        ))}
    </StyledList>
  );
};

export default FeatureList;
