import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import SessionContext from '../context/SessionContext';
import { $t } from 'src/utils';
import FilterInput from './FilterInput';
import { Segment, SegmentEvent } from 'src/lib';
import { useZipcode } from 'src/hooks/useZipcode';
import { useExplorer } from 'src/hooks/useExplorer';
import { Input, InputProps } from './Input';
import paths from 'src/constants/paths';

const SZip = styled.div`
  display: inline-flex;
  margin: auto;
  align-items: stretch;
`;

interface ZipProps {
  fromAetnaLP?: boolean;
  cta?: string;
  helpColor?: InputProps['helpColor'];
  placeholder?: string;
}

/**
 *
 * @todo proper zip handoff
 *
 */
const Zip = ({
  fromAetnaLP = false,
  cta = 'Get started',
  helpColor,
  placeholder = 'ZIP code',
}: ZipProps) => {
  const { pxId, adminPxLink, clearAetnaSession } = useContext(SessionContext);
  const { data, upsertExplorer, upserting } = useExplorer();
  const [hasTouched, setHasTouched] = useState(false);

  const { isValidLocation, zip, setZip, place } = useZipcode(data.zipcode, {
    assumeCounty: true,
  });

  const error = isValidLocation ? '' : 'Enter a valid zip code';

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isValidLocation) {
      await upsertExplorer({ zip, place }, false);
      Segment.track(SegmentEvent.PX_STARTED, { flow: 'Explorer', pxId, adminPxLink });
      if (!fromAetnaLP) {
        clearAetnaSession();
      }
      navigate(paths.EXPLORER_ZIP);
    } else {
      setHasTouched(true);
    }
  };

  const handleBlur = () => {
    setHasTouched(true);
  };

  const handleChange = (v) => {
    setZip(v?.replace(/\D/g, '')?.slice(0, 5));
    setHasTouched(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <SZip>
        <Input
          submitting={upserting}
          cta={cta}
          required
          name="zip"
          value={zip}
          placeholder={placeholder}
          pattern="[0-9]{5}"
          keyboard="numeric"
          autocomplete="postal-code"
          onChange={handleChange}
          onBlur={handleBlur}
          help={place ? `${place.name}, ${place.state}` : ''}
          helpColor={helpColor}
          error={hasTouched ? error : ''}
        />
      </SZip>
    </form>
  );
};

/**
 *
 * @todo proper handoff
 *
 */
export const Income = ({ slasher }) => {
  const { income, setIncome, pxId, adminPxLink } = useContext(SessionContext);

  const onEnter = (event) => {
    event.preventDefault();

    if (slasher) {
      Segment.track(SegmentEvent.PX_STARTED, { flow: 'Slasher', pxId, adminPxLink });
      navigate('/slasher/zip');
    } else {
      Segment.track(SegmentEvent.PX_STARTED, { flow: 'Explorer', pxId, adminPxLink });
      navigate(paths.EXPLORER_PEOPLE);
    }
  };

  return (
    <form onSubmit={onEnter}>
      <SZip>
        <FilterInput
          raw
          required
          narrow
          prefix="$"
          suffix={`/${$t({ id: 'health-plan.year-abbr', defaultMessage: 'yr' })}`}
          image="income"
          type="text"
          keyboard="numeric"
          value={income}
          title="Annual Income"
          name="income"
          pattern="[0-9]{1,8}"
          placeholder="household income"
          button={{ onPress: onEnter, disabled: !income || income === '' || income < 1 }}
          onChange={(v) => {
            setIncome(parseInt(v));
          }}
        />
      </SZip>
    </form>
  );
};

export default Zip;
