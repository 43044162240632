import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const SBox = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  border-radius: var(--radius-xl);
  margin: calc(4 * var(--side-margin)) 0;
  b1,
  .b2,
  b2,
  h2 {
    margin: 12px 0;
  }
  h2 {
    margin: 16px 0;
    font-weight: 500;
  }
  a {
    font-weight: 600;
    color: var(--color-flare-primary);
  }
  > div {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
      flex-basis: 100%;
    }
  }
  .left {
    padding-right: calc(2 * var(--side-margin));
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

const SRight = styled.div`
  background: ${(p) => p.color}
    ${(p) => (p.fullImage ? `url(/img/box/${p.fullImage}) top/cover` : p.color ? p.color : '')};
  border-radius: var(--radius-xl);
  width: 50%;
  padding-top: 50%;
  @media (max-width: 768px) {
    padding-top: 60%;
    margin-bottom: 24px;
  }
`;

/**
 *
 */
const FeatureBox = ({
  eb,
  title = 'Title',
  subtitle = 'Text',
  color = 'var(--c-red-1)',
  ebColor = 'var(--c-red-0)',
  style,
  ctaText,
  fullImage,
  children,
}) => {
  return (
    <SBox color={color} style={style} data-sal="slide-up">
      <SRight color={color} fullImage={fullImage}></SRight>
      <div className="left">
        <b2 style={{ color: ebColor || 'var(--c-fg-1)' }}>{eb}</b2>
        <h2>{title}</h2>
        <b1 className="o85">{subtitle}</b1>
        {ctaText && (
          <>
            <br />
            <Button small>{ctaText}</Button>
            <br />
          </>
        )}
        {children && (
          <div style={{ marginTop: 40 }}>
            <div />
            {children}
          </div>
        )}
      </div>
    </SBox>
  );
};

export default FeatureBox;
