import React, { ReactNode } from 'react';
import styled from 'styled-components';

const SDiv = styled.div`
  overflow: hidden;

  > * {
    &:not(:only-child) {
      &:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
`;

interface BoxesProps {
  children: ReactNode;
}

export default function Boxes(props: BoxesProps) {
  return <SDiv {...props} />;
}
