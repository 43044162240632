import React, { useState } from 'react';
import styled from 'styled-components';

const STabs = styled.div`
  display: flex;
  align-items: top;
  justify-content: flex-start;
  width: 100%;
  .grow {
    flex: 1;
  }
  @media (max-width: 800px) {
    display: block;
  }
`;

const STabBar = styled.div`
  font-size: var(--pl);
  align-items: baseline;
  color: var(--fg);
  flex-shrink: 0;
  flex-grow: 0;
  width: 300px;
  @media (max-width: 800px) {
    display: flex;
    width: auto;
  }
`;

const STab = styled.div`
  margin-top: 1rem;
  opacity: ${(p) => (p.active ? 1 : 0.5)};
  font-weight: ${(p) => (p.active ? 500 : 500)};
  font-size: var(--pl);
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  @media (max-width: 800px) {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
`;

const Tabs = ({ title, tabs = [], tabContent = [] }) => {
  const [tab, setTab] = useState(0);
  return (
    <>
      {!!title && <h3>{title}</h3>}
      <STabs>
        <STabBar>
          {tabs?.map((t, idx) => (
            <STab key={t} onClick={() => setTab(idx)} active={tab === tabs?.indexOf(t)}>
              {t}
            </STab>
          ))}
        </STabBar>

        <div className="grow">{tabContent?.[tab]}</div>
      </STabs>
    </>
  );
};

export default Tabs;
