import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  padding: 16px;
  border-radius: 16px;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
  border: 1px solid;
  margin-bottom: 8px;
  border: 1px solid ${(p) => (p.active ? 'var(--c-fg-0)' : 'var(--c-border)')};
  box-shadow: ${(p) => (p.active ? 'inset 0 0 0px 0.5px var(--c-fg-0)' : '')};
  &:hover {
    border-color: ${(p) => (p.onClick ? 'var(--c-fg-3)' : 'var(--c-border)')};
  }
  .result-title {
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 4px;
    color: var(--c-fg-0);
    font-size: var(--fs-b2);
  }
  .result-subtitle {
    color: var(--c-fg-1);
    font-size: var(--fs-b3);
  }
  .result-detail {
    color: var(--c-fg-0);
    font-size: var(--fs-b3);
  }
`;

export const StyledPillSelect = styled.select`
  border-radius: 999px;
  padding: 0 16px;
  appearance: none;
  width: auto;
  outline: none;
  min-width: 0;
  margin: 0 8px 0 0;
  text-align: center;
  height: 40px;
  line-height: 1;
  flex-shrink: 0;
  color: ${(p) => (p.active ? 'var(--bg)' : 'var(--fg)')};
  border: none;
  background: ${(p) => (p.active ? 'var(--bgca)' : 'var(--c-border-light)')};
  font-size: var(--fs-b3);
  font-weight: 500;
  backdrop-filter: blur(40px);

  cursor: pointer;
  text-align-last: center; // required for safari
  &:hover {
    background: ${(p) => (p.active ? 'var(--bgca)' : 'var(--c-border)')};
  }
`;

const SearchResultCard = ({ title, subtitle, detail, onPress, active, children }) => {
  return (
    <StyledCard onClick={onPress} active={active} className={!children && 'pressable'}>
      <div className="result-title">{title}</div>
      <div className="flex-h">
        <div className="result-subtitle">{subtitle}</div>
        <div className="result-detail">{detail}</div>
      </div>
      <div>{children}</div>
    </StyledCard>
  );
};

export default SearchResultCard;
