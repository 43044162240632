import React, { useState } from 'react';
import styled from 'styled-components';

const StyledAccordion = styled.div`
  font-feature-settings: 'tnum';
  -moz-font-feature-settings: 'tnum';
  -ms-font-feature-settings: 'tnum';
  -webkit-font-feature-settings: 'tnum';
  padding-bottom: ${(params) => (params.open ? 24 : 0)}px;
  transition: padding-bottom 0.2s;
  .licnum {
    font-weight: 500;
    font-size: var(--fs-b3);
  }
  .licType {
    opacity: ${(params) => (params.open ? 0.85 : 0.5)} !important;
  }
  &:hover {
    background: var(--bga);
    cursor: pointer;
  }
`;

const StyledAccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--fga);
  padding: 12px 0 11px;
  box-sizing: border-box;
`;

const StyledAccordionBody = styled.div`
  max-height: ${(params) => (params.open ? params.numAgents * 36 + 24 : 0)}px;
  opacity: ${(params) => (params.open ? 1 : 0)};
  overflow: hidden;
  max-width: 300px;
  margin-left: auto;
  transition: max-height 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-size: var(--fs-b3);
`;

const StyledLicenseholder = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--fga);
  padding: 8px 0 7px;
`;

const StyledState = styled.div`
  height: 16px;
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  background-image: url(/states/${(params) => params.state}.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 12px;
  transition: 0.2s;
  transform: scale(${(params) => (params.open ? 1.35 : 1)});
  opacity: ${(params) => (params.open ? 1 : 0.75)};
  filter: var(--svgFilter);
  @media (max-width: 850px) {
    margin-right: 12px;
  }
`;

const StyledStateInfo = styled.div`
  display: flex;
  font-weight: 500;
  align-items: baseline;
  font-size: var(--fs-b3);
  .licType {
    margin-left: 1rem;
    font-size: var(--fs-b3);
  }
  @media (max-width: 850px) {
    display: block;
    .licType {
      margin-left: 0;
      font-weight: 400;
    }
  }
`;

const AccordionPrimary = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AccordionHeader = ({ children }) => <StyledAccordionHeader>{children}</StyledAccordionHeader>;

const AccordionBody = ({ open, numAgents, children }) => (
  <StyledAccordionBody open={open} numAgents={numAgents}>
    {children}
  </StyledAccordionBody>
);

const Accordion = ({ state, type, npn, agents, open, onOpen }) => {
  return (
    <StyledAccordion open={open} onClick={() => onOpen()}>
      <AccordionHeader>
        <AccordionPrimary>
          <StyledState
            className="stateSVG"
            open={open}
            state={state.toUpperCase().replace(/ /g, '')}
          />
          <StyledStateInfo>
            <div>{state}</div>
            <div className="licType">{type}</div>
          </StyledStateInfo>
        </AccordionPrimary>
        <div className="bigNpn licnum" style={{ marginTop: 1 }}>
          {npn}
        </div>
      </AccordionHeader>
      <AccordionBody open={open} numAgents={!!agents && agents.length}>
        {!!agents &&
          agents.map(
            (item) =>
              !!item &&
              !!item.npn && (
                <StyledLicenseholder key={item.npn}>
                  <div>{item.name}</div>
                  <div className="licnum">{item.npn}</div>
                </StyledLicenseholder>
              ),
          )}
      </AccordionBody>
    </StyledAccordion>
  );
};

const LicenseTable = () => {
  const [open, setOpen] = useState();

  return [...new Set(lic.map((item) => item.state_name))].map((item, idx) => {
    const licForState = lic.filter((_item) => {
      return _item.state_name === item;
    });
    const licIndiv = licForState
      .filter((_item) => {
        return people.includes(_item.holder_name) && _item.holder_name !== 'Catch Insurance';
      })
      .map((_item2) => {
        return { name: _item2.holder_name, npn: _item2.npn };
      });

    const licFirm = licForState.filter((_item) => {
      return _item.holder_name === 'Catch Insurance';
    })[0];

    return (
      !!licFirm &&
      !!licFirm.npn && (
        <Accordion
          state={item}
          type={licFirm.lic_type}
          npn={licFirm.npn}
          open={open === idx}
          onOpen={() => setOpen(idx === open ? -1 : idx)}
          agents={licIndiv}
        />
      )
    );
  });
};

const lic = [
  {
    npn: 18986211,
    state_name: 'NPN',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 20655395,
    state_name: 'NPN',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'NPN',
    holder_name: 'Laura Speyer',
  },

  {
    npn: 3000473658,
    state_name: 'Alabama',
    state_abbr: 'AL',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 100170614,
    state_name: 'Alaska',
    state_abbr: 'AK',
    lic_type: 'Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449485,
    state_name: 'Arizona',
    state_abbr: 'AZ',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000464396,
    state_name: 'Arkansas',
    state_abbr: 'AR',
    lic_type: 'Accident, Health & Sickness; Exchange Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 6003058,
    state_name: 'California',
    state_abbr: 'CA',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 591426,
    state_name: 'Colorado',
    state_abbr: 'CO',
    lic_type: 'Accident and Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 2621520,
    state_name: 'Connecticut',
    state_abbr: 'CT',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000461813,
    state_name: 'Delaware',
    state_abbr: 'DE',
    lic_type: 'Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449425,
    state_name: 'District of Columbia',
    state_abbr: 'DC',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 'L106813',
    state_name: 'Florida',
    state_abbr: 'FL',
    lic_type: 'Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 203291,
    state_name: 'Georgia',
    state_abbr: 'GA',
    lic_type: 'Accident and Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 18986211,
    state_name: 'Hawaii',
    state_abbr: 'HI',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 703135,
    state_name: 'Idaho',
    state_abbr: 'ID',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449487,
    state_name: 'Illinois',
    state_abbr: 'IL',
    lic_type: 'Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3402086,
    state_name: 'Indiana',
    state_abbr: 'IN',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1002332547,
    state_name: 'Iowa',
    state_abbr: 'IA',
    lic_type: 'Accident and Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 18986211,
    state_name: 'Kansas',
    state_abbr: 'KS',
    lic_type: 'Accidental & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 'DOI-1022498',
    state_name: 'Kentucky',
    state_abbr: 'KY',
    lic_type: 'Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 792652,
    state_name: 'Louisiana',
    state_abbr: 'LA',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 'AGN324322',
    state_name: 'Maine',
    state_abbr: 'ME',
    lic_type: 'Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449443,
    state_name: 'Maryland',
    state_abbr: 'MD',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 18986211,
    state_name: 'Massachusetts',
    state_abbr: 'MA',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: '0118199',
    state_name: 'Michigan',
    state_abbr: 'MI',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 40614256,
    state_name: 'Minnesota',
    state_abbr: 'MN',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 15034951,
    state_name: 'Mississippi',
    state_abbr: 'MS',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 8483108,
    state_name: 'Missouri',
    state_abbr: 'MO',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449620,
    state_name: 'Montana',
    state_abbr: 'MT',
    lic_type: 'Disability (Health); Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 100304427,
    state_name: 'Nebraska',
    state_abbr: 'NE',
    lic_type: 'Sickness, Accident and Health; Life and Annuities',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3401566,
    state_name: 'Nevada',
    state_abbr: 'NV',
    lic_type: 'Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 2423226,
    state_name: 'New Hampshire',
    state_abbr: 'NH',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1691414,
    state_name: 'New Jersey',
    state_abbr: 'NJ',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000453710,
    state_name: 'New Mexico',
    state_abbr: 'NM',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1799035,
    state_name: 'New York',
    state_abbr: 'NY',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1000619825,
    state_name: 'North Carolina',
    state_abbr: 'NC',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449446,
    state_name: 'North Dakota',
    state_abbr: 'ND',
    lic_type: 'Accident & Health; Life & Annuity',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1225414,
    state_name: 'Ohio',
    state_abbr: 'OH',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449449,
    state_name: 'Oklahoma',
    state_abbr: 'OK',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000482035,
    state_name: 'Oregon',
    state_abbr: 'OR',
    lic_type: 'Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 881171,
    state_name: 'Pennsylvania',
    state_abbr: 'PA',
    lic_type: 'Accident & Health; Life & Fixed Annuities',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000449500,
    state_name: 'Rhode Island',
    state_abbr: 'RI',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000461852,
    state_name: 'South Carolina',
    state_abbr: 'SC',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 10021184,
    state_name: 'South Dakota',
    state_abbr: 'SD',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 2427299,
    state_name: 'Tennessee',
    state_abbr: 'TN',
    lic_type: 'Accident & Health',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 2432938,
    state_name: 'Texas',
    state_abbr: 'TX',
    lic_type: 'Life, Accident, Health and HMO',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 703383,
    state_name: 'Utah',
    state_abbr: 'UT',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3002589162,
    state_name: 'Vermont',
    state_abbr: 'VT',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 145089,
    state_name: 'Virginia',
    state_abbr: 'VA',
    lic_type: 'Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 1006392,
    state_name: 'Washington',
    state_abbr: 'WA',
    lic_type: 'Disability; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 100291276,
    state_name: 'West Virginia',
    state_abbr: 'WV',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3000461916,
    state_name: 'Wisconsin',
    state_abbr: 'WI',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 387946,
    state_name: 'Wyoming',
    state_abbr: 'WY',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Catch Insurance',
  },
  {
    npn: 3002495486,
    state_name: 'Alabama',
    state_abbr: 'AL',
    lic_type: 'Accident & Health or Sickness; Life; Dental Services',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002285743,
    state_name: 'Alabama',
    state_abbr: 'AL',
    lic_type: 'Accident & Health or Sickness; Life; Dental Services',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491649,
    state_name: 'Alaska',
    state_abbr: 'AK',
    lic_type: 'Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284683,
    state_name: 'Alaska',
    state_abbr: 'AK',
    lic_type: 'Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Arizona',
    state_abbr: 'AZ',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Arizona',
    state_abbr: 'AZ',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Arkansas',
    state_abbr: 'AR',
    lic_type: 'Accident, Health & Sickness; Exchange Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Arkansas',
    state_abbr: 'AR',
    lic_type: 'Accident, Health & Sickness; Exchange Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 1211296,
    state_name: 'California',
    state_abbr: 'CA',
    lic_type: 'Accident & Health or Sickness, Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 4255661,
    state_name: 'California',
    state_abbr: 'CA',
    lic_type: 'Accident & Health or Sickness, Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 792203,
    state_name: 'Colorado',
    state_abbr: 'CO',
    lic_type: 'Accident and Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 774957,
    state_name: 'Colorado',
    state_abbr: 'CO',
    lic_type: 'Accident and Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Connecticut',
    state_abbr: 'CT',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Connecticut',
    state_abbr: 'CT',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491723,
    state_name: 'Delaware',
    state_abbr: 'DE',
    lic_type: 'Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284662,
    state_name: 'Delaware',
    state_abbr: 'DE',
    lic_type: 'Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491740,
    state_name: 'District of Columbia',
    state_abbr: 'DC',
    lic_type: 'Accident, Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284700,
    state_name: 'District of Columbia',
    state_abbr: 'DC',
    lic_type: 'Accident, Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 'W978539',
    state_name: 'Florida',
    state_abbr: 'FL',
    lic_type: 'Health, Life & Variable Annuity',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 'W9331169',
    state_name: 'Florida',
    state_abbr: 'FL',
    lic_type: 'Health, Life & Variable Annuity',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3608979,
    state_name: 'Georgia',
    state_abbr: 'GA',
    lic_type: 'Accident and Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3576122,
    state_name: 'Georgia',
    state_abbr: 'GA',
    lic_type: 'Accident and Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Hawaii',
    state_abbr: 'HI',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Hawaii',
    state_abbr: 'HI',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Idaho',
    state_abbr: 'ID',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Idaho',
    state_abbr: 'ID',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Illinois',
    state_abbr: 'IL',
    lic_type: 'Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Illinois',
    state_abbr: 'IL',
    lic_type: 'Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3896195,
    state_name: 'Indiana',
    state_abbr: 'IN',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3858214,
    state_name: 'Indiana',
    state_abbr: 'IN',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Iowa',
    state_abbr: 'IA',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Iowa',
    state_abbr: 'IA',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Kansas',
    state_abbr: 'KS',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Kansas',
    state_abbr: 'KS',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 1271620,
    state_name: 'Kentucky',
    state_abbr: 'KY',
    lic_type: 'Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 1245398,
    state_name: 'Kentucky',
    state_abbr: 'KY',
    lic_type: 'Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 1077672,
    state_name: 'Louisiana',
    state_abbr: 'LA',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 1031900,
    state_name: 'Louisiana',
    state_abbr: 'LA',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 'PRN450464',
    state_name: 'Maine',
    state_abbr: 'ME',
    lic_type: 'Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 'PRN438765',
    state_name: 'Maine',
    state_abbr: 'ME',
    lic_type: 'Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491769,
    state_name: 'Maryland',
    state_abbr: 'MD',
    lic_type: 'Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284640,
    state_name: 'Maryland',
    state_abbr: 'MD',
    lic_type: 'Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Massachusetts',
    state_abbr: 'MA',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Massachusetts',
    state_abbr: 'MA',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 1211296,
    state_name: 'Michigan',
    state_abbr: 'MI',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 1181966,
    state_name: 'Michigan',
    state_abbr: 'MI',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 10943458,
    state_name: 'Minnesota',
    state_abbr: 'MN',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 40839672,
    state_name: 'Minnesota',
    state_abbr: 'MN',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 10943458,
    state_name: 'Mississippi',
    state_abbr: 'MS',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 10910827,
    state_name: 'Mississippi',
    state_abbr: 'MS',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491816,
    state_name: 'Missouri',
    state_abbr: 'MO',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284708,
    state_name: 'Missouri',
    state_abbr: 'MO',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491761,
    state_name: 'Montana',
    state_abbr: 'MT',
    lic_type: 'Disability (Health)',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284668,
    state_name: 'Montana',
    state_abbr: 'MT',
    lic_type: 'Disability (Health)',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Nebraska',
    state_abbr: 'NE',
    lic_type: 'Sickness, Accident & Health; Life and Annuities',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Nebraska',
    state_abbr: 'NE',
    lic_type: 'Sickness, Accident & Health; Life and Annuities',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3895263,
    state_name: 'Nevada',
    state_abbr: 'NV',
    lic_type: 'Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3857838,
    state_name: 'Nevada',
    state_abbr: 'NV',
    lic_type: 'Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'New Hampshire',
    state_abbr: 'NH',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'New Hampshire',
    state_abbr: 'NH',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491831,
    state_name: 'New Jersey',
    state_abbr: 'NJ',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284682,
    state_name: 'New Jersey',
    state_abbr: 'NJ',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'New Mexico',
    state_abbr: 'NM',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'New Mexico',
    state_abbr: 'NM',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 1781568,
    state_name: 'New York',
    state_abbr: 'NY',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 1762975,
    state_name: 'New York',
    state_abbr: 'NY',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'North Carolina',
    state_abbr: 'NC',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'North Carolina',
    state_abbr: 'NC',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'North Dakota',
    state_abbr: 'ND',
    lic_type: 'Accident & Health; Life & Annuity',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'North Dakota',
    state_abbr: 'ND',
    lic_type: 'Accident & Health; Life & Annuity',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 1503578,
    state_name: 'Ohio',
    state_abbr: 'OH',
    lic_type: 'Accident & Health',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 1478468,
    state_name: 'Ohio',
    state_abbr: 'OH',
    lic_type: 'Accident & Health',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491792,
    state_name: 'Oklahoma',
    state_abbr: 'OK',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284657,
    state_name: 'Oklahoma',
    state_abbr: 'OK',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Oregon',
    state_abbr: 'OR',
    lic_type: 'Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Oregon',
    state_abbr: 'OR',
    lic_type: 'Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 1123956,
    state_name: 'Pennsylvania',
    state_abbr: 'PA',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 1104080,
    state_name: 'Pennsylvania',
    state_abbr: 'PA',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491583,
    state_name: 'Rhode Island',
    state_abbr: 'RI',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284655,
    state_name: 'Rhode Island',
    state_abbr: 'RI',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'South Carolina',
    state_abbr: 'SC',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'South Carolina',
    state_abbr: 'SC',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 40682700,
    state_name: 'South Dakota',
    state_abbr: 'SD',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 40666354,
    state_name: 'South Dakota',
    state_abbr: 'SD',
    lic_type: 'Accident & Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491664,
    state_name: 'Tennessee',
    state_abbr: 'TN',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284712,
    state_name: 'Tennessee',
    state_abbr: 'TN',
    lic_type: 'Accident & Health; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 2998440,
    state_name: 'Texas',
    state_abbr: 'TX',
    lic_type: 'Life, Accident, Health and HMO',
    holder_name: 'Alexa Irish',
  },
  {
    npn: '2935860; 2935916',
    state_name: 'Texas',
    state_abbr: 'TX',
    lic_type: 'Life, Accident, Health and HMO',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 982450,
    state_name: 'Utah',
    state_abbr: 'UT',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 966869,
    state_name: 'Utah',
    state_abbr: 'UT',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 3002491697,
    state_name: 'Vermont',
    state_abbr: 'VT',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 3002284715,
    state_name: 'Vermont',
    state_abbr: 'VT',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 1370178,
    state_name: 'Virginia',
    state_abbr: 'VA',
    lic_type: 'Health, Life & Annuities',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 1347137,
    state_name: 'Virginia',
    state_abbr: 'VA',
    lic_type: 'Health, Life & Annuities',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 1204851,
    state_name: 'Washington',
    state_abbr: 'WA',
    lic_type: 'Disability; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 1188118,
    state_name: 'Washington',
    state_abbr: 'WA',
    lic_type: 'Disability; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'West Virginia',
    state_abbr: 'WV',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'West Virginia',
    state_abbr: 'WV',
    lic_type: 'Accident and Health or Sickness; Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 20655395,
    state_name: 'Wisconsin',
    state_abbr: 'WI',
    lic_type: 'Accident & Health, Life',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 20521098,
    state_name: 'Wisconsin',
    state_abbr: 'WI',
    lic_type: 'Accident & Health, Life',
    holder_name: 'Laura Speyer',
  },
  {
    npn: 551227,
    state_name: 'Wyoming',
    state_abbr: 'WY',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Alexa Irish',
  },
  {
    npn: 535911,
    state_name: 'Wyoming',
    state_abbr: 'WY',
    lic_type: 'Accident & Health or Sickness',
    holder_name: 'Laura Speyer',
  },
];

const people = [...new Set(lic.map((item) => item.holder_name))];

export default LicenseTable;
