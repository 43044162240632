import { useState, useEffect } from 'react';

/**
 * @deprecated
 * beware: this will not always trigger a change in storedValue
 * if the underlying storage value changes
 */
export const useLocalStorage = <T>(key, initialValue?: T) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      const parsedItem = JSON.parse(item);

      return !!item ? parsedItem : initialValue;
    } catch (err) {
      console.error(err);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);

      if (!!key && value) {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const removeValue = () => {
    if (!!key) {
      localStorage.removeItem(key);
    }
  };

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
