import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isActive, formatMedicalName, $t } from 'src/utils';
import { useMarketplace } from 'src/hooks';
import { FilterInput, Modal, SearchResultCard, SelectedDrug, TextButton } from 'src/components';
import { useExplorer } from 'src/hooks/useExplorer';

const HorizontalScroll = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
`;

const DoctorsPage = ({ onClose, open }) => {
  const { data, upsertExplorer } = useExplorer();

  const [docs, setDocs] = useState([]);

  useEffect(() => {
    if (data?.providersWanted?.length > 0) {
      const existingProviders = (data?.providersWanted || [])?.map(({ __typename, ...d }) => d);
      setDocs([...existingProviders]);
    }
  }, [data]);

  const { searchTerm, setSearchTerm, results } = useMarketplace({
    type: 'providers',
    zip: data.zipcode,
    year: data.coverageYear,
  });

  const handleSave = () => {
    upsertExplorer({ providersWanted: docs }, true);
    onClose();
  };

  const toggleDoc = (d) => {
    const exists = docs?.some((doc) => doc.npi === d.npi);

    if (exists) {
      setDocs(docs.filter((doc) => doc?.npi !== d.npi));
    } else {
      setDocs([...docs, d]);
      setSearchTerm('');
    }
  };

  return (
    <Modal
      title={$t({ id: 'doctors.label', defaultMessage: 'Doctors' })}
      open={open}
      onClose={handleSave}
      topItems={
        <TextButton text={$t({ id: 'doctors.btn', defaultMessage: 'Save' })} onPress={handleSave} />
      }
      bottomItems={
        docs?.length > 0 && (
          <HorizontalScroll>
            {docs?.map((item, idx) => (
              <SelectedDrug
                key={item?.npi}
                name={formatMedicalName(item?.name)}
                onRemove={() => {
                  const arr = docs.filter((it, i) => i !== idx);
                  setDocs(arr);
                  setSearchTerm('');
                }}
              />
            ))}
          </HorizontalScroll>
        )
      }
    >
      <h3 style={{ marginBottom: 0 }}>
        {$t({
          id: 'doctors.title',
          defaultMessage: 'Need access to specific doctors?',
        })}
      </h3>
      <p style={{ marginTop: 2, color: 'var(--c-fg-1)' }}>
        {$t({
          id: 'doctors.subtitle',
          defaultMessage: 'Current primary care physician, specialist, or other provider',
        })}
      </p>
      <div className="sticky-search">
        <FilterInput
          wide
          type="text"
          value={searchTerm}
          placeholder={$t({
            id: 'doctors.search',
            defaultMessage: 'Search for a doctor or specialist',
          })}
          onChange={(val) => {
            setSearchTerm(val);
          }}
        />
      </div>
      {results?.length > 0 &&
        results?.map((item) => (
          <SearchResultCard
            key={item?.npi}
            title={formatMedicalName(item?.name)}
            subtitle={item?.taxonomy}
            detail={`${item?.address?.city.toProperCase()}, ${item?.address?.state}`}
            active={isActive({
              array: docs,
              item: item,
              key: 'npi',
            })}
            onPress={() => {
              toggleDoc({ name: item?.name, npi: item?.npi });
            }}
          />
        ))}
    </Modal>
  );
};

export default DoctorsPage;
