import { useEffect, useMemo, useState } from 'react';

export interface Applicant {
  age: number;
  relation: 'SELF' | 'SPOUSE' | 'CHILD';
  sex: 'MALE' | 'FEMALE';
}

const getBlankApplicant = (relation) => ({
  isApplying: true,
  relation,
  age: null,
  sex: null,
});

export const useApplicants = (members = []) => {
  const [applicants, setApplicants] = useState([getBlankApplicant('SELF')]);

  useEffect(() => {
    if (members.length > 0) {
      setApplicants(
        members.map((m) => ({
          isApplying: m?.isApplying,
          age: m?.age,
          relation: m?.relation,
          sex: m?.sex,
        })),
      );
    }
  }, [members]);

  const hasSpouse = useMemo(() => {
    return applicants?.some((a) => a.relation === 'SPOUSE');
  }, [applicants]);

  const isValidMembers = useMemo(() => {
    return applicants.every((a) => a.sex && a.age && a.age <= 100 && a.age >= 0);
  }, [applicants]);

  const addApplicant = (relation) => {
    setApplicants([...applicants, getBlankApplicant(relation)]);
  };

  const updateApplicant = (index, value) => {
    const array = [...applicants];
    array[index] = value;
    setApplicants([...array]);
  };

  const removeApplicant = (index) => {
    const array = applicants.filter((it, i) => i !== index);
    setApplicants(array);
  };

  return {
    isValidMembers,
    hasSpouse,
    applicants,
    addApplicant,
    updateApplicant,
    removeApplicant,
    setApplicants,
  };
};
