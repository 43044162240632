import { gql } from '../generated';

gql(`
  fragment MarketplaceEligibility on MarketplaceEligibility {
    aptc
    csr
    hardshipExemption
    isMedicaidChip
  }
`);

gql(`
  fragment PXDependent on PublicHealthExplorerDependentOld {
    age
    relation
    sex
    isSmoker
  }
`);

gql(`
  fragment SessionFields on PublicHealthExplorerData {
    id
    email
    zipcode
    countyfips
    coverageYear
    state
    income
    providerPlanID
    pathwayType
    email
    financialPreference
    plannedUsage
    drugsWanted {
      rxcui
      name
    }
    providersWanted {
      npi
      name
    }
    dependents {
      ...PXDependent
    }
  }
`);

export const UPSERT_SESSION = gql(`
  mutation UpsertSession($input: PublicHealthExplorerDataInput!) {
    upsertPublicHealthExplorerData(input: $input) {
      ...SessionFields
    }
  }
`);

export const GET_SESSION = gql(`
  query PublicHealthSession($id: ID!) {
    getPublicHealthExplorerData(id: $id) {
      ...SessionFields
    }
  }
`);
