import React from 'react';
import styled from 'styled-components';
import * as RadixMenu from '@radix-ui/react-navigation-menu';
import { NavLink, NavTrigger } from '../NavLink';
import { NavSubmenu } from './NavSubmenu';

export interface NavMenuProps {
  links: Array<{
    path: string;
    text: string;
    icon?: any;
    onClick?: () => void;
    children?: Array<{
      path: string;
      text: string;
    }>;
  }>;
}

const StyledMenu = styled(RadixMenu.Root)`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

const StyledList = styled(RadixMenu.List)`
  display: flex;
  justify-content: center;
  padding: 4px;
  border-radius: 6px;
  list-style: none;
  box-shadow: 0 2px 10px var(--black-a7);
  margin: 0 -12px;
`;

const StyledViewport = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 100%;
  left: 0;
  perspective: 2000px;

  .NavigationMenuViewport {
    position: relative;
    transform-origin: top center;
    margin-top: 9px;
    width: 100%;
    height: var(--radix-navigation-menu-viewport-height);
    transition:
      width,
      height,
      300ms ease;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 6px 12px -3px #00000022;
    border: 1px solid var(--c-border);
    background: var(--bgc);
    backdrop-filter: blur(25px);
    border-radius: 18px;

    @media only screen and (min-width: 600px) {
      width: var(--radix-navigation-menu-viewport-width);
    }

    &[data-state='open'] {
      animation: scaleIn 200ms ease;
    }

    &[data-state='closed'] {
      animation: scaleOut 200ms ease;
    }
  }
`;

const StyledContent = styled(RadixMenu.Content)`
  position: absolute;
  top: 0;
  width: 100%;
  animation-duration: 250ms;
  animation-timing-function: ease;

  @media only screen and (min-width: 600px) {
    width: auto;
  }

  &[data-motion='from-start'] {
    animation-name: enterFromLeft;
  }

  &[data-motion='from-end'] {
    animation-name: enterFromRight;
  }

  &[data-motion='to-start'] {
    animation-name: exitToLeft;
  }

  &[data-motion='to-end'] {
    animation-name: exitToRight;
  }
`;

const StyledIndicator = styled(RadixMenu.Indicator)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 10px;
  top: 100%;
  overflow: hidden;
  z-index: 1;
  transition:
    width,
    transform 250ms ease;

  &[data-state='visible'] {
    animation: fadeIn 200ms ease;
  }

  &[data-state='hidden'] {
    animation: fadeOut 200ms ease;
  }
`;

const Arrow = styled.div`
  position: relative;
  top: 70%;
  background-color: white;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-top-left-radius: 2px;
  border: 1px solid var(--c-border);
`;

const StyledItem = styled(RadixMenu.Item)`
  display: flex;
`;

export const NavMenu: React.FC<NavMenuProps> = (props: NavMenuProps) => {
  return (
    <StyledMenu>
      <StyledList>
        {props.links.map((link) => {
          const key = link.text || link.icon;

          if (link.children) {
            return (
              <StyledItem key={key}>
                <NavTrigger text={link.text} icon={link.icon} />
                <StyledContent>
                  <NavSubmenu resources={link.children} />
                </StyledContent>
              </StyledItem>
            );
          }

          return (
            <StyledItem key={key}>
              <NavLink icon={link.icon} path={link.path} text={link.text} onClick={link.onClick} />
            </StyledItem>
          );
        })}

        <StyledIndicator>
          <Arrow />
        </StyledIndicator>
      </StyledList>
      <StyledViewport>
        <RadixMenu.Viewport className="NavigationMenuViewport" />
      </StyledViewport>
    </StyledMenu>
  );
};
