export default [
  'square',
  'doordash',
  'stripe',
  'paypal',
  'intuit',
  'postmates',
  'gusto',
  'uber',
  'vagaro',
  'upwork',
  'etsy',
  'cashapp',
  'billcom',
  'glossgenius',
  'turo',
  'airbnb',
];

export const TOP_SOURCES = ['Doordash', 'Cash App', 'Stripe', 'Square', 'Uber', 'Quickbooks'];

export const cleanSource = (source) => source?.replace(' ', '')?.toLowerCase();

export const SG = {
  gig: ['Doordash', 'Uber Eats', 'Uber', 'Lyft', 'Postmates'],
  p2p: ['Cash App', 'Venmo', 'Deposit', 'Paypal', 'Zelle'],
  physical: ['Vagaro', 'Square', 'Styleseat'],
  ecommerce: ['Square', 'Stripe', 'Etsy'],
  invoice: ['Bill.com', 'Intuit', 'Paypal', 'Wave'],
  freelance: ['Upwork', 'Fiverr', 'Stripe'],
  designer: ['Webflow', 'Squarespace'],
  space: ['Airbnb', 'Turo'],
};

export const RELATED_SOURCES = {
  square: [...SG['ecommerce'], ...SG['physical']],
  doordash: SG['gig'],
  stripe: [...SG['p2p'], ...SG['ecommerce']],
  paypal: SG['invoice'],
  intuit: SG['invoice'],
  postmates: SG['gig'],
  gusto: SG['freelance'],
  uber: SG['gig'],
  vagaro: SG['physical'],
  upwork: SG['freelance'],
  etsy: SG['designer'],
  cashapp: SG['p2p'],
  billcom: SG['invoice'],
  glossgenius: SG['physical'],
  turo: SG['space'],
  airbnb: SG['space'],
};

export const makeSources = (source) => {
  const related = RELATED_SOURCES[cleanSource(source)] || [];
  const _st = new Set([cleanSource(source), ...related, ...TOP_SOURCES]);
  const _sources = [..._st];
  return _sources;
};

export const ALL_SOURCES = [
  '100 Million Academy',
  '14Four',
  'A Case for Women',
  'AAA Insurance',
  'ACH Credit',
  'ACH Deposit',
  'ACH Payment',
  'ADP',
  'AND.CO',
  'ATM Deposit',
  'AbeBooks',
  'Abercrombie',
  'About, Inc.',
  'Account Credit',
  'Acorns',
  'Advance',
  'AdvicePay',
  'Adyen Inc',
  'Aeropostale',
  'Aerotek',
  'Aetna',
  'Affirm Payment',
  'Aflac',
  'Afterpay',
  'Aimbridge',
  'Airbnb',
  'Aisle Planner',
  'Alaska Airlines',
  'Albert Savings',
  'Alchemy Method',
  'Allstate',
  'Amazon',
  'Amazon Marketplace',
  'Amazon Mturk',
  'Amazon Prime',
  'Amazon.com Payments',
  'AmeriLife',
  'American Airlines',
  'American Ambulance',
  'American Eagle Outfitters',
  'American Family Insurance',
  'American General',
  'American Homes 4 Rent',
  'American Income Life Insurance',
  'Americo',
  'Amika',
  'Amway',
  'AppSumo',
  'Appen',
  'Apple Cash Transfer',
  'Apple Store',
  'Apple iTunes',
  'Aquis',
  'Aramark',
  'ArbiterPay',
  'Arbonne',
  'Asbury Automotiv',
  'Asheville Taxi',
  'Ashley Huft Photography',
  'Aspiration Instant Transfer',
  'Assist Wireless',
  'AssistPro',
  'Atlas Stone Photography',
  'Audible',
  'Austin Oaks Hospital',
  'AutoZone',
  'Avis',
  'Awaken Massages',
  'BB Farmaceutical',
  'Banana Republic',
  'Bank of America Cash Rewards',
  'Bank of America Merchant Services Direct Deposit',
  'BankCard',
  'Barrister Global',
  'Beachbody',
  'Beauty Loft LLC',
  'Bed Bath & Beyond',
  'Bel Air Music',
  'Best Buy',
  'Betterment',
  'Bill Payment',
  'Bill.com',
  'BiteSquad',
  'Bonsai',
  'Books A Million',
  'Booksy',
  'Braintree',
  'Bright',
  'Brightwheel',
  'Brigit',
  'Broker Sumo',
  'Buffalo Wild Wings',
  'Builder Assist LLC',
  'Burger King',
  'Butterball',
  'CVS',
  'Cal Poly',
  'Cancelled Transaction',
  'CarMax',
  'CareLinx',
  'Cargo Driver Earnings',
  'Case Western Reserve University',
  'Cash App',
  'Cash Reward',
  'Catch',
  'Caviar',
  'Chase Credit Card Reward Redemption',
  'Check Card Purchase',
  'Check Card Refund',
  'Checkbook Inc',
  'Checking Card Adjustment',
  'Chevron',
  'Chewy',
  'Chick-fil-A',
  'Child Support',
  'Chipotle Mexican Grill',
  'Cintas Corp',
  'Circle K',
  'Claim Payment or Reimbursement',
  'ClassPass',
  'ClassWallet',
  'Clean Plates',
  'Clearent Credit Card Processing',
  'Cleo AI',
  'Cleveland State University',
  'Coin Out',
  'Coinbase',
  'Coinhop',
  'Colonial Life',
  'Color Street Payment',
  'ComPsych',
  'Comcast',
  'Commission Payment',
  'Corporate ACH',
  'CosmoProf Beauty',
  'Costco',
  'Cracker Barrel',
  'Credit Karma',
  'Currency Cloud',
  'Cushman & Wakefield',
  'Cutco/Vector',
  'DFAS Payment',
  'DailyPay',
  'Darby Films',
  'Dave',
  "David's Bridal",
  'Debit Card Credit',
  'Debit Card Money Received',
  'Debit Card Purchase Adjustment',
  'Debit Card Refund',
  'Deel',
  'Deep Massage NYC',
  'Del Taco',
  'Delivery Driver Payment',
  'Deposit',
  "Dick's Sporting Goods",
  "Dickey's Barbecue",
  'Digit',
  'Dignity Health',
  "Dillard's",
  'Direct Deposit',
  'Direct Pay',
  'Discovery Benefits',
  'Disney',
  'Dobot',
  'Dogtopia',
  "Domino's",
  'Doordash',
  'Dosh.cash',
  'Dough LLC',
  'DraftKings',
  'Dropbox',
  'EDI Payment',
  'ELF Cosmetics',
  'Earnin',
  'East Penn Manufacturing',
  'Ebay',
  'Elemental Isolates',
  'Elva Design Group',
  'Embassy Suites',
  'Embrace',
  'Emily Steward Photography',
  'Employ Ease',
  'EmployBridge',
  'Energy BBDO',
  'Enterprise Rent-A-Car',
  'Esurance',
  'Etsy',
  'Eventbrite',
  'Evolve Vaca',
  'Executive Temps',
  'Expedia',
  'Expensify',
  'External Deposit',
  'Facebook Pay',
  'Faire',
  'Farmers Insurance',
  'Favor Delivery',
  'FedEx',
  'Fenix Internet',
  'Field Agent Payment',
  'Field Nation',
  'Finhabits',
  'Fleet Feet',
  'Folio Investments',
  'Forever 21',
  'Freedom Card Cashback',
  'From The Heart Counseling',
  'Frontier Airlines',
  'Fund Disbursement',
  'Fundbox',
  'GC Coaching, LLC',
  "Gabby's Bagels",
  'Gatehouse Media',
  'Geico',
  'Getty Images',
  'GigSalad',
  'GlossGenius',
  'GlyMed Plus',
  'Go Far Rewards',
  'Google',
  'Google Adsense',
  'Google Pay',
  'Green Dot Direct Deposit',
  'GreenDot',
  'Groupon Merchant Services',
  'GrubHub',
  'Guitar Center',
  'Gusto',
  'H&R Block',
  'Hair by Kayla Newman',
  'Hairbrained',
  'Hallmark',
  'Handy',
  'Harvard University',
  'Hasbro',
  'Hearst',
  'Heart & Fern',
  'Heart Starts',
  'Heartland Payment System',
  'Henderson Aquatics',
  'Herbalife',
  'Hill Masonry',
  'Hilton',
  'HireClub',
  'Hitmaker Music',
  'Hive Salon LLC',
  'Hobby Lobby',
  'Holiday Inn',
  'Hollister',
  'Home Depot',
  'HomeAway',
  'HomeGoods',
  'HoneyBook',
  'House of Style',
  'Hyperwallet',
  'HyreCar',
  'IHC Health Services',
  'IRS Treasury',
  'Ibotta',
  'Ikea',
  'InTouch Language',
  'Incoming Wire',
  'Indiegogo',
  'Ingenio',
  'Ingles',
  'Instacart',
  'Instawork',
  'Insurance Premium',
  'Intl Musicians',
  'Intuit Payment Solution',
  'Isuzu',
  'Ivy Pay',
  'J.Crew',
  'J.T. Hicks & Co',
  'JCPenney',
  'Jane Payments',
  'Jeanie Deposit',
  'JetBlue',
  'Jiffy Lube',
  'Jobble',
  'Juice Plus',
  'Kabbage',
  'Kaiser Aluminum',
  'Keen.com',
  'Keller Williams',
  'Kevin King Piano Tuner LLC',
  "Kohl's",
  'Kroger',
  'LA Fitness',
  'LLBean',
  'Landstar Systems',
  'LendUp',
  'Lessons in your home',
  'Liberty Bankers',
  'Liberty Mutual',
  'Lime',
  'Lionbridge',
  'Long Run Express',
  "Lowe's",
  'Lugg.com',
  'Lululemon Athletica',
  'Luna Care PT',
  'Lyft',
  'Lyra Health',
  'Mabee Justi',
  "Macy's",
  'MadeHere',
  'Maplebear Inc',
  'Maplewood Software',
  'Mas + Millie',
  'MasterBrand Cabinets',
  'MasterCard MoneySend',
  'Maverik',
  'McKinsey',
  'Medium',
  'Megan Burges Photography',
  'Meijer',
  "Menard's",
  'Mercari',
  'Merchant Bankcard',
  'Mezu',
  'Microsoft',
  'Mobile/Online Deposit',
  'Mutual of Omaha',
  'NC State University',
  'Nationwide',
  'Net Pay',
  'Net Pay SECU',
  'Net Tax Refund',
  'Netflix',
  'Noom',
  'Nordstrom',
  "O'Reilly Auto Parts",
  'ODJFS',
  'OS Restaurant Services',
  'OddBird',
  'OfferUp',
  'Ohio Benefits',
  'Ohio State University',
  'Old Navy',
  'OnlyFans',
  'Ovation Inc',
  'Overdraft Protection',
  'Overdraft Transfer',
  'P2P Transfer',
  'PNC Merchant Deposit',
  'POS Credit',
  'POS Money Send',
  'Panera Bread',
  "Papa John's Pizza",
  'Papaya Creative',
  'Pared',
  'Party City',
  'Patreon',
  'Paws and Pet Sitting',
  'PayActiv',
  'Paychex',
  'Payday Benefits',
  'Payment',
  'Payment Received',
  'Paymentech Deposit',
  'Payoneer',
  'Paypal',
  'Payroll',
  'Perfect Peach Body Wax',
  'Personal Capital',
  'PetSmart',
  'Petco',
  'Phrendly',
  'Pier 1 Imports',
  'Pilot.co',
  'Pizza Hut',
  'Planet Fitness',
  'Ply Gem',
  'Point Pickup',
  'Popmoney',
  'Poshmark',
  'Postmates',
  'Principal',
  'Progressive',
  'Propay Transfer',
  'Publix',
  'PulseLearning',
  'Purchase Return',
  'Pure Haven',
  'Qapital',
  'Qkids',
  'Qoins',
  'Quality Consulting',
  'Quickbooks',
  'Quicken',
  'Raceway',
  'Radio Robot Inc',
  'Rally Rd',
  'Reaves Law Firm',
  'Redfin',
  'Refund Transaction',
  'Regal Cinemas',
  'Rental Girl',
  'Return of Posted Check / Item',
  'Right Side Up',
  'Roadie',
  'Robert Half Int',
  'Robinhood',
  'Rockwell Collins',
  'Rodan and Fields',
  'Ross Dress for Less',
  'Round Up Transfer',
  'Rover.com',
  'Royal Caribbean',
  'Runza',
  'S&D Design',
  'SMM Specialist',
  'SMS Assist LLC',
  'Safeco Insurance',
  'Safeway',
  'Salary',
  'Salon Only',
  'SalonCentric',
  'SalonInteractive',
  'Savely',
  'Scribe Media',
  'Scribe Therapeutics',
  'Second City',
  'Sephora',
  'Sezzle Payout',
  'Shapow Parlor',
  'Shiftsmart',
  'Shipt',
  'Shoe Carnival',
  'Shootproof',
  'Shopify',
  'Siemens',
  'Simply Eloped',
  'Sixth Studio Co',
  'Sleep Number',
  'Small Business Administration EIDL',
  'SmugMug',
  'Snapshot Deposit',
  'Social Security',
  'Sofar Sounds',
  'Soleil Beauty',
  'Spectora Payout',
  'Square',
  'Square Capital',
  'Squarespace',
  'Squire',
  'Staples',
  'Starbucks',
  'Stash',
  'State Farm',
  'State Tax Refund',
  'State of Arizona',
  'State of CO Vendor Pay',
  'State of Indiana',
  'State of Montana',
  'State of Ohio',
  'State of Tennessee',
  'Staycation, LLC',
  'Steady',
  'Steeped Tea Inc',
  'Stonyfield Farm',
  'Stripe',
  'StubHub',
  'Studio Devore',
  'Styleseat',
  'Super 8',
  'Supplemental Security Income',
  'Sutter Health',
  'T-Mobile',
  'T.J. Maxx',
  'TakeLessons',
  'Talcott Free Library',
  'Talkspace',
  'Target',
  'TaskRabbit',
  'Taste Catering',
  'Taylor Corp.',
  'Teachable',
  'Teller Processed',
  'Texas A&M',
  'The Associated Press',
  'The Beauty Loft',
  'The Kennedy Center',
  'The Look Salon',
  'The Music Class',
  'The Pampered Chef',
  'TheCut',
  'Three Moon Collective',
  'Throwdown Labs',
  'Ticketmaster',
  'Tidy',
  'Time to Pet',
  'Tippy',
  'Townhouse Digital',
  'Trade Thirsty',
  'Transamerica',
  'Transfer',
  'Turo',
  'Twine',
  'U-Haul',
  'UNC',
  'US Govt Federal Salary',
  'Uber',
  'Uber Eats',
  'Unemployment',
  'United HealthCare',
  'University of CA',
  'University of Cincinnati',
  'University of IL',
  'University of Michigan',
  'Uplift',
  'Upshift',
  'Upwork',
  'Urbansitter',
  'V Shred',
  'VA Benefits',
  'VIPKid',
  'Vagaro',
  'Valparaiso University',
  'Varsity Tutors',
  'Veem Payments',
  'Vendor Pay',
  'Venmo',
  'Veryable',
  'Visa Direct',
  'Visa Money Transfer',
  'Wag More Pet Grooming',
  'Wag',
  'Waitr',
  'Walgreens',
  'Walmart',
  'Wave Payments',
  'WeGoLook',
  'WePay',
  'WeWork',
  'Wealthfront',
  'Webflow',
  'Webull Financial',
  'Wegmans',
  'Wilde Sparrow Photography',
  'Wilhelmina',
  'Wilson Marine',
  'Winwin',
  'Wix',
  'Wonolo',
  'Wyzant',
  'Yellow Robot Studios LLC',
  'You Need a Budget',
  'Zara',
  'Zeel Vendor Payment',
  'Zelle',
  'Zenefits',
  'Zipcar',
  'Zola',
  'rue21',
];
