import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const SBullet = styled.div`
  color: var(--c-fg-0);
  display: flex;
  align-items: baseline;
  margin: 24px 0;
  font-weight: 500;
  font-size: var(--fs-b2);
  .number {
    height: 28px;
    width: 28px;
    border-radius: 8px;
    background: ${(p) => (p.loading ? 'var(--fgaa)' : 'var(--c-fg-0)')};
    color: var(--c-bg-0);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }
  .subtitle {
    font-size: var(--fs-b2);
    margin-top: 6px;
    color: var(--c-fg-1);
    font-weight: 400;
    a {
      color: var(--c-fg-1);
    }
  }
`;

export const Bullet = ({ num, text, subtitle, loading }) =>
  loading ? (
    <Skeleton style={{ marginBottom: 24, height: 28, width: 28 }} />
  ) : (
    <SBullet style={{ marginBottom: 8 }} loading={loading}>
      <div className="number">{num}</div>
      <div>
        <div>{text}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
    </SBullet>
  );
