import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { SessionContext } from 'src/context/SessionContext';
import { usePartner } from 'src/hooks/usePartner';
import Modal from './Modal';

interface SplitLayoutProps {
  title: string;
  subtitle?: string;
  progress: number;
  children: any;
  sidebarContent?: any;
  style?: any;
  largeTitle?: boolean;
  precomponent?: any;
  loading?: boolean;
  wideTitle?: boolean;
  sidebarOpen?: boolean;
  onClose?: any;
  initial?: any;
  filtersOpen?: boolean;
  accessory?: any;
}

/**
 *
 * @param progress from 0 to 1
 *
 */
const SplitLayout = ({
  title,
  subtitle,
  progress = 0,
  children,
  sidebarContent,
  style,
  precomponent,
  loading,
  wideTitle,
  sidebarOpen,
  onClose,
  initial,
  filtersOpen,
  accessory,
}: SplitLayoutProps) => {
  const { partner, setProgress } = useContext(SessionContext);
  const { color, color2 } = usePartner({ slug: partner });

  useEffect(() => {
    if (setProgress) setProgress(progress);
  }, [progress, setProgress]);

  return (
    <>
      <SSplitLayout
        style={style}
        color={color2 || color}
        className="split split-animate"
        sidebarOpen={sidebarOpen}
        filtersOpen={filtersOpen}
      >
        <div className="split-initial">{initial}</div>
        <div className={`split-sidebar desktop-only ${sidebarOpen && ' open'}`}>
          <div>{sidebarContent}</div>
        </div>
        <div className="split-main">
          {precomponent}

          <h2b is="h2" className={wideTitle && 'wide'} style={{ marginBottom: 0 }}>
            {title || <Skeleton style={{ maxWidth: 700 }} />}
          </h2b>

          {(subtitle || loading) && (
            <b3 is="div" style={{ marginTop: 6, color: `var(--c-fg-1)` }}>
              {subtitle || <Skeleton style={{ maxWidth: 700 }} />}
            </b3>
          )}

          {children}
        </div>
        <div className="acc"> {accessory}</div>
        <div className="tablet-only">
          <Modal
            onClose={onClose}
            open={sidebarOpen && window.matchMedia('(max-width: 1450px)')?.matches}
            gray
            mobileOnly
          >
            {sidebarContent}
          </Modal>
        </div>
      </SSplitLayout>
    </>
  );
};

const SSplitLayout = styled.div`
  --first: min(380px, 24%);
  --last: ${(p) => (p.filtersOpen ? '35%' : '45%')};
  @media (max-width: 1450px) {
    --first: 348px;
    --last: 0;
  }
  box-sizing: border-box;
  bottom: 0;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  // z-index: 999999999999999999999999999999999999999999999999;
  display: flex;
  background: var(--c-bg-gray);
  > div:not(.acc) {
    box-sizing: border-box;
    padding: var(--side-margin);
    z-index: 9999999999999999;
    bottom: 0;
    top: 0;
    position: absolute;
    overflow: scroll;
    &.split-initial {
      transform: translateX(${(p) => (p.filtersOpen ? '0%' : '-100%')});
      width: calc(var(--first));
      left: 0;
      transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
      will-change: transform, box-shadow, background;
      max-height: 100vh;
      z-index: -1;

      .nav-standin {
        margin: calc(-1 * var(--side-margin));
        margin-bottom: var(--side-margin);
        position: sticky;
        top: calc(-1 * var(--side-margin));
        left: 0;
        right: 0;
        backdrop-filter: blur(48px);
        height: var(--s-nav-height);
        z-index: 999;
      }
      .fader {
        opacity: 0.35 !important;
        transition:
          filter 3s 0.5s,
          opacity 3s 0.5s,
          transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        will-change: opacity, transform;
      }
      &:hover {
        box-shadow: -50px 0 200px var(--c-border);
        background: var(--bgca);
        .fader {
          opacity: 1 !important;
          transition:
            opacity 0.5s,
            transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
          filter: blur(0px);
        }
      }
    }
    &.split-main {
      right: var(--last);
      left: ${(p) => (p.filtersOpen ? 'var(--first)' : '0px')};
      transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
      will-change: left, padding-left;
      margin: auto;
      flex-grow: 1;
      max-width: 720px;
      padding: calc(var(--side-margin) + var(--s-nav-height)) var(--side-margin) var(--side-margin)
        var(--side-margin);
    }
    &.split-sidebar {
      width: calc(var(--last) - 12px);
      max-width: 700px;
      top: calc(var(--s-nav-height));
      bottom: 12px;
      right: 12px;
      transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
      will-change: width;
      overflow: hidden !important;
      max-height: 100%;
      > div {
        transform: translateX(${(p) => (p.sidebarOpen ? 0 : 50)}%);
        opacity: ${(p) => (p.sidebarOpen ? 1 : 0)};
        transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        border-radius: var(--br-large);
        background: var(--c-bg-gray);
        will-change: transform, background-color, opacity;
        overflow: scroll;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
      }
    }
    &.footer * {
      color: var(--c-fg-1);
    }
  }
  .mobile-only {
    display: none;
  }
  .tablet-only {
    display: none;
  }
  .desktop-only {
    display: block;
  }
  .split-sidebar-inner {
    padding: calc(1.5 * var(--side-margin));
    &.padded {
      padding-bottom: calc(var(--side-margin) + var(--s-nav-height));
    }
  }
  @media (max-width: 1450px) {
    > div {
      &.split-main {
        padding-left: calc(2 * var(--side-margin));
        padding-right: calc(2 * var(--side-margin));
        right: 0;
      }
      &.split-sidebar {
        display: none;
      }
    }
    .tablet-only {
      display: block;
    }
    .desktop-only-strict {
      display: none !important;
    }
    .split-sidebar-inner {
      padding: calc(1 * var(--side-margin));
    }
  }
  @media (max-width: 1080px) {
    > div {
      &.split-main {
        left: 0px !important;
        width: 100%;
        padding-left: var(--side-margin) !important;
        padding-right: var(--side-margin) !important;
      }
      &.split-initial {
        display: none;
      }
    }
    .mobile-only {
      display: block;
    }
    .desktop-only {
      display: none !important;
    }
    > div.split-sidebar {
      display: none;
      &.open {
        display: block;
      }
      .split-sidebar-inner {
        padding: var(--side-margin);
      }
    }
  }
`;

export default SplitLayout;
