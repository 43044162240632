type EnvConfig = {
  [key: string]: string;
};

const variables = {
  CATCH_ENV: process.env.GATSBY_CATCH_ENV as 'dev' | 'prod',
  LOGROCKET_APP_ID: process.env.GATSBY_LOGROCKET_APP_ID as string,
  GATSBY_DATADOG_RUM_CLIENT_TOKEN: process.env.GATSBY_DATADOG_RUM_CLIENT_TOKEN as string,
  GATSBY_DATADOG_RUM_APPLICATION_ID: process.env.GATSBY_DATADOG_RUM_APPLICATION_ID as string,
  GATSBY_DATADOG_RUM_ENVIRONMENT: process.env.GATSBY_DATADOG_RUM_ENVIRONMENT as string,
};

export const env = {
  ...variables,

  // @todo: this shouldn't need a fallback, but im adding as a safety for now
  LOGROCKET_APP_ID: variables.LOGROCKET_APP_ID || '6an89b/catch-production',
  GATSBY_DATADOG_RUM_CLIENT_TOKEN: variables.GATSBY_DATADOG_RUM_CLIENT_TOKEN,
  GATSBY_DATADOG_RUM_APPLICATION_ID: variables.GATSBY_DATADOG_RUM_APPLICATION_ID,
  GATSBY_DATADOG_RUM_ENVIRONMENT: variables.GATSBY_DATADOG_RUM_ENVIRONMENT,

  // computed
  isDev: variables.CATCH_ENV === 'dev',
  isProd: variables.CATCH_ENV === 'prod',
};

// throws warning if anything is undefined
function checkEnv(config: EnvConfig): void {
  Object.entries(config).forEach(([key, value]) => {
    if (!value) {
      console.warn(`Warning: Environment variable ${key} is not set.`);
    }
  });
}

checkEnv(variables);
