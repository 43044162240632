import { useQuery, gql } from '@apollo/client';

import { gcmsClient } from 'src/utils/client';

export const PARTNER_QUERY = gql`
  query Partners($slug: String) {
    partner(where: { slug: $slug }) {
      id
      slug
      name
      website
      color {
        hex
      }
      color2 {
        hex
      }
      colorLight {
        hex
      }
      logo {
        url
      }
    }
  }
`;

export const usePartner = ({ slug } = {}) => {
  const { data, loading, error } = useQuery(PARTNER_QUERY, {
    client: gcmsClient,
    skip: !slug,
    fetchPolicy: 'cache-first',
    variables: {
      slug: slug?.toLowerCase()?.replace(/ /g, ''),
    },
  });

  return {
    color: data?.partner?.color?.hex,
    color2: data?.partner?.color2?.hex,
    colorLight: data?.partner?.colorLight?.hex,
    loading,
    error,
    logo: data?.partner?.logo?.url,
    website: data?.partner?.website,
  };
};
