import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const SBreadcrumbs = styled.div`
  display: flex;
  align-items: center;
  color: var(--fg);
  height: var(--height-nav);
  position: relative;
  z-index: 1;
  font-size: 1rem;
  a {
    color: var(--fg);
    &:hover {
      color: var(--color-flare-primary);
    }
  }
  .dlink {
    opacity: 0.35;
    font-weight: 600;
  }
`;
const SCaret = styled.div`
  margin: 0 8px 1px;
  font-size: 1.5rem;
  opacity: 0.25;
`;

const Caret = ({}) => <SCaret>&#8250;</SCaret>;

const Breadcrumbs = ({ links = [{ text: 'Home', current: true }] }) => {
  return (
    <SBreadcrumbs>
      {links?.map((link) => (
        <>
          {!!link?.path ? (
            <Link className="link" to={link?.path}>
              {link?.text}
            </Link>
          ) : (
            <div className="dlink">{link?.text}</div>
          )}
          {!link?.current && <Caret />}
        </>
      ))}
    </SBreadcrumbs>
  );
};

export default Breadcrumbs;
