import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

export interface ResourceProps {
  title: string;
  url?: string;
  text?: string;
  padded?: string;
  minor?: string;
  color?: string;
  iconColor?: string;
  icon?: React.ComponentType;
}

const StyledTitle = styled.div<{ minor?: string }>`
  font-size: 15px;
  font-weight: 450;
  opacity: ${(p) => (p.minor ? 0.65 : 0.85)};
  margin: 0;
`;

const StyledText = styled.div`
  color: var(--c-fg-1);
  font-weight: 400;
  margin: 0;
`;

const StyledResource = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  margin: 0;
  cursor: ${(p) => (p.href ? 'pointer' : 'default')};
  user-select: none;
  border-radius: 16px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--c-system);
  }

  &:hover {
    background: var(--c-border-light);
    ${StyledTitle}, ${StyledText} {
      opacity: ${(p) => (p.href ? 1 : '')} !important;
    }
  }
`;

const StyledIcon = styled.div`
  background: ${(p) => p.color || 'var(--c-border-light)'};
  height: 40px;
  width: 40px;
  margin-right: 12px;
  flex-shrink: 0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  &:hover {
    border-color: transparent;
  }
  > div {
    background: center/contain no-repeat;
    filter: var(--svgFilter);
    height: 24px;
    width: 24px;
  }
`;

/** ref forwarding and props spreading are needed for correct composition with Radix Nav & Gatsby
 * https://www.radix-ui.com/primitives/docs/guides/composition
 * */
export const Resource = React.forwardRef<HTMLAnchorElement, ResourceProps>(
  ({ title, url, text, padded, minor, color, iconColor, icon, ...props }, ref) => {
    return (
      <StyledResource
        padded={padded}
        to={url}
        target={/https/.test(url) ? '_blank' : '_self'}
        color={color}
        className="pressable"
        ref={ref}
        {...props}
      >
        {!!icon && (
          <StyledIcon color={color} className="icon" style={{ opacity: minor ? 0.65 : 1 }}>
            {React.createElement(icon, { color: iconColor || 'var(--c-fg-0)' })}
          </StyledIcon>
        )}

        <div>
          <StyledTitle minor={minor}>{title}</StyledTitle>
          {!!text && <StyledText>{text}</StyledText>}
        </div>
      </StyledResource>
    );
  },
);

Resource.displayName = 'Resource';

export default Resource;
