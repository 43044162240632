import lr from 'logrocket';
import { env } from 'src/env';

export const LogRocket = {
  init: () => {
    lr.init(env.LOGROCKET_APP_ID, {
      rootHostname: 'catch.co',
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase() === 'https://cognito-idp.us-west-2.amazonaws.com/') {
            request.body = undefined;
          }

          return request;
        },
      },
    });
  },
};
