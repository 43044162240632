import { useEffect, useMemo } from 'react';
import client from 'src/gatsby-plugin-apollo/client';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { StringParam, useQueryParams } from 'use-query-params';
import { useQuery, useMutation, GET_SESSION, UPSERT_SESSION } from 'src/data';
import { Segment, SegmentEvent } from 'src/lib';
import { ENV, ffmStates } from 'src/utils';
import { getCounty } from './useZipcode';

export interface PublicSessionStore {
  sessionID?: string;
  zip?: string;
  setSession: (pxid: string) => void;
  setZip: (zip: string) => void;
  clearSession: () => void;
}

export const useSession = create(
  persist<PublicSessionStore>(
    (set) => ({
      sessionID: undefined,
      zip: undefined,

      setSession: (sessionID) => set({ sessionID }),
      setZip: (zip) => set({ zip }),

      clearSession: () => set({ sessionID: undefined }),
    }),
    {
      name: 'public_session',
      onRehydrateStorage: () => (state) => {
        const stored = localStorage.getItem('catch_pxid');

        if (stored) {
          if (state) state?.setSession(stored);
          localStorage.removeItem('catch_pxid'); // Optional: remove after transferring
        }
      },
    },
  ),
);

export const usePublicSession = (root?: boolean) => {
  const [query] = useQueryParams({ sid: StringParam, zip: StringParam });
  const { sessionID, zip, setZip, setSession, clearSession } = useSession();
  const initializing = (!!query.zip && !zip) || (!!query.sid && !sessionID);

  useEffect(() => {
    if (query.zip) setZip(query.zip);
    if (query.sid) setSession(query.sid);
  }, [query]);

  /**
   * If there is no session ID (either from query or storage)
   * but there is a defined zip, we should create a new session
   */
  const shouldCreateSession = useMemo(() => {
    return !sessionID && !!zip;
  }, [sessionID, zip]);

  useQuery(GET_SESSION, {
    client,
    variables: { id: sessionID || '' },
    skip: !sessionID,
    onError: () => clearSession(),
    fetchPolicy: 'cache-first',
  });

  const [upsertSession, { loading: upserting }] = useMutation(UPSERT_SESSION, {
    client,
    onCompleted: (data) => {
      storeNewSession(data.upsertPublicHealthExplorerData.id);
    },
  });

  const storeNewSession = (newID: string) => {
    if (newID !== sessionID) {
      setSession(newID);

      Segment.track(SegmentEvent.PX_SESSION_CREATED, {
        pxId: newID,
        adminPxLink: `${ENV.adminUrl}/explorer/${newID}`,
      });
    }
  };

  const createNewSession = async (zip: string, year = 2025) => {
    const county = await getCounty(zip);

    upsertSession({
      variables: {
        input: {
          coverageYear: year,
          state: county?.state,
          countyfips: county?.fips,
          zipcode: county?.zipcode,
          pathwayType: ffmStates.includes(county?.state) ? 'EDE' : 'STATE_EXCHANGE',
        },
      },
    });
  };

  useEffect(() => {
    if (shouldCreateSession && root) {
      createNewSession(zip);
    }
  }, [shouldCreateSession, root]);

  return {
    loading: shouldCreateSession || initializing,
    sessionID,
    upsertSession,
    upserting,
  };
};
