import { useEffect } from 'react';
import { inIframe } from 'src/utils/browser';

export const IframeResizer = ({ children }) => {
  useEffect(() => {
    if (inIframe()) {
      const handleResize = () => {
        const pageHeight = Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight,
        );

        // post message for top level window to listen
        window.parent.postMessage(
          {
            type: 'iframe-resize', // the message type for matching
            iframeID: 'catch-iframe',
            width: document.body.clientWidth,
            height: pageHeight,
          },
          '*',
        );
      };

      window.addEventListener('resize', handleResize);

      // fires initial size on mount
      handleResize();

      // Cleanup listener on unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return children;
};
