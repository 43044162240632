import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { SessionContext } from 'src/context/SessionContext';
import { usePartner } from 'src/hooks/usePartner';
import { Terms } from './Terms';

interface PXLayoutProps {
  pretitle?: string;
  title: string;
  subtitle?: string;
  progress: number;
  children: any;
  style?: any;
  lightColor?: any;
  darkColor?: any;
  largeTitle?: boolean;
  precomponent?: any;
  middlecomponent?: any;
  loading?: boolean;
  toolbar?: any;
  wideTitle?: any;
  tint?: any;
  terms?: boolean;
  slide?: boolean; //default behavior is to slide component in from right
}

/**
 *
 */
const PXLayout = ({
  pretitle,
  title,
  subtitle,
  progress = 0,
  children,
  style,
  lightColor,
  darkColor,
  largeTitle,
  precomponent,
  middlecomponent,
  loading,
  toolbar,
  wideTitle,
  tint,
  long,
  terms,
  slide = true,
}: PXLayoutProps) => {
  const { partner, setProgress } = useContext(SessionContext);
  const { color, color2, colorLight } = usePartner({ slug: partner });

  useEffect(() => {
    if (setProgress) setProgress(progress);
    return () => {};
  }, [progress]);

  return (
    <SPXLayout
      style={style}
      color={darkColor ?? (color2 || color)}
      colorLight={lightColor ?? colorLight}
      className="split split-animate"
      tint={tint}
      long={long}
    >
      <div className="g"></div>
      <div className="_pxmain" data-sal={slide && 'slide-left'}>
        <div className="_pxmainb">
          <div style={{ marginBottom: 40 }}>
            {precomponent}
            {largeTitle ? (
              <h2 className={wideTitle && 'wide'} style={{ marginBottom: 0, fontSize: 40 }}>
                {pretitle && <p style={{ margin: 0, color: `var(--c-fg-1)` }}>{pretitle}</p>}
                {title || <Skeleton style={{ maxWidth: 700 }} />}
              </h2>
            ) : (
              <h3 className={wideTitle && 'wide'} style={{ marginBottom: 0 }}>
                {pretitle && <p style={{ margin: 0, color: `var(--c-fg-1)` }}>{pretitle}</p>}
                {title || <Skeleton style={{ maxWidth: 700 }} />}
              </h3>
            )}
            {middlecomponent}
            {(subtitle || loading) && (
              <p style={{ marginTop: 4, color: `var(--c-fg-1)` }}>
                {subtitle || <Skeleton style={{ maxWidth: 700 }} />}
              </p>
            )}
          </div>

          {loading ? <div>Loading...</div> : <div className="children">{children}</div>}

          {terms && <Terms />}
        </div>

        <div className="_pxtoolbar">{toolbar}</div>
      </div>
    </SPXLayout>
  );
};

const SPXLayout = styled.div`
  background: linear-gradient(
    to bottom,
    ${(p) => p.colorLight || 'var(--c-bg-0)'},
    ${(p) => (p.colorLight ? p.colorLight : p.color ? p.color + '66' : 'var(--c-red-1)')}
  );
  @media (prefers-color-scheme: dark) {
    background: linear-gradient(
      to bottom,
      var(--c-bg-0),
      ${(p) => (p.color ? p.color + '66' : 'var(--c-red-1)')}
    );
  }

  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;

  .g {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      var(--c-bg-0),
      ${(p) => (p.colorLight ? '#ffffff00' : '#ffffff99')}
    );
    @media (prefers-color-scheme: dark) {
      background: linear-gradient(to bottom, var(--c-bg-0), #11111199);
    }
  }
  ._pxmain {
    position: relative;
    box-sizing: border-box;
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    margin-top: calc(var(--s-nav-height) + var(--side-margin));
    margin-bottom: var(--side-margin);
    background: ${(p) => p.tint || 'var(--c-bg-0)'};
    border-radius: 24px;
    box-shadow: 0 25px 100px -10px rgba(0, 0, 0, 0.05);
    ._pxmainb {
      overflow: scroll;
      padding: calc(2 * var(--side-margin));
      padding-bottom: calc(var(--side-margin) + var(--s-nav-height));
      padding-top: var(--side-margin);
      @media (max-width: 768px) {
        padding: calc(1 * var(--side-margin));
        padding-bottom: calc(var(--side-margin) + var(--s-nav-height));
        bottom: 0;
        border-radius: 24px 24px 0 0;
      }
    }
    @media (prefers-color-scheme: dark) {
      background: ${(p) => p.tint || '#55555555'};
    }
    @media (max-width: 768px) {
      bottom: 0;
      border-radius: 24px 24px 0 0;
      padding-bottom: 0;
      margin-bottom: 0;
      max-height: 100%;
    }
    .children {
      margin: auto;
    }
  }
  ._pxtoolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @media (max-width: 768px) {
      bottom: 0;
    }
  }
`;

export default PXLayout;
