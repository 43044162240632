const BENEFITS = '/benefits';
const EXPLORER = '/explorer';
const GUIDES = '/guides';
const GUIDES_HEALTH = `${GUIDES}/health`;
const LEGAL = '/legal';

export default {
  ABOUT: '/about',
  AETNA: '/aetnacvshealth',
  BENEFITS,
  //BENEFITS_HEALTH: `${BENEFITS}/health`,
  BLOG: '/blog',
  //CAREERS: '/careers',
  COMMUNITY: '/community',
  CONTACT: '/contact',
  CREATOR: '/creatour',
  EXPLORER,
  EXPLORER_HANDOFF: `${EXPLORER}/handoff`,
  EXPLORER_INCOME: `${EXPLORER}/income`,
  EXPLORER_PEOPLE: `${EXPLORER}/people`,
  EXPLORER_PLANS: `${EXPLORER}/plans`,
  EXPLORER_ZIP: `${EXPLORER}/zip`,
  GET_STARTED: '/get-started',
  GUIDES,
  GUIDES_HEALTH,
  GUIDES_HEALTH_DATES: `${GUIDES_HEALTH}/dates`,
  GUIDES_HEALTH_SEP: `${GUIDES_HEALTH}/sep`,
  HOME: '/',
  HOW_IT_WORKS: '/how-it-works',
  JOBS: '/jobs',
  LEGAL,
  LICENSES: '/licenses',
  PARTNERS: '/partners',
  PRESS: '/press',
  PRIVACY_POLICY: `${LEGAL}/privacy`,
  TERMS_OF_USE: `${LEGAL}/terms`,
  TRUST: '/trust',

  LOGOS: '/misc/catch_logos.zip',
};
