import React from 'react';
import styled from 'styled-components';

const SOuter = styled.div`
  width: calc(var(--appgraphic-img-width) + var(--uicard-width) - var(--uicard-overlap));
  max-width: 100%;
  height: ${(p) =>
    p.screen
      ? 'calc(var(--uiscreen-height) + var(--uicard-overlapy))'
      : 'calc(var(--appgraphic-img-height) + var(--uicard-overlapy))'};
  margin: auto;
  position: relative;
  top: 0;
  @media (max-width: 800px) {
    max-width: 100%;
    border-radius: 0;
    top: -50px;
    height: calc(
      var(--appgraphic-img-height-mobile) + var(--uicard-height) - var(--uicard-overlapy)
    );
  }

  @media (max-width: 800px) {
    height: auto;
  }
`;

const SPhoto = styled.div`
  position: absolute;
  top: calc(0.5 * var(--uicard-overlapy));
  &.right {
    left: 0;
  }
  &.left {
    right: 0;
  }
  height: ${(p) => (p.screen ? 'var(--uiscreen-height)' : 'var(--appgraphic-img-height)')};
  width: var(--appgraphic-img-width);

  border-radius: var(--radius-xl);
  background-size: ${(p) => (p.contain ? 'contain' : 'cover')};
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(p) => p.image});
  @media (max-width: 800px) {
    width: 100%;
    height: var(--appgraphic-img-height-mobile);
    position: relative !important;
    top: unset;
    width: auto !important;
  }
`;

const SCard = styled.div`
  position: absolute;
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }
  &.top {
    top: calc(-0.5 * var(--uicard-overlapy));
  }
  &.bottom {
    bottom: 0;
  }
  @media (max-width: 800px) {
    width: 100%;
    top: 0;
    position: relative !important;
    height: auto !important;
    width: auto !important;
  }
`;

/**
 * @todo moible layout needs work
 */
const AppGraphic = ({ x = 'right', y = 'bottom', image, alt, component, contain, bgComponent }) => {
  return (
    <SOuter screen>
      {!!image ? (
        <SPhoto alt={alt} className={`${x} ${y}`} image={image} contain={contain}></SPhoto>
      ) : !!bgComponent ? (
        <SPhoto screen className={` ${x} ${y}`}>
          {bgComponent}
        </SPhoto>
      ) : (
        <></>
      )}

      <SCard className={`${x} ${y}`}>{component || <></>}</SCard>
    </SOuter>
  );
};

export default AppGraphic;
