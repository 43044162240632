import React from 'react';

import styled from 'styled-components';
import Container from './PXContainer';

const StyledToolbar = styled.div`
  border-radius: ${(p) => (p.top ? '24px 24px 0 0' : '0 0 24px 24px')};
  z-index: 9999;
  position: absolute;
  bottom: ${(p) => (p.top ? '' : '0px')};
  top: ${(p) => (p.top ? '0px' : '')};
  left: 0px;
  right: 0;
  height: ${(p) => (p.modal ? 'var(--s-nav-height)' : '')};
  display: flex;
  align-items: center;
  max-width: var(--width-read);
  margin: auto;
  box-sizing: border-box;
  backdrop-filter: blur(40px);
  border-top: ${(p) => (p.border ? '1px solid var(--c-border)' : '')};
  padding: ${(p) => (p.modal ? '' : 'var(--side-margin)')} 0
    ${(p) => (p.modal ? '' : 'var(--side-margin)')};
  > div {
    flex: 1;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    width: 100%;
    border-radius: ${(p) => (p.top ? '24px 24px 0 0' : '0')};
  }
`;

const Toolbar = ({ children, top, half, modal, bg, border }) => {
  return (
    <StyledToolbar border={border} bg={bg} className="tb" top={top} half={half} modal={modal}>
      <Container smallInner>
        <div className="flex-h">{children}</div>
      </Container>
    </StyledToolbar>
  );
};

export default Toolbar;
