import { useQuery, gql } from '@apollo/client';
import { gcmsClient } from 'src/utils/client';
import { locale } from 'src/utils';

export const SNIPPET_QUERY = gql`
  query Snippet($slug: String) {
    en: snippet(where: { slug: $slug }, locales: [en]) {
      body
      shortTitle
      subtitle
      slug
      title
      tldr
    }
    es: snippet(where: { slug: $slug }, locales: [es]) {
      body
      shortTitle
      subtitle
      slug
      title
      tldr
    }
  }
`;

export const useSnippet = (slug) => {
  const { data, loading, error } = useQuery(SNIPPET_QUERY, {
    client: gcmsClient,
    skip: !slug,
    fetchPolicy: 'cache-first',
    variables: {
      slug: slug?.toLowerCase(),
    },
  });

  // falls back to english if we can't find localized snippet
  const snippet = data?.[locale] || data?.en;

  return {
    snippet,
    loading,
    error,
  };
};
