import React from 'react';
import styled from 'styled-components';

const SBlock = styled.div`
  padding: 1.5rem 0;
  max-width: ${(p) => (p.width ? p.width + 'px' : 'var(--width-block)')};
  width: 100%;
  color: var(--fg);
  > * {
    margin-left: ${(p) => (p.center ? 'auto' : '')};
    margin-right: ${(p) => (p.center ? 'auto' : '')};
    text-align: ${(p) => (p.center ? 'center' : 'left')};
  }
  p.p,
  p.pl {
    margin-top: 0 !important;
    padding-top: 0 !important;
    margin-bottom: 0rem;
  }
  h2 {
    margin-bottom: 16px;
    margin-top: 16px;
  }
  h3 {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .pretitle {
    font-weight: 500;
    font-size: var(--pl);
    margin-top: -48px;
    margin-bottom: 24px;
    color: var(--color-${(p) => p.color}-dark);
  }
  b1,
  .b2,
  b2,
  .b3,
  b3 {
    color: var(--c-fg-0);
  }
  h2,
  h3 {
    b1 {
      margin-top: 4px;
      font-weight: 500;
    }
  }
`;

const SIcon = styled.div`
  background: var(--c-border) url(${(p) => p.icon}) center/
    ${(p) => (p.fill ? '75%' : p.large ? '75%' : '50%')} no-repeat;
  height: 84px;
  width: 84px;
  border-radius: 999px;
  filter: var(--svgFilter);
`;

const makeTitle = ({ title, subtitle, link, light }) => {
  return (
    <>
      {title}
      {!!subtitle && (
        <>
          <br />
          <b1 style={{ fontWeight: light ? 400 : 500 }} className={light ? 'o50' : ''}>
            {subtitle || link}
          </b1>
        </>
      )}
    </>
  );
};

/**
 * @deprecated
 * @todo add link
 */
const Block = ({
  w,
  icon,
  title,
  alt,
  subtitle,
  pretitle,
  text,
  children,
  smallBody,
  small,
  center,
  fillIcon,
  largeIcon,
  color,
  link,
  light,
  iconColor = 'ink',
}) => {
  return (
    <SBlock small={small} center={center} width={w} color={color}>
      {!!pretitle && <eb>{pretitle}</eb>}
      {!!icon && (
        <SIcon alt={alt} icon={icon} fill={fillIcon} large={largeIcon} color={iconColor} />
      )}
      {!!small ? (
        <h3 style={{ fontWeight: 500 }}>{makeTitle({ title, subtitle, link, light })}</h3>
      ) : (
        <h2>{makeTitle({ title, subtitle, link })}</h2>
      )}
      {!!text && !!smallBody ? <b3>{text}</b3> : <b1>{text}</b1>}
      {children}
    </SBlock>
  );
};

export default Block;
