import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const SImgOuter = styled.div`
  width: 100%;
  flex-basis: 35%;
  transform: rotateY(-10deg);
  transform-origin: right;
  transform-style: preserve-3d;
  position: relative;
  overflow: visible;
`;

const SHPost = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 0 auto var(--s-block-gap);
  justify-content: flex-start;
  perspective: 1000px;
  perspective-origin: center;
  transform-style: preserve-3d;
  position: relative;
  .postimg,
  .content {
    transition: 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
  }
  .img-cont {
    flex-basis: 35%;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .content {
    padding: 0 calc(3 * var(--side-margin));
    max-width: var(--width-read);
    transition: 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
  }
  &:hover {
    .postimg {
      transform: rotateY(0) scale(1.05);
    }
  }
  @media (max-width: 800px) {
    display: block;
    .content {
      padding: 2rem 0;
    }
    .postimg {
      width: 100% !important;
      flex-shrink: 0;
      /* position: relative; */
      .img-inner {
        width: 100% !important;
      }
    }
  }
`;

export type PostPreviewImageSize = 'Default' | 'Tall';

export interface PostPreviewProps {
  title: string;
  subtitle: string;
  eyebrow: string;
  link: string;
  teaser: string;
  color?: string;
  image: string;
  imageSize?: PostPreviewImageSize;
}

export const PostPreview = ({
  title,
  eyebrow,
  subtitle,
  link,
  teaser,
  color,
  image,
  imageSize = 'Default',
}: PostPreviewProps) => {
  return (
    <Link to={link}>
      <SHPost>
        <SImgOuter horizontal className="postimg" size={imageSize}>
          <GatsbyImage image={image} style={{ borderRadius: 24 }} />
        </SImgOuter>

        <div className="content">
          <h3
            className="r g-text"
            style={{
              marginTop: 0,
              backgroundImage: color ? `var(--g-${color}-text)` : 'var(--c-fg-1)',
            }}
          >
            {eyebrow}
          </h3>
          <h2 style={{ marginTop: 0 }}>
            {title}
            <br />
            <span className="o65 r">{subtitle}</span>
          </h2>

          <article className="article">{!!teaser && <p>{teaser}</p>}</article>
        </div>
      </SHPost>
    </Link>
  );
};
