import React from 'react';
import { createIntl, createIntlCache, MessageDescriptor } from 'react-intl';
import { messages } from '../lang';

export const LOCALE_EN = 'en';
export const LOCALE_ES = 'es';
const DEFAULT_LOCALE = LOCALE_EN;

class IntlStorage {
  locale = DEFAULT_LOCALE;

  constructor() {
    this.locale = this.getLocale();
    this.setLocale = this.setLocale.bind(this);
    this.toggleLocale = this.toggleLocale.bind(this);
  }

  setLocale(locale: string) {
    this.locale = locale;

    /** Prevent SSR execution */
    if (typeof window === 'undefined')return;

    /** Set locale in localStorage */
    window.localStorage.setItem('locale', locale);

    /** Set locale in cookie to persist language preference across app and website */
    const domainParts = window.location.hostname.split('.');
    const domain = domainParts.slice(-2).join('.');
    document.cookie = `locale=${locale}; domain=${domain}; path=/; max-age=31536000; SameSite=Lax`;

    window.location.reload();
  }

  getLocale(): string {
    /** Prevent SSR execution */
    if (typeof window === 'undefined')return DEFAULT_LOCALE;

    /** Attempt to get locale from localStorage */
    const localStorageLocale = window.localStorage
    ? window.localStorage.getItem('locale') || DEFAULT_LOCALE
    : DEFAULT_LOCALE;

    if (localStorageLocale) return localStorageLocale;

    /** Attempt to get locale from cookie otherwise */
    if (document.cookie) {
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === 'locale') return value;
      }
    }

    return DEFAULT_LOCALE;
  }

  toggleLocale() {
    this.setLocale(this.locale === LOCALE_EN ? LOCALE_ES : LOCALE_EN);
  }
}

let intlStorage;

/** Instantiate only in the client */
if (typeof window !== undefined) {
  intlStorage = new IntlStorage();
} else {
  /* SSR fallback */
  intlStorage = {
    locale: DEFAULT_LOCALE,
    setLocale: () => {},
    toggleLocale: () => {},
  };
}

export const locale = intlStorage.locale;
console.log('Using locale: ', locale);

export const getLocale = () => intlStorage.locale;
export const toggleLocale = intlStorage.toggleLocale;

/**
 * optional, but highly recommended
 * prevents a memory leak
 */
const cache = createIntlCache();

/**
 * the intl object -- gets passed to the provider
 */
const intl = createIntl(
  {
    locale: getLocale(),
    messages: messages[getLocale()],
  },
  cache,
);

interface TranslateProps extends MessageDescriptor {
  data?: Record<string, any>;
}

const richText = {
  b: (str) => <b>{str}</b>,
  m: (str) => <span className="m">{str}</span>,
  e: (str) => <span className="m mask-gradient" style={{backgroundImage: 'var(--g-cerise-text)'}}>{str}</span>,
};

/**
 * t is the main translate option
 * - it's named to provide brevity throughout the codebase
 * - the default message is required
 */
export const $t = (props: TranslateProps) => {
  return intl.formatMessage(
    {
      id: props.id,
      defaultMessage: props.defaultMessage,
    },
    {
      ...props.data,
      ...richText,
    },
  );
};
