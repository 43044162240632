import React from 'react';
import { constructMetaTags, PageMetadata, PrefetchApplicationType } from 'src/types/metadata';
import { Helmet } from 'react-helmet';
import { KEYWORDS } from 'src/data/meta';
import { DEFAULT_DESCRIPTION } from 'src/data/meta';
import ENV from 'src/utils/env';

const prefetchUrls: Record<PrefetchApplicationType, string> = {
  APP: ENV.appUrl,
  APP_RENDER: ENV.appSignUpUrl,
  PX: ENV.pxUrl,
  SITE: 'https://catch.co',
};

interface SeoProps {
  metadata?: PageMetadata | {};
  skipLd: boolean;
}

/**
 * @todo income source cascading and associations
 */
const Metadata = ({ metadata = {}, skipLd }: SeoProps) => {
  const {
    video,
    noIndex,
    datePublished,
    dateModified,
    section,
    tag,
    keywords = [],
    description = DEFAULT_DESCRIPTION,
    type,
    path,
    canonical,
    data,
    color,
    darkColor,
  } = metadata;

  const jsonLd = skipLd
    ? {}
    : constructMetaTags({
        ...metadata,
        pageType: 'article',
      });

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta name="robots" content="index,follow" />
      <meta name="name" content={metadata?.title} />
      <meta name="copyright" content="Catch Financial, LLC." />
      <meta property="og:site_name" content="Catch" />

      {/* geo and locale */}
      <meta name="language" content="EN-US" />
      <meta property="og:country-name" content="USA" />
      <meta property="og:locale" content="en_US" />

      {/* display */}
      <meta name="theme-color" content={color} media="(prefers-color-scheme: light)" />
      <meta name="theme-color" content={darkColor} media="(prefers-color-scheme: dark)" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#9b00e2" />
      <meta name="apple-mobile-web-app-title" content="Catch" />
      <meta name="application-name" content="Catch" />
      <meta name="msapplication-TileColor" content="#9b00e2" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, viewport-fit=cover"
      />

      {/* contact info */}
      <meta property="og:email" content="hey@catch.co" />
      <meta name="reply-to" content="hey@catch.co" />

      {/* social setup */}
      <link rel="shortcut icon" href="/icons/favicon.ico"></link>
      <meta property="fb:app_id" content="1092215927650784" />
      <meta property="fb:page_id" content="1949946635295657" />
      <meta property="twitter:site" content="@catchbenefits" />
      <meta property="twitter:creator" content="@catchbenefits" />
      <link rel="me" href="https://twitter.com/catchbenefits"></link>

      {/* page info */}
      <title>{`${metadata?.title || ''} | Catch`}</title>
      <meta property="twitter:title" content={metadata?.title} />
      <meta property="og:title" content={metadata?.title} />
      <meta
        property="og:type"
        content={
          !!video
            ? 'video.other'
            : /article|Article|Post|CaseStudy|Interview/.test(type)
              ? 'article'
              : ''
        }
      />

      {/* page description */}
      <meta property="twitter:description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      <meta name="keywords" content={[...keywords, ...KEYWORDS.Catch].join()} />
      {!!noIndex ? <meta name="robots" content="noindex" /> : ''}

      {/* image */}
      <meta property="og:image" content={metadata?.image} />
      <meta property="twitter:image" content={metadata?.image} />
      <meta name="image" content={metadata?.image} />

      {/* video */}
      <meta property="og:video" content={video} />
      <meta property="og:video:width" content="1920" />
      <meta property="og:video:height" content="1080" />

      {/* article */}
      <meta property="og:updated_time" content={dateModified} />
      <meta property="article:published_time" content={datePublished} />
      <meta property="article:modified_time" content={dateModified} />
      <meta property="article:section" content={section} />
      <meta property="article:tag" content={tag} />

      {/* app */}
      <meta name="application-name" content="Catch" />
      <meta name="apple-mobile-web-app-title" content="Catch" />
      {!metadata.hideAppSuggestion && <meta name="apple-itunes-app" content="app-id=1394681868" />}
      <meta property="twitter:app:name:iphone" content="Catch" />
      <meta property="twitter:app:name:ipad" content="Catch" />
      <meta property="twitter:app:name:googleplay" content="Catch" />
      <meta property="twitter:app:id:iphone" content="1394681868" />
      <meta property="twitter:app:id:ipad" content="1394681868" />
      <meta property="twitter:app:id:googleplay" content="com.catchcoapp" />

      {/* person */}
      <meta property="profile:first_name" content={metadata?.people?.[0]?.firstName} />
      <meta property="profile:last_name" content={metadata?.people?.[0]?.lastName} />

      {/* canonical */}
      {ENV.isDev && <meta name="robots" content="noindex, nofollow" />}
      {(!!path || !!canonical) && !ENV.isDev && (
        <link rel="canonical" href={canonical || `https://catch.co${path}`} />
      )}
      {(!!path || !!canonical) && !ENV.isDev && (
        <meta property="og:url" content={canonical || `https://catch.co${path}`} />
      )}

      {/* data tokens */}
      {data?.map((item, idx) => (
        <meta
          key={`twitter:label${idx + 1}`}
          name={`twitter:label${idx + 1}`}
          content={item.label}
        />
      ))}
      {data?.map((item, idx) => (
        <meta key={`twitter:data${idx + 1}`} name={`twitter:data${idx + 1}`} content={item.value} />
      ))}

      {/* structured data */}
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>

      {/* preload */}
      <link rel="prefetch" as="document" href={prefetchUrls.APP}></link>
      <link rel="prerender" href={prefetchUrls.APP_RENDER}></link>
      <link rel="preconnect" href="https://s.catch.co" crossOrigin="true"></link>
      <link rel="preconnect" href="https://media.graphassets.com" crossOrigin="true"></link>
    </Helmet>
  );
};

export default Metadata;
