import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { format } from 'date-fns';
import { GatsbyImage } from 'gatsby-plugin-image';

const SPost = styled.div`
  padding: 0 0 calc(var(--side-margin) * 1.5);
`;

const SHPost = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  margin: 0 auto calc(2 * var(--side-margin));
  border-radius: 8px;
  justify-content: flex-start;
  .postimg {
    width: 420px;
    height: auto;
    flex-shrink: 0;
    position: relative;

    transition: 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
    .img-inner {
      width: 100%;
      padding-top: 100%;
      height: auto;
    }
  }
  .content {
    padding: 0 8rem 0 4rem;
    max-width: var(--width-read);
    transition: 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
  }

  &:hover {
    .postimg {
      transform: scale(1.02);
      // box-shadow: var(--uicard-shadow-deep);
    }
  }
  @media (max-width: 800px) {
    display: block;
    .content {
      padding: 2rem;
    }
    .postimg {
      width: 100% !important;

      flex-shrink: 0;
      position: relative;
      .img-inner {
        width: 100% !important;
      }
    }
  }
`;

const SPPost = styled.div`
  margin: 72px 0;
  border-top: 1px solid var(--c-fg-0);
  &:hover {
    h3 {
      color: var(--c-cerise-0);
    }
  }
`;

const SBPost = styled.div`
  .postimg {
    width: 100%;
    margin: auto;
    flex-shrink: 0;
    position: relative;
  }
  .postimg {
    > div {
      // box-shadow: inset 104px 0 0 -100px ${(p) => p.color};
    }
    border-radius: 4px;
    transition: 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
  }
  .content {
    margin-top: 24px;
    transition: 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
  }
  &:hover {
    .postimg {
      transform: scale(1.02);
      // box-shadow: var(--uicard-shadow-deep);
    }
  }
  @media (max-width: 800px) {
    display: block;
    .content {
      padding: 2rem;
    }
    .postimg {
      width: 100% !important;
      height: 280px;
      flex-shrink: 0;
      position: relative;
      .img-inner {
        width: 100% !important;
        margin: auto;
      }
    }
  }
`;

const SImgOuter = styled.div`
  width: 100%;
  height: ${(p) => (!p.horizontal ? '300px' : '')};
  border-radius: var(--br-large);
`;

const SImg = styled.div`
  width: 100%;
  height: 300px;
  max-width: 420px;
  background: var(--color-ink-subtle) url(${(p) => p.img}) center/cover;
  border-radius: var(--br-large);
  margin: auto;
`;

const Post = ({ title, subtitle, image, link }) => {
  return (
    <SPost>
      <Link to={link}>
        {!!image && (
          <SImgOuter>
            <SImg alt={title} img={image} />
          </SImgOuter>
        )}
        <br />
        <h4 className="b">{title}</h4>
        {!!subtitle && (
          <p style={{ margin: 0 }} className="pl r o75">
            {subtitle}
          </p>
        )}
      </Link>
    </SPost>
  );
};

export const HPost = ({ title, subtitle, image, link, smallImg, tag, teaser, date }) => {
  return (
    <Link to={link}>
      <SHPost>
        {!!image && !smallImg && (
          <SImgOuter horizontal className="postimg">
            <GatsbyImage image={image} style={{ borderRadius: 24 }} />
          </SImgOuter>
        )}
        <div className="content">
          <h2>{title}</h2>
          {!!subtitle && <b1>{subtitle}</b1>}
          {!!teaser && <b1>{teaser}</b1>}
          {!!date && <b2>{format(Date.parse(date), 'MMMM d, Y')}</b2>}
          {!!tag && <eb>{tag?.replace('_', ' ')}</eb>}
        </div>
      </SHPost>
    </Link>
  );
};

/**
 * used for the tri-post template at the bottom of some pages
 */
export const BPost = ({ title, color, image, link, smallImg }) => {
  return (
    <Link to={link}>
      <SBPost color={color}>
        {!!image && !smallImg && (
          <SImgOuter horizontal className="postimg">
            <SImg alt={title} className="img-inner" horizontal img={image} loading="lazy" />
          </SImgOuter>
        )}
        <div className="content">
          <b1>{title}</b1>
        </div>
      </SBPost>
    </Link>
  );
};

/**
 *
 * @used on the press page
 */
export const PressPost = ({ title, color, link, date, outlet }) => {
  return (
    <Link to={link}>
      <SPPost color={color}>
        <div className="content">
          {!!outlet && <eb style={{ marginTop: 4, marginBottom: 0 }}>{outlet}</eb>}
          <h3 style={{ marginTop: 12, marginBottom: 0 }}>{title}</h3>
          {!!date && (
            <eb className="o50" style={{ marginTop: 12 }}>
              {format(Date.parse(date), 'MMMM d, Y')}
            </eb>
          )}
        </div>
      </SPPost>
    </Link>
  );
};

export default Post;
