import React from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import { useRedirect } from 'src/utils/links';

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 24px;

  h3 {
    margin: auto;
    margin-top: 16px;
    margin-bottom: 12px;
    max-width: 280px;
  }

  p {
    margin: 0;
  }
`;

export function RedirectModal() {
  const { externalUrl, seconds, cancel } = useRedirect();

  if (!externalUrl) return null;

  return (
    <Modal small open={externalUrl} onClose={cancel} showToolbar={false}>
      <Title>
        <h3>You're being redirected to our partner, Catch</h3>
        <p>You'll be automatically redirected in {seconds}...</p>
      </Title>
    </Modal>
  );
}
