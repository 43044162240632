import React from 'react';
import styled from 'styled-components';
import { peopleString } from '../utils';

const SAvatar = styled.div`
  border-radius: ${(p) => (p.square ? 'var(--radius-xl)' : '999px')};
  overflow: hidden;
  height: ${(p) => p.height}px;
  width: ${(p) => p.height}px;
  background: var(--color-ink-subtle) url(${(p) => p.img}) center/cover;
`;

const SAvatarGroup = styled.div`
  display: flex;
  align-items: center;
  height: ${(p) => p.height}px;
  font-weight: 500;
  .avatar {
    margin-right: -4px;
    border: 2px solid var(--bg);
    &:last-child {
      margin-right: ${(p) => (p.showNames ? 12 : 0)}px;
    }
  }
`;

const Avatar = ({ height = 32, url, square }) => {
  return <SAvatar className="avatar" square={square} height={height} img={url} />;
};

export const AvatarGroup = ({ people, height, showNames }) => {
  return (
    <SAvatarGroup showNames={showNames}>
      {people?.map((person, idx) => (
        <Avatar key={idx} height={height} url={person?.avatar?.url} />
      ))}
      {!!showNames && peopleString(people)}
    </SAvatarGroup>
  );
};

export default Avatar;
