import React from 'react';
import styled from 'styled-components';
import * as RadixMenu from '@radix-ui/react-navigation-menu';
import { Resource } from 'src/components/Resource';
export interface NavSubmenuProps {
  resources: Array<{
    title: string;
    url?: string;
    color?: string;
    iconColor?: string;
    icon?: React.ComponentType;
  }>;
}

const StyledSubmenu = styled.div`
  display: grid;
  padding: 16px;
  margin: 0;
  column-gap: 10px;
  list-style: none;
  width: 300px;
`;

export const NavSubmenu: React.FC<NavSubmenuProps> = ({ resources }: NavSubmenuProps) => {
  return (
    <StyledSubmenu>
      {resources.map((resource) => (
        <RadixMenu.Link asChild key={resource.title}>
          <Resource {...resource} />
        </RadixMenu.Link>
      ))}
    </StyledSubmenu>
  );
};
