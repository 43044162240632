import { isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import { apiKey } from 'src/utils';

export interface County {
  fips: string;
  name: string;
  state: string;
  zipcode: string;
}

export const getCounties = async (zip: string): Promise<Array<County>> => {
  // Month is 0-indexed so this is checking before Nov 1, 2024
  const beforeOE2025 = isBefore(new Date(), new Date(2024, 10, 1));

  /**
   * Note: override the year param to 2023 ahead of OE -- the results for
   * certain counties in CT are not mapping to Ideon's API
   */
  const yearParam = beforeOE2025 ? '&year=2023' : '';

  return fetch(
    `https://marketplace.api.healthcare.gov/api/v1/counties/by/zip/${zip}?apikey=${apiKey}${yearParam}`,
  )
    .then((response) => response.json())
    .then((data) => data.counties)
    .catch(() => {
      return;
    });
};

/**
 * Finds a default county
 * @param zip
 * @param fips
 * @returns
 */
export const getCounty = async (zip: string, fips?: string): Promise<County | undefined> => {
  const counties = await getCounties(zip);

  // match county by fips and return if found; otherwise, return the first in list
  if (counties.length > 0) {
    const found = counties.find((county) => county.fips === fips);
    return found || counties[0];
  }
};

/**
 * useZipcode
 * Helper hook for handling zip code and county lookups
 * @param initialValue
 * @returns
 */
export const useZipcode = (initialValue = '', options?: { assumeCounty?: boolean }) => {
  const [lookingUp, setLoading] = useState(true);
  const [zip, setZip] = useState(initialValue);
  const [place, setPlace] = useState<County | undefined>();
  const [counties, setCounties] = useState<Array<County>>([]);
  const [message, setMessage] = useState('');

  useEffect(() => setZip(initialValue), [initialValue]);

  const isValidZip = zip.length === 5;

  const handleCountyLookup = async (zip) => {
    setMessage('Loading...');

    const possibleCounties = await getCounties(zip);

    setMessage('');
    setLoading(false);
    setCounties(possibleCounties);

    if (possibleCounties.length > 1 && options?.assumeCounty) {
      const county = possibleCounties[0];
      setPlace(county);
      setMessage(`${county.name}, ${county.state}`);
    }

    if (possibleCounties.length === 1) {
      const county = possibleCounties[0];
      setPlace(county);
      setMessage(`${county.name}, ${county.state}`);
    }

    if (possibleCounties.length === 0) {
      setMessage("Sorry, this zip code either doesn't exist or is outside our service area");
    }
  };

  useEffect(() => {
    // if zip code changes, unset the place
    setPlace(undefined);
    setMessage('');
    setCounties([]);

    // if zip code is 5 digits, perform county lookup
    if (isValidZip) {
      handleCountyLookup(zip);
    } else {
      setLoading(false);
    }
  }, [zip]);

  return {
    lookingUp,
    zip,
    counties,
    place,
    message,
    setZip,
    setPlace,

    showCounties: counties && counties.length > 1,
    isValidLocation: isValidZip && !!place?.fips,
  };
};
