import React from 'react';

import styled from 'styled-components';

const V_MULT = 5;
const vary = (idx, total = 10, variance = 1, anchor = 0) =>
  (idx / total) * (Math.random() - (0.5 - anchor)) * variance * V_MULT;

const SStack = styled.div`
  position: relative;
  margin: auto;
`;

const SStackItem = styled.div`
  position: absolute;
  transform: translateY(${(p) => vary(p.idx, p.total, p.v, p.y) * 10}px)
    translateX(${(p) => vary(p.idx, p.total, p.v, p.x) * 10}px)
    rotateZ(${(p) => vary(p.idx, p.total, p.v)}deg);
  z-index: ${(p) => 100 - p.idx};
`;

const Stack = ({ items = [], variance = 1, x = 0, y = 0 }) => {
  return (
    <SStack>
      {items?.map((item, idx) => (
        <SStackItem key={idx} idx={idx} x={x} y={y} v={variance} total={items?.length}>
          {item}
        </SStackItem>
      ))}
    </SStack>
  );
};

export default Stack;
