import { useState, useEffect } from 'react';

/**
 * //@todo use useSession hook instead of this
 * temp usage for useAetnaSession
 * UPDATE - ed: added event listeners to track changes
 */
export const useNewLocalStorage = <T>(
  key: string,
  initialValue?: T,
): [
  T | undefined,
  (value: T | undefined | ((val: T | undefined) => T | undefined)) => void,
  () => void,
] => {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      const item = localStorage.getItem(key);

      return !!item ? JSON.parse(item) : initialValue;
    } catch (err) {
      console.error(err);
      return initialValue;
    }
  });

  const setValue = (value: T | undefined | ((val: T | undefined) => T | undefined)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      if (!!key) {
        if (valueToStore === null || valueToStore === undefined) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const removeValue = () => {
    if (!!key) {
      localStorage.removeItem(key);
      setStoredValue(initialValue);
    }
  };

  useEffect(() => {
    //listen to changes in localStorage from other components
    const handleChange = (e: StorageEvent) => {
      if (e.key === key) {
        try {
          const newVal = e.newValue ? JSON.parse(e.newValue) : initialValue;
          setStoredValue(newVal);
        } catch (error) {
          console.error(error);
        }
      }
    };

    window.addEventListener('storage', handleChange);

    //set initial value
    if (initialValue !== undefined && !localStorage.getItem(key)) setValue(initialValue);

    return () => {
      window.removeEventListener('storage', handleChange);
    };
  }, [initialValue, key]);

  return [storedValue, setValue, removeValue];
};

export default useNewLocalStorage;
