import React from 'react';
import { useSnippet } from 'src/hooks';
import { Modal, Snippet } from '..';

/**
 *
 *
 */
const SnippetModal = ({ onClose, slug, open }) => {
  const { snippet } = useSnippet(slug);

  return (
    <Modal open={open} onClose={onClose}>
      <Snippet post={snippet} />
    </Modal>
  );
};

export default SnippetModal;
