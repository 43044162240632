import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const SCarousel = styled.div`
  display: flex;
  justify-content: flex-start;
  perspective: 1000px;
  perspective-origin: center;
  position: relative;
  overflow: scroll;
  transform-style: preserve-3d;
  margin: 0 auto 0;
  padding: 4rem 4rem 4rem 8rem;
  max-width: 1500px;
  @media (max-width: 1500px) {
    margin: 0 calc(-1 * var(--side-margin));
    padding: 2rem var(--side-margin);
  }
`;

const SItem = styled(Link)`
  overflow: visible;
  transition: 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
  transform: rotateY(20deg) translateZ(0px);
  transform-style: preserve-3d;
  transform-origin: left;
  margin-right: -100px;
  cursor: pointer;
  .name {
    transform: rotateY(-20deg) translateZ(0);
    transform-origin: left;
    transition: 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
    text-align: left;
  }
  &:hover {
    transform: rotateY(10deg) translateX(calc(-1 * var(--side-margin)));
    .name {
      transform: rotateY(-10deg) translateX(calc(1 * var(--side-margin)));
    }
  }
`;

const SItemInner = styled.div`
  width: 500px;

  background: ${(p) => (p.img ? `url(${p.img}) center/cover` : `var(--g-${p.color}-light)`)};
  border-radius: var(--br-large);
  box-shadow: 0 0 4rem #00000022;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 350px;
  }
`;

const SItemIcon = styled.div`
  background: url(${(p) => p.icon}) center/contain;
  width: 200px;
  height: 200px;
  margin-top: -75%;
`;

export interface CarouselItemProps {
  title: string;
  subtitle?: string;
  image?: string;
  color?: string;
  path?: string;
  icon?: string;
}

export interface CarouselProps {
  items: Array<CarouselItemProps>;
}

export const CarouselItem = ({ title, subtitle, image, color, path, icon }: CarouselItemProps) => {
  return (
    <SItem to={path}>
      <SItemInner color={color}>
        <GatsbyImage image={image} style={{ minHeight: '100%', minWidth: '100%' }} />
        <SItemIcon icon={icon} />
      </SItemInner>
      <h3 className="name">
        {title}
        <br />
        <span className="o50 l">{subtitle}</span>
      </h3>
    </SItem>
  );
};

export const Carousel3d = ({ items }: CarouselProps) => {
  return (
    <SCarousel>
      {items?.map((item) => (
        <CarouselItem
          key={item.path}
          title={item.title}
          color={item?.color}
          path={item.path}
          image={item.image}
          icon={item.icon}
        />
      ))}
    </SCarousel>
  );
};

export default Carousel3d;
