export const stateExchanges = {
  CA: {
    name: 'Covered California',
    url: 'http://www.coveredca.com/',
  },
  CO: {
    name: 'Connect for Health Colorado',
    url: 'http://www.connectforhealthco.com/',
  },
  CT: {
    name: 'Access Health CT',
    url: 'https://www.accesshealthct.com/',
  },
  DC: {
    name: 'DC Health Link',
    url: 'https://dchealthlink.com/',
  },
  ID: {
    name: 'Your Health Idaho',
    url: 'http://www.yourhealthidaho.org/',
  },
  KY: { name: 'Kynect', url: 'https://kynect.ky.gov' },
  MD: {
    name: 'Maryland Health Connection',
    url: 'http://www.marylandhealthconnection.gov/',
  },
  ME: { name: 'CoverME', url: 'https://coverme.gov' },
  MA: {
    name: 'Massachusetts Health Connector',
    url: 'http://www.mahealthconnector.org/',
  },
  MN: {
    name: 'MNsure',
    url: 'http://www.mn.gov/hix/',
  },
  NM: { name: 'beWellnm', url: 'https://www.bewellnm.com/' },
  NV: {
    name: 'Nevada Health Link',
    url: 'https://www.nevadahealthlink.com/',
  },
  NJ: { name: 'Get Covered NJ', url: 'https://www.getcovered.nj.gov/' },
  NY: {
    name: 'New York State of Health',
    url: 'http://healthbenefitexchange.ny.gov/',
  },
  PA: { name: 'Pennie', url: 'https://www.pennie.com/' },
  RI: {
    name: 'HealthSource RI',
    url: 'http://www.healthsourceri.com/',
  },
  VT: {
    name: 'Vermont Health Connect',
    url: 'http://healthconnect.vermont.gov/',
  },
  WA: {
    name: 'Washington Healthplanfinder',
    url: 'http://www.wahealthplanfinder.org/',
  },
};
