import React from 'react';
import styled from 'styled-components';

const SQuoteOuter = styled.div`
  padding: 0 calc(var(--side-margin) / 2) calc(var(--side-margin) / 2);
  box-sizing: border-box;
  border-radius: var(--br-large);
  background: var(--c-bg-2);
  width: 360px;
`;

const SQuote = styled.div`
  text-align: center;
  margin: auto;
  h3 {
    margin-top: 24px;
    max-width: 96%;
    padding-left: 4%;
    font-weight: 400;
    line-height: 1.25;
  }
`;

const SPhoto = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 999px;
  background: var(--c-border) url(${(p) => p.bg}) center/cover;
  margin-top: -32px;
  margin-bottom: 24px;
`;

export const Quote = ({ body, name, title, image }) => {
  return (
    <SQuoteOuter>
      <SQuote>
        {!!image && <SPhoto bg={image} />}
        <img loading="lazy" src="/graphics/quote.svg" alt="quotation mark" />
        <h3>
          <q>{body}</q>
        </h3>
        <b1>{name}</b1>
        <eb>{title}</eb>
      </SQuote>
    </SQuoteOuter>
  );
};

export default Quote;
