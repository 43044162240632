import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import SectionTitle from '../SectionTitle';
import { Terms } from '../Terms';

const BG = 'var(--c-bg-0)';

const SSplitHero = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: calc(var(--s-nav-height));
  background: black
    linear-gradient(
      to bottom,
      ${(p) => p.colorLight || p.color},
      ${(p) => p.colorLight || p.color + 'CC'}
    );
  @media (prefers-color-scheme: dark) {
    background: black linear-gradient(to bottom, ${(p) => p.color}, ${(p) => p.color + 'CC'});
  }

  a {
    font-weight: 600;
  }

  > .inner {
    margin: auto;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--side-margin);
    align-items: center;
    width: 100%;
    .top {
      &.filter {
        filter: ${(p) => !p.colorLight && 'invert(100%) saturate(0) brightness(500%)'};
        @media (prefers-color-scheme: dark) {
          filter: invert(100%) saturate(0) brightness(500%);
        }
      }
      margin-bottom: 36px;
      img {
        max-width: 150px;
        max-height: 64px;
        @media (max-width: 1080px) {
          max-width: 120px;
          max-height: 48px;
        }
      }
    }
    @media (max-width: 1400px) and (min-width: 1080px) {
      h1 {
        font-size: var(--fs-h2) !important;
      }
    }
    @media (max-width: 1080px) {
      grid-template-columns: 1fr;
    }
    .image {
      background: var(--c-bg-0) ${(p) => !p.image && `url('/img/david2.png')`} center/cover
        no-repeat;
      padding-top: 100%;
      position: relative;
      > * {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
    .body {
      padding: var(--side-margin);
      box-sizing: border-box;
      color: ${(p) => (p.color === BG || p.colorLight ? 'var(--c-fg-0)' : 'white')} !important;
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 1080px) {
      display: block;

      .image {
        mask-image: ${(p) => (p.image ? 'linear-gradient(to bottom, transparent, black 95%)' : '')};
        padding-top: 60%;
      }
    }
  }
`;

/**
 *
 * @todo logo filter
 * @todo pass signup context
 * @todo responsive
 *
 */
export const NewHero = ({
  title,
  subtitle,
  image,
  color = BG,
  colorLight,
  logo,
  applyFilter,
  promo,
  eb,
  children,
  fine,
  terms,
}) => {
  return (
    <SSplitHero color={color} image={image} colorLight={colorLight}>
      <div className="inner">
        <div className="body">
          {!!logo && (
            <div className={`top${applyFilter ? ' filter' : ''}`}>
              <GatsbyImage image={logo} alt={'Logo'} objectFit="contain" objectPosition="left" />
            </div>
          )}
          <div className="mid">
            <SectionTitle title={title} subtitle={subtitle} promo={promo} pretitle={eb} />
            {!!fine && (
              <div
                style={{
                  marginTop: 24,
                  marginBottom: 24,
                  fontWeight: 450,
                }}
              >
                {fine}
              </div>
            )}
            {terms && <Terms marginTop={-12} />}

            {children}
          </div>
        </div>

        <div className="image">
          <GatsbyImage image={image} alt={title} />
        </div>
      </div>
    </SSplitHero>
  );
};
