import React from 'react';
import styled from 'styled-components';

const SInput = styled.input`
  border: 1px ${({ $invalid }) => ($invalid ? 'var(--c-red-0)' : 'transparent')} solid;
  color: var(--c-fg-0);
  font-size: var(--fs-base);
  font-weight: normal;
  padding: 0 20px;
`;

export interface Input2Props {
  autoComplete?: string;
  invalid?: boolean;
  name: string;
  required?: boolean;
  type?: 'email' | 'text' | 'textarea';
}

export default function Input2({ invalid, type, ...props }: Input2Props) {
  const commonProps = {
    $invalid: invalid,
    ...props,
  };

  if (type === 'textarea') {
    return <textarea {...commonProps} />;
  }

  return <SInput {...commonProps} type={type} />;
}
