import React from 'react';
import styled from 'styled-components';

const SBanner = styled.div`
  height: var(--banner-height);
  top: var(--s-nav-height);
  position: absolute;
  left: 0;
  right: 0;
  backdrop-filter: blur(25px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => (p.textColor ? p.textColor : p.hex || p.color ? 'white' : 'var(--bg)')};
  background: ${(p) => (p.hex ? p.hex : p.color ? p.color : 'var(--fg)')};
  z-index: -1;
  font-weight: 500;
  font-size: var(--fs-b3);
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 var(--side-margin);
  margin: 0 calc(-1 * var(--side-margin));
  @media (max-width: 800px) {
    justify-content: flex-start;
    white-space: normal;
    text-align: center;
    margin: 0;
  }
`;

const Banner = (props) => {
  const {
    color,
    accentColor,
    textColor,
    text = 'Banner',
    link,
    explicitBannerColor,
    hideBanner,
  } = props;

  if (hideBanner) {
    return null;
  }

  return (
    <a href={link}>
      <SBanner color={color} hex={explicitBannerColor && accentColor} textColor={textColor}>
        {text} &#8594;
      </SBanner>
    </a>
  );
};

export default Banner;
