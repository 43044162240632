import React, { cloneElement, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

const SDiv = styled.div`
  width: fit-content;
  background: var(--bgc);
  border: 1px transparent solid;
  border-radius: 999px;
  box-shadow: 0 4px 32px #25272e1a;
  display: flex;
  overflow: hidden;
  transition: border var(--transition-duration) var(--transition-ease);

  &:has(input:user-invalid) {
    border-color: var(--c-red-0);
  }

  &:has(input:focus) {
    border-color: transparent !important;
  }
`;

const SButtonWrap = styled.div`
  margin: 4px;
  margin-left: 0;
`;

interface FieldWithButtonProps {
  button: ReactNode;
  field: ReactElement;
}

export default function FieldWithButton({ button, field }: FieldWithButtonProps) {
  return (
    <SDiv>
      {cloneElement(field, {
        labelAsPlaceholder: true,
      })}
      <SButtonWrap>{button}</SButtonWrap>
    </SDiv>
  );
}
