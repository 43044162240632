import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'src/components';
import * as RadixMenu from '@radix-ui/react-navigation-menu';
import { Icon, IconType } from '../Icon/Icon';

export interface NavLinkProps {
  path?: string;
  text: string;
  icon?: IconType;
  onClick?: () => void;
}

export interface NavTriggerProps {
  text: string;
  icon?: IconType;
}

const styles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  user-select: none;

  padding: 8px 12px;
  font-weight: 450;
  border-radius: 12px;
  font-size: 15px;
  font-family: var(--ff-medium);
  color: var(--c-fg-0);
  font-variation-settings: 'opsz' 20;
  opacity: 0.75;
  transition: 0.1s;

  &:hover {
    background: var(--c-border-light);
    color: var(--c-fg-0);
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 0 0 2px var(--c-system);
  }
`;

const StyledLink = styled(RadixMenu.Link)`
  ${styles}
`;

const StyledTrigger = styled(RadixMenu.Trigger)`
  ${styles}

  padding-right: 6px;

  .arrow {
    margin-left: 4px;
    position: relative;
    top: 1px;
    transition: transform 250ms ease;
  }

  &[data-state='open'] > .arrow {
    transform: rotate(-180deg);
  }
`;

const StyledIcon = styled(Icon)<{ $hasSibling: boolean }>`
  margin-right: ${({ $hasSibling }) => ($hasSibling ? 4 : 0)}px;
`;

export function NavLink({ path, text, icon, onClick }: NavLinkProps) {
  const linkIcon = icon ? <StyledIcon type={icon} size={20} $hasSibling={!!text} /> : null;

  const handleKeyDown = ({ key }) => {
    if (key === 'Enter' && onClick) {
      onClick();
    }
  };

  return (
    <StyledLink asChild active={false}>
      {path ? (
        <Link to={path}>
          {linkIcon} {text}
        </Link>
      ) : (
        <button type="button" onClick={onClick} onKeyDown={handleKeyDown}>
          {linkIcon} {text}
        </button>
      )}
    </StyledLink>
  );
}

export function NavTrigger({ text }: NavTriggerProps) {
  return (
    <StyledTrigger>
      {text} <Icon type="ChevronDown" aria-hidden className="arrow" size={16} />
    </StyledTrigger>
  );
}
