import React, { useMemo } from 'react';
import styled from 'styled-components';
import { getCarrierLogo } from '@catch-co/health-utils';
import { $t, ENV, formatCurrency, metalLabels } from 'src/utils';
import { Range, PXSection as Section, PriceChart, FilterTile } from 'src/components';

const $ = (v) => formatCurrency({ value: v });

const SLogo = styled.div`
  background-color: var(--fgaa);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding-top: 100%;
  border-radius: 999px;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  transition: 0.15s;
  &:active {
    transform: scale(0.97);
  }
`;

const PLACEHOLDERS = [
  {
    label: $t({ id: 'health-plan.easy-pricing', defaultMessage: 'Easy Pricing' }),
    type: 'Standard',
  },
  { label: $t({ id: 'health-plan.HSA', defaultMessage: 'HSA' }), type: 'HSA' },
];

/**
 *
 *
 * Filters Modal
 * WIP
 *
 */
export const Filters = ({
  plans = [],
  premium,
  deductible,
  networks = [],
  metals = [],
  issuers = [],
  setPremium,
  setDeductible,
  setNetworks,
  setMetals,
  setIssuers,
  minPremium = 0,
  maxPremium = 500,
  minDeductible = 0,
  maxDeductible,
  filters,
  setFilters,
  color,
  isDental,
  onInfo,
  options,
  setOptions,
}) => {
  const __issuers = useMemo(() => new Set(plans?.map((p) => p.issuer)), [plans]);
  const _issuers = useMemo(
    () =>
      new Array(...__issuers)?.sort(
        (a, b) =>
          plans?.filter((p) => p.issuer === b).length - plans?.filter((p) => p.issuer === a).length,
      ),
    [__issuers],
  );

  return (
    <div>
      <Section
        title={$t({ id: 'health-plan.Premium', defaultMessage: 'Premium' })}
        subtitle={` ${$(minPremium)} – ${$(maxPremium)} ${$t({
          id: 'health-plan.per-month',
          defaultMessage: 'per month',
        })}`}
        onInfo={() => onInfo('aptc')}
      >
        <PriceChart
          color={color}
          plans={plans}
          max={maxPremium}
          property="premium"
          isDental={isDental}
        />
        <Range
          color={color}
          value={Math.min(premium, maxPremium)}
          max={maxPremium}
          onChange={setPremium}
        />
      </Section>

      <Section
        title={$t({ id: 'health-plan.Deductible', defaultMessage: 'Deductible' })}
        onInfo={() => onInfo('deductibles')}
        subtitle={` ${$(minDeductible)} – ${$(maxDeductible)} ${$t({
          id: 'health-plan.per-year',
          defaultMessage: 'per year',
        })}`}
      >
        <PriceChart
          color={color}
          plans={plans}
          max={maxDeductible}
          property="deductible"
          isDental={isDental}
        />
        <Range
          color={color}
          value={Math.min(deductible, maxDeductible)}
          max={maxDeductible}
          onChange={setDeductible}
        />
      </Section>

      <Section
        title={$t({ id: 'health-plan.Metal', defaultMessage: 'Metal' })}
        onInfo={() => onInfo('metals')}
      >
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gridGap: 6 }}>
          {(isDental ? ['Low', 'High'] : ['Bronze', 'Silver', 'Gold', 'Platinum'])?.map((t) => (
            <FilterTile
              key={t}
              large
              label={metalLabels[t]}
              text={t}
              icon="Metal"
              active={metals.includes(t)}
              partialActive={metals?.length === 0}
              onPress={(t) =>
                metals.includes(t)
                  ? setMetals(metals?.filter((a) => a !== t))
                  : setMetals([...metals, t])
              }
            />
          ))}
        </div>
      </Section>

      {!isDental && (
        <Section
          title={$t({ id: 'health-plan.Network', defaultMessage: 'Network' })}
          onInfo={() => onInfo('networks')}
        >
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gridGap: 6 }}>
            {['HMO', 'PPO', 'EPO', 'POS']?.map((t) => (
              <FilterTile
                color={color}
                key={t}
                text={t}
                active={networks.includes(t)}
                partialActive={networks?.length === 0}
                onPress={(t) =>
                  networks.includes(t)
                    ? setNetworks(networks?.filter((a) => a !== t))
                    : setNetworks([...networks, t])
                }
              />
            ))}
          </div>
        </Section>
      )}

      <Section title={$t({ id: 'health-plan.Insurer', defaultMessage: 'Insurer' })}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr ',
            gridGap: 8,
            maxWidth: 300,
          }}
        >
          {_issuers?.map((t) => (
            <SLogo
              key={t}
              className={issuers.includes(t) ? 'active' : 'fader'}
              style={{
                backgroundImage: `url(https://s.catch.co/img/carriers/${getCarrierLogo(t)}.png)`,
                boxShadow: issuers?.includes(t) ? '0 0 0 1.5px white, 0 0 0.5px 3px black' : '',
                opacity: issuers?.includes(t) || issuers?.length === 0 ? 1 : 0.25,
              }}
              onClick={() =>
                issuers.includes(t)
                  ? setIssuers(issuers?.filter((a) => a !== t))
                  : setIssuers([...issuers, t])
              }
            ></SLogo>
          ))}
        </div>
      </Section>

      {!isDental && (
        <Section
          title={$t({ id: 'health-plan.Features', defaultMessage: 'Features' })}
          onInfo={() => onInfo('standard')}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gridGap: 6,
            }}
          >
            {PLACEHOLDERS?.map(({ type, label }) => (
              <FilterTile
                key={type}
                large
                text={type}
                icon={type}
                label={label}
                active={filters?.[type]}
                partialActive
                onPress={() => setFilters({ ...filters, [type]: !filters[type] })}
              />
            ))}
          </div>
        </Section>
      )}

      {!isDental && ENV.isDev && (
        <Section title="Options" subtitle="dev only">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr  ',
              gridGap: 6,
            }}
          >
            {['Standard Naming']?.map((t) => (
              <FilterTile
                key={t}
                text={t}
                icon={t}
                active={options?.[t]}
                partialActive
                onPress={(t) => setOptions({ ...options, [t]: !options[t] })}
              />
            ))}
          </div>
        </Section>
      )}
    </div>
  );
};
