export const properCase = (str) =>
  `${str?.slice(0, 1)?.toUpperCase()}${str?.slice(1)?.toLowerCase()}`;

const PLURALS = {
  person: 'people',
};

export const formatString = (string, number) => {
  if (number === 1) return string;
  else return PLURALS[string];
};

export const capitalize = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
