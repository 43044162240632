import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { isString } from 'lodash';

export const Markdown = ({ children }) => {
  return (
    <>
      {isString(children) ? (
        <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} className="markdown">
          {children}
        </ReactMarkdown>
      ) : (
        children
      )}
    </>
  )
};
