import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '..';
import { Segment, SegmentEvent } from 'src/lib';

const SInput = styled.div`
  input,
  textarea {
    border: 1px solid var(--fga);
    border-radius: 12px;
    margin-bottom: 12px;
    width: 100%;
    display: block;
    appearance: none;
    font-size: var(--pl);
    padding: 12px 16px;
    font-family: var(--ff-base);
    font-weight: 500;
    background: transparent;
    color: var(--fg);
    outline: none;
    &:focus {
      border-color: var(--fg);
      box-shadow: inset 0 0 0 0.5px var(--fg);
    }
    &[type='submit'] {
      background: var(--fg);
      color: var(--bg);
      cursor: pointer;
      &:active {
        opacity: 0.9;
      }
    }
  }
`;

/**
 *
 * W6VdbnJp: partners form
 * 1bH5iIH7: press form
 *
 */
const FormModal = ({ onClose, title = 'Get in touch', formspringId = 'W6VdbnJp', open }) => {
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  return (
    <Modal open={open} onClose={onClose}>
      <h3>{title}</h3>

      <form
        action={
          /fodiscomail|sibicomail/.test(email) || company === 'google'
            ? ``
            : `https://submit-form.com/${formspringId}`
        }
        onSubmit={() => {
          if (/fodiscomail|sibicomail/.test(email)) {
          } else {
            Segment.identify({ email, name });

            Segment.track(SegmentEvent.PARTNER_FORM_SUBMITTED, {
              name,
              company,
              email,
              message,
            });
            return true;
          }
        }}
      >
        <input type="hidden" name="_redirect" value="https://catch.co/thanks" />
        <SInput>
          <input
            type="name"
            name="name"
            autoComplete="name"
            inputMode="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="company"
            name="company"
            inputMode="text"
            autoComplete="organization"
            placeholder="Company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <input
            required
            type="email"
            name="email"
            inputMode="email"
            autoComplete="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            type="textarea"
            name="message"
            placeholder="Message"
            onChange={(e) => setMessage(e.target.value)}
          />
          <br />

          <center>
            <input type="submit" value="Send message" style={{ display: 'block' }} />
          </center>
        </SInput>
      </form>
    </Modal>
  );
};

export default FormModal;
