import React from 'react';
import styled from 'styled-components';

const SDivider = styled.div`
  margin: ${(p) => (p.small ? '3rem' : 'var(--side-margin)')} auto;
  height: 2px;
  background: var(--color-${(p) => p.color}-primary);
  width: 200px;
`;

const Divider = ({ color = 'ink', small }) => {
  return <SDivider small={small} color={color} />;
};

export default Divider;
