import React from 'react';
import { getCarrierLogo } from '@catch-co/health-utils';
import styled from 'styled-components';
import { cleanSource } from '../utils/sources';

const SLogo = styled.div`
  background-image: url(${(p) => p.domain}/${(p) => p.type}/${(p) => p.source}.${(p) => p.ext});
  background-size: ${(p) => (p.ext === 'svg' ? '75%' : 'cover')};
  background-position: center;
  background-repeat: no-repeat;
  height: ${(p) => p.size}px;
  width: ${(p) => p.size}px;
  border-radius: 99px;

  flex-shrink: 0;
`;

export type ImageCollection = 'sources' | 'carriers';

export interface SourceLogoProps {
  size?: number;
  collection?: ImageCollection;
  source: string;
}

const Component = ({ size = 72, collection = 'sources', source = 'square' }) => {
  return (
    <SLogo
      size={size}
      source={collection === 'carriers' ? getCarrierLogo(source) : cleanSource(source) || 'square'}
      type={collection}
      ext={collection === 'goals' ? 'svg' : 'png'}
      domain={collection === 'goals' ? '' : 'https://s.catch.co/img'}
    />
  );
};

export default Component;
