import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  defaultDataIdFromObject,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-fetch';
import { ENV } from '../utils';

const httpLink = new HttpLink({
  uri: ENV.apiUrl,
  fetch,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
  typePolicies: {
    ScoredHealthPlan: {
      merge: (og, incoming, opts) => opts.mergeObjects(og, incoming),
    },
    HealthPlan: {
      merge: (og, incoming, opts) => opts.mergeObjects(og, incoming),
    },
    Query: {
      fields: {
        publicHealthPlanDetails: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'HealthPlan',
              id: args.planID,
            });
          },
        },
        getPublicExplorerData: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'PublicHealthExplorerData',
              id: args.id,
            });
          },
        },
        getPublicExplorerDependent: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'PublicHealthExplorerDependent',
              id: args.id,
            });
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  connectToDevTools: true,
  cache,
  name: 'public',
  version: '2022-10',
  link: from([errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialResults: true,
      returnPartialData: true,
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      returnPartialResults: true,
    },
  },
});

export default client;
