import React from 'react';
import styled from 'styled-components';

const SBlankBox = styled.div`
  height: 600px;
  width: 500px;
  background: ${(p) => p.color};
  margin-top: 24px;
  right: -64px;

  border-radius: 39px;
  display: flex;
  align-items: ${(p) => (p.screen ? 'flex-end' : 'center')};
  justify-content: center;
  @media (max-width: 800px) {
    margin-top: 64px;

    right: 0;
    width: 100%;
  }
`;

export const BlankBox = ({ children, color = 'var(--g-teal)', screen }) => (
  <SBlankBox color={color} screen={screen}>
    {children}
  </SBlankBox>
);

export const GridBox = ({ children, textColor, color = 'var(--g-teal)' }) => (
  <div
    style={{
      height: 350,
      borderRadius: `var(--br-large)`,
      padding: 'calc(2* var(--side-margin)) var(--side-margin) var(--side-margin)',
      color: textColor || 'var(--c-fg)',
      background: color,
    }}
  >
    {children}
  </div>
);
