import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import styled, { css } from 'styled-components';

const SImg = styled.img`
  max-width: 100%;
  height: auto;
  user-select: none;
  vertical-align: middle;

  ${({ $full }) =>
    $full &&
    css`
      width: 100%;
      max-width: none;
    `}
`;

interface ImageProps {
  alt?: string;
  full?: boolean;
  src?: IGatsbyImageData | string;
  src2x?: string;
}

export default function Image({ alt = '', full, src, src2x, ...props }: ImageProps) {
  const commonProps = {
    alt,
  };

  if (typeof src === 'object') {
    return <GatsbyImage {...commonProps} image={src} {...props} />;
  }

  return (
    <SImg
      {...commonProps}
      src={src}
      srcSet={!!src2x && `${src2x} 2x`}
      $full={full}
      aria-hidden={alt ? undefined : true}
      {...props}
    />
  );
}
