import { useRef } from 'react';

/**
 * Opens the Intercom widget and manages focus for accessibility.
 * - Saves the currently focused element before opening the Intercom widget.
 * - Sets focus to a button inside the Intercom iframe once it has loaded.
 * - Restores focus to the original element when the Intercom widget is closed.
 */
export function useIntercom() {
  const triggerElementRef = useRef<HTMLElement | null>(null);

  const handleIntercomOpen = () => {
    if (document.activeElement instanceof HTMLElement) {
      triggerElementRef.current = document.activeElement;
    }

    if (window.Intercom) {
      window.Intercom('show');
      /** Poll for the Intercom iframe and the target button inside */
      const intervalId = setInterval(() => {
        const iframe = document.querySelector(
          'iframe[name="intercom-messenger-frame"]',
        ) as HTMLIFrameElement;
        if (iframe && (iframe.contentDocument || iframe.contentWindow?.document)) {
          const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
          /** Focus on the first button available */
          const textButton = iframeDoc?.querySelector('[role="button"]');

          if (textButton) {
            (textButton as HTMLElement).focus();
            clearInterval(intervalId);
          }
        }
      }, 100);

      /** Return focus to previously focused element */
      window.Intercom('onHide', () => {
        if (triggerElementRef.current) {
          triggerElementRef.current.focus();
        }
      });
    }
  };

  return handleIntercomOpen;
}
