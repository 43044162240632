import React from 'react';
import styled from 'styled-components';
import { APPLE_URL, GOOGLE_URL, WEB_URL } from 'src/utils';

const SOuter = styled.div`
  text-align: center;
`;

const SDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SDownloadBadge = styled.div`
  height: 48px;
  width: ${(p) => p.width}px;
  background: ${(p) => p.src} center/contain no-repeat;
  margin: 0 8px;
  backdrop-filter: blur(40px);
  filter: drop-shadow(0px 8px 15px rgba(0, 25, 10, 0.08));
`;

const Download = ({ signupUrl = WEB_URL, accentColor, appsOnly }) => {
  const onPress = () => {};

  return (
    <SOuter>
      <SDownload>
        <a onClick={() => onPress('iOS')} href={APPLE_URL} target="_blank" rel="noreferrer">
          <SDownloadBadge width={144} src="var(--badge-appstore)" />
        </a>
        <a onClick={() => onPress('Android')} href={GOOGLE_URL} target="_blank" rel="noreferrer">
          <SDownloadBadge width={162} src="var(--badge-playstore)" />
        </a>
      </SDownload>
      {!appsOnly && (
        <div style={{ marginTop: 12 }}>
          <span className="o75">Download the app or get started</span>
          <span className="link m  " style={{ color: accentColor }}>
            {' '}
            <a href={signupUrl} target="_blank" rel="noreferrer" className="u">
              on web
            </a>
          </span>
        </div>
      )}
    </SOuter>
  );
};

export default Download;
