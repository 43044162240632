import paths from 'src/constants/paths';

const isCatchEnv = (str) => process.env.GATSBY_CATCH_ENV === str;

export const URL = 'https://catch.co';
export const URL_DEV = 'https://catch.co';
export const CATCH_API_URL_DEV = 'https://api.dev.catch.co/public/graphql';
export const CATCH_API_URL_PROD = 'https://api.catch.co/public/graphql';
export const CATCH_APP_URL_DEV = 'https://dev.catch.co';
export const CATCH_APP_URL_PROD = 'https://app.catch.co';
export const CATCH_ADMIN_URL_DEV = 'https://at.dev.catch.co';
export const CATCH_ADMIN_URL_PROD = 'https://at.catch.co';
export const CATCH_PX_URL_DEV = `https://devsite.catch.co${paths.EXPLORER}`;
export const CATCH_PX_URL_PROD = `https://catch.co${paths.EXPLORER}`;
export const SEGMENT_KEY_DEV = '3GvBbJ6Wb2hREOcNMmMmuubdsNdeV4n3';
export const SEGMENT_KEY_PROD = 'N9EvyzM3yGarAZSJgUed2q7aAZhI6Q1a';
export const MARKETPLACE_KEY = 'uplvuvRrQRzAWlleHNXfPcN5qkdnVJBg';
export const MARKETPLACE_KEY_DEV = 'd687412e7b53146b2631dc01974ad0a4';
export const LOGROCKET_DEV = '6an89b/catch-health-dev';
export const LOGROCKET_PROD = '6an89b/catch-production';
export const GCMS = 'https://api-us-west-2.hygraph.com/v2/ckk8cjwpzoa6401z14h5uff5o/master';

const SIGN_IN_PATH = '/auth/sign-in';
const SIGN_UP_PATH = '/auth/sign-up';

export const ENV_PROD = {
  name: process.env.GATSBY_CATCH_ENV,
  isDev: isCatchEnv('dev'),
  url: URL,
  apiUrl: CATCH_API_URL_PROD,
  appUrl: CATCH_APP_URL_PROD,
  appSignInUrl: `${CATCH_APP_URL_PROD}${SIGN_IN_PATH}`,
  appSignUpUrl: `${CATCH_APP_URL_PROD}${SIGN_UP_PATH}`,
  adminUrl: CATCH_ADMIN_URL_PROD,
  segmentKey: SEGMENT_KEY_PROD,
  marketplaceKey: MARKETPLACE_KEY,
  logrocketKey: LOGROCKET_PROD,
  websiteApiUrl: GCMS,
  pxUrl: CATCH_PX_URL_PROD,
  intercomId: 'pniw40fg',
};

export const ENV_DEV = {
  name: process.env.GATSBY_CATCH_ENV,
  isDev: isCatchEnv('dev'),
  url: URL_DEV,
  apiUrl: CATCH_API_URL_DEV,
  appUrl: CATCH_APP_URL_DEV,
  appSignInUrl: `${CATCH_APP_URL_DEV}${SIGN_IN_PATH}`,
  appSignUpUrl: `${CATCH_APP_URL_DEV}${SIGN_UP_PATH}`,
  adminUrl: CATCH_ADMIN_URL_DEV,
  segmentKey: SEGMENT_KEY_DEV,
  marketplaceKey: MARKETPLACE_KEY_DEV,
  logrocketKey: LOGROCKET_DEV,
  websiteApiUrl: GCMS,
  pxUrl: CATCH_PX_URL_DEV,
  intercomId: 'qn5cenup',
};

const ENV = isCatchEnv('dev') ? ENV_DEV : ENV_PROD;

export default ENV;
