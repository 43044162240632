import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { usePartner } from 'src/hooks';
import { SessionContext } from 'src/context/SessionContext';

const SIntroLayout = styled.div`
  box-sizing: border-box;
  position: relative;
  background: ${(p) =>
    p.bg ? p.bg + ' url(/img/noise.png) center/50px repeat' : 'var(--g-red) center/cover'};
  padding: 15vh var(--side-margin) var(--side-margin);
  margin: 0 auto 0;
  border-radius: 32px;
  width: calc(100vw - var(--side-margin) - var(--side-margin));
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: inset 0 -50vh 100vh -25vh #00000033;
  margin-top: var(--s-nav-height) !important;
  ._main,
  .blend {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .max {
    max-width: var(--width-read);
    width: 100%;
    align-self: stretch;
    margin: 0 auto;
  }
  .mob-vert {
    align-self: center;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  canvas {
    border-radius: 32px;
    margin: auto;
    width: calc(100vw - calc(2 * var(--side-margin))) !important;
    height: 100% !important;
  }

  ._main *,
  ._blend * {
    text-align: center;
  }
  .dark * {
    color: var(--c-fg-0);
  }
  ._main h2 {
    margin-bottom: 16px;
    margin-top: 16px;
  }
  ._main b1 {
    font-weight: 400;
    opacity: 0.75;
    margin-bottom: 32px;
  }
  .blend {
    mix-blend-mode: hard-light;
  }
  img {
    margin-bottom: 48px;
    height: 25vh;
    animation-name: 'bouncey';
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-direction: alternate-reverse;
  }
  eb {
    border-top: 1px solid var(--c-fg-0);
    align-self: center;
    display: inline-block;

    padding-top: 8px;
  }
  .intro-disclaimer {
    color: var(--c-fg-3);
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: var(--side-margin);
    text-align: left;
    mask-image: linear-gradient(to bottom, black, transparent);
    max-width: 500px;
    .copy {
      color: var(--c-fg-3);
      font-size: var(--fs-b3);
      font-weight: 400;
      margin-bottom: 4px;
      text-align: left;
    }
  }
  @media (max-width: 768px) {
    padding: 10vh var(--side-margin) var(--side-margin);
    box-sizing: border-box;
    eb,
    .eb {
      display: none;
    }
    h2 {
      font-size: min(var(--fs-h2), 10vw);
      line-height: 1.15;
      margin-bottom: 12px;
      margin-top: 12px;
    }
    b1 {
      font-size: var(--fs-b2);
      line-height: var(--lh-b2);
    }
    img {
      margin-bottom: 0;
      height: 20vh;
    }
    .mob-vert {
      width: 100%;
      flex-direction: column;
      align-items: stretch;
      align-self: stretch;
      margin-bottom: -16px;
      > div:first-child {
        margin: 0 0 6px 0 !important;
      }
    }
    canvas {
      height: 100%;
    }
  }
`;

export const IntroLayout = ({ title, text, eb, children, imageUrl }) => {
  const { partner, setProgress } = useContext(SessionContext);
  const [_, setHasGradient] = useState(false);
  const { color2, color } = usePartner({ slug: partner });

  useEffect(() => {
    if (setProgress) setProgress(0);
    setHasGradient(true);
  }, [setProgress]);

  return (
    <SIntroLayout bg={color2 || color}>
      {/* {!color && !!hasGradient && <Granim className="grad" id="granim" states={grads[vertical]} />} */}
      <div className="blend">
        <img loading="lazy" src={imageUrl} />
      </div>
      <div className="dark _main" style={{ zIndex: 99 }}>
        <eb>{eb}</eb>
        <h1 style={{ margin: '16px 0' }}>{title}</h1>
        <b1>{text}</b1>
      </div>
      <div className=" " style={{ zIndex: 99 }}>
        {children}
      </div>
    </SIntroLayout>
  );
};

export default IntroLayout;
