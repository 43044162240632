export type Copy = string | JSX.Element | (string | JSX.Element)[];

export interface FAQ {
  title: string;
  explanation: string;
}

export enum BenefitsAdvisorContext {
  home_nav = 'home_nav',
  px_nav = 'px_nav',
  partner_nav = 'partner_nav',
  other_nav = 'other_nav',
  home_page = 'home_page',
  partner_section = 'partner_section',
  guide = 'guide',
}

export type AnnotationType = 'info' | 'positive' | 'negative';

export interface Annotation {
  id: string;
  type: AnnotationType;
  text: Copy;
  data?: any;
}

export * from './partners';
