import React from 'react';
import styled from 'styled-components';

const StyledLogoLineup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-right: 24px;
  height: 48px;
`;

const StyledLogo = styled.div`
  background-color: ${(p) => p.theme.cardColor};
  background-image: url(${(p) => p.logo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 16px rgba(31, 37, 51, 0.1);
  margin-right: -9px;
  height: 48px;
  width: 48px;
  border-radius: 999px;
  z-index: 9999999;
`;

const LogoLineup = ({ logos, width, style }) => {
  return (
    <StyledLogoLineup width={width} style={style}>
      {logos
        ?.slice(0, 4)
        ?.map(
          (el) =>
            el !== 'https://s.catch.co/img/carriers/default.png' && (
              <StyledLogo className="icon" logo={el} key={el} />
            ),
        )}
    </StyledLogoLineup>
  );
};

export default LogoLineup;
