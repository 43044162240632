export default {
  UNAVAILABLE_STATES: {
    CA: true,
    AZ: true,
    GA: true,
    SC: true,
    TN: true,
    PA: true,
    MI: true,
    AL: true,
    MA: true,
    MS: true,
    NY: true,
    OK: true,
    WA: true,
    WI: true,
    CO: true,
    LA: true,
    AR: true,
    OR: true,
    MN: true,
    CT: true,
    NE: true,
    IA: true,
    ID: true,
    KY: true,
    MT: true,
    NH: true,
    ME: true,
    NM: true,
    SD: true,
    WV: true,
    WY: true,
    ND: true,
    RI: true,
    VT: true,
    AK: true,
    HI: true,
    DC: true,
  },
};
