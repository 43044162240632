import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const SRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: ${(p) => (p.padded ? 2 : 0)}rem 0;
  padding-right: var(--side-margin);
  .n {
    padding-top: 14px;
  }

  @media (max-width: 800px) {
    .n {
      padding-top: 0px;
    }
  }
`;

const SIcon = styled.div`
  background: url(${(p) => p.icon}) center/
    ${(p) => (p.logo ? 'contain' : p.logoSm ? '80%' : '60%')} no-repeat;
  border: ${(p) => (p.logo ? '' : p.none ? '' : '1.5px solid var(--fga) ')};
  height: var(--icon-height);
  width: var(--icon-width);
  border-radius: ${(p) => (p.logo ? 0 : 999)}px;
  margin-right: 16px;
  flex-shrink: 0;
  filter: var(--svgFilter);
`;

const Row = ({ icon, title, text, link, padded, logo, logoSm, pretitle, none, info }) => {
  return (
    <SRow padded={padded}>
      {!!icon && <SIcon icon={icon} logo={logo} logoSm={logoSm} none={none} />}
      <div>
        {!!pretitle && <eb style={{ marginBottom: 24 }}>{pretitle}</eb>}
        <b1 className="">{title}</b1>
        <b2 className="r" style={{ color: 'var(--c-fg-1)' }}>
          {text}
        </b2>
        <b2 className="n">{info}</b2>
        {!!link && (
          <Link target="_blank" to={link?.url}>
            <div className="link fp m" style={{ marginTop: 6 }}>
              {link?.text} &#8250;
            </div>
          </Link>
        )}
      </div>
    </SRow>
  );
};

export default Row;
