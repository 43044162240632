import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { PxIcon } from './util/PxIcon';
import { $t, metalLabels } from 'src/utils';

const SHud = styled.div`
  padding: calc(1 * var(--side-margin)) var(--side-margin);
  display: flex;
  overflow: scroll;
  margin: 0 calc(-1 * var(--side-margin));
`;

const STid = styled.div`
  font-weight: ${(p) => (p.active ? 500 : 400)};
  border-radius: 12px;
  box-sizing: border-box;
  background: var(--bga);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  opacity: ${(p) => (p.active ? 1 : 0.35)};
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
  line-height: 1;
  padding-right: 32px;
  flex-shrink: 0;
  margin-right: 12px;
  &:hover {
    background: ${(p) => (p.onClick ? 'var(--bgaa)' : 'var(--bga)')};
  }
  @media (max-width: 1450px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0;
    flex-basis: 100px;
  }
  ._text {
    font-size: var(--fs-b2);
    padding: 16px 0;
    @media (max-width: 1450px) {
      padding: 0 12px 12px;
      font-size: var(--fs-b2);
      text-align: center;
    }
    ._sub {
      font-size: 12px;
      font-weight: 400;
      color: var(--c-fg-3);
      margin-top: 4px;
      @media (max-width: 1450px) {
        font-size: 11px;
        font-family: var(--ff-small);
      }
    }
  }
  ._icon {
    height: 100%;
    width: 48px;
    margin-right: -4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 1450px) {
      margin-right: 0;
      padding-top: 4px;
      width: 100%;
      height: 40px;
    }
  }
`;

const Tidbid = ({ icon, text, loading, active, name, color, iconStyle, guideSlug, onInfo }) => {
  const label = metalLabels[text] || text;

  return (
    <STid className="pressable" active={active} onClick={() => onInfo(guideSlug)}>
      <div className="_icon" style={iconStyle}>
        <PxIcon name={icon} color={color} text={text} />
      </div>
      <div className="_text">
        <div className="underline">{loading ? <Skeleton /> : label || <Skeleton />}</div>
        <div className="_sub">{name}</div>
      </div>
    </STid>
  );
};

/**
 *
 * @todo CSR, helptext
 *
 */
export const Hud = ({ metal, network, standard, dental, vision, hsa, loading, onInfo }) => {
  return (
    <SHud>
      <Tidbid
        onInfo={onInfo}
        name={$t({ id: 'health-plan.network', defaultMessage: 'Network' })}
        active={network}
        loading={loading}
        icon={'Network'}
        text={network}
        guideSlug="networks"
      />
      {standard && (
        <Tidbid
          onInfo={onInfo}
          name={$t({ id: 'health-plan.learn-more', defaultMessage: 'Learn more' })}
          active={standard}
          loading={loading}
          icon={'Standard'}
          text={$t({ id: 'health-plan.easy-pricing', defaultMessage: 'Easy Pricing' })}
          guideSlug="standard"
        />
      )}
      <Tidbid
        onInfo={onInfo}
        name={$t({ id: 'health-plan.metal-tier', defaultMessage: 'Metal Tier' })}
        active={metal}
        loading={loading}
        icon={'Metal'}
        text={metal}
        iconStyle={{ mixBlendMode: 'var(--blend)' }}
        guideSlug="metals"
      />
      {vision && (
        <Tidbid
          onInfo={onInfo}
          name={$t({ id: 'health-plan.included', defaultMessage: 'included' })}
          active={vision}
          loading={loading}
          icon={'Vision'}
          text={
            vision
              ? $t({ id: 'health-plan.vision', defaultMessage: 'Vision' })
              : $t({ id: 'health-plan.no-vision', defaultMessage: 'No vision' })
          }
        />
      )}
      {dental && (
        <Tidbid
          onInfo={onInfo}
          name={$t({ id: 'health-plan.included', defaultMessage: 'included' })}
          active={dental}
          loading={loading}
          icon={'Dental'}
          text={
            dental
              ? $t({ id: 'health-plan.dental', defaultMessage: 'Dental' })
              : $t({ id: 'health-plan.no-dental', defaultMessage: 'No dental' })
          }
        />
      )}
      {hsa && (
        <Tidbid
          onInfo={onInfo}
          name={$t({ id: 'health-plan.compatibility', defaultMessage: 'compatibility' })}
          active={hsa}
          loading={loading}
          icon={'HSA'}
          text={
            hsa
              ? $t({ id: 'health-plan.hsa', defaultMessage: 'HSA' })
              : $t({ id: 'health-plan.no-hsa', defaultMessage: 'No HSA' })
          }
          guideSlug="hdhp-hsa"
        />
      )}
    </SHud>
  );
};

export default Hud;
