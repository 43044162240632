import React from 'react';
import { CardOption, Modal } from 'src/components';
import { useExplorer } from 'src/hooks/useExplorer';
import { useFormValue } from 'src/hooks/useFormValue';
import { $t } from 'src/utils';

const UsagePage = ({ onClose, open }) => {
  const [usage, setUsage] = useFormValue();
  const { upsertExplorer } = useExplorer();

  const _setUsage = (plannedUsage) => {
    setUsage(plannedUsage);
    upsertExplorer({ plannedUsage });
    setTimeout(onClose, 500);
  };

  return (
    <Modal open={open} onClose={onClose} title={$t({ id: 'usage.label', defaultMessage: 'Usage' })}>
      <h3>
        {$t({
          id: 'usage.title',
          defaultMessage: 'How often will you need health care next year?',
        })}
      </h3>
      <p>
        {$t({
          id: 'usage.subtitle',
          defaultMessage:
            'This is just for recommendation purposes and won’t limit your actual usage in any way',
        })}
      </p>
      <CardOption
        prominent
        value="REC_LOW"
        title={$t({ id: 'usage.low-title', defaultMessage: 'Rarely' })}
        subtitle={$t({
          id: 'usage.low-label',
          defaultMessage: 'Preventive care and not much else',
        })}
        active={usage === 'REC_LOW'}
        onPress={_setUsage}
      />
      <CardOption
        prominent
        value="REC_MEDIUM"
        title={$t({ id: 'usage.medium-title', defaultMessage: 'Sometimes' })}
        subtitle={$t({
          id: 'usage.medium-label',
          defaultMessage: 'Prescriptions; doctors or specialists visits',
        })}
        active={usage === 'REC_MEDIUM'}
        onPress={_setUsage}
      />
      <CardOption
        prominent
        value="REC_HIGH"
        title={$t({ id: 'usage.high-title', defaultMessage: 'Very Often' })}
        subtitle={$t({
          id: 'usage.high-label',
          defaultMessage: 'Frequent and/or major hospital visits',
        })}
        active={usage === 'REC_HIGH'}
        onPress={_setUsage}
      />
    </Modal>
  );
};

export default UsagePage;
