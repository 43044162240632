import React, { useContext } from 'react';
import { Accordion, Button, Page } from 'src/components';
import { IntroLayout } from '../components';
import { $t } from 'src/utils';
import SessionContext from 'src/context/SessionContext';
import { Segment, SegmentEvent } from 'src/lib';

const COPY = {
  slasher: {
    eb: $t({ id: 'slasher.pretitle', defaultMessage: 'Health Insurance' }),
    title: $t({ id: 'slasher.title', defaultMessage: 'See how much you could save' }),
    text: $t({
      id: 'slasher.subtitle',
      defaultMessage:
        'Find tax credits you may have missed to lower your health insurance premiums.',
    }),
    cta: $t({ id: 'slasher.btn', defaultMessage: 'Find savings' }),
    imageUrl: '/img/moneybags.png',
    faqContext: 'health_slasher',
    og: '/graphics/OG_Premium_Slasher.jpg',
    vertical: 'health',
    steps: [
      { name: 'Link your health plan to Catch' },
      { name: 'Let us check your tax credits' },
      { name: 'Lower your monthly costs today' },
    ],
  },
  explorer: {
    eb: $t({ id: 'explorer.pretitle', defaultMessage: 'Health Insurance' }),
    title: $t({ id: 'explorer.title', defaultMessage: 'Explore Health Plans' }),
    text: $t({
      id: 'explorer.subtitle',
      defaultMessage: 'Find savings and get a custom recommendation',
    }),
    cta: $t({ id: 'explorer.btn', defaultMessage: 'Get started' }),
    imageUrl: '/img/health.png',
    faqContext: 'health_explorer',
    og: '/img/og/explorer.png',
    vertical: 'health',
  },
};

/**
 * Flow Intro Page
 */
const IntroPage = ({ onNext: _next, flow, faqs }) => {
  const copy = COPY[flow?.toLowerCase()];
  const { pxId, adminPxLink } = useContext(SessionContext);

  const onNext = () => {
    Segment.track(SegmentEvent.PX_STARTED, { flow, pxId, adminPxLink });
    if (_next) _next();
  };

  return (
    <Page
      prefooter={<></>}
      showFooter
      context={flow}
      hideButtons
      hideLinks
      navBorder={false}
      progress={0}
      metadata={{
        title: copy.title,
        description: copy.text,
        image: copy.og,
        faqs: faqs,
        steps: {
          name: 'Slash your premiums',
          minutes: 5,
          steps: copy.steps || [],
        },
      }}
    >
      <IntroLayout
        eb={copy.eb}
        title={copy.title}
        text={copy.text}
        imageUrl={copy.imageUrl}
        vertical={copy.vertical}
      >
        <div className="flex-h mob-vert dark">
          <Button bouncy onPress={onNext} style={{ paddingLeft: 32, paddingRight: 32 }}>
            {copy.cta}
          </Button>
        </div>
        <div className="max dark">
          <br />
          <br />
          <br />
          <Accordion
            items={faqs?.map((faq) => ({
              title: faq?.title,
              content: faq?.explanation,
            }))}
          />
        </div>
      </IntroLayout>
    </Page>
  );
};

export default IntroPage;
