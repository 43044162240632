import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const SBox = styled.div`
  height: ${(p) => p.height}px;
  border-radius: var(--br-large);
  width: ${(p) => (p.half ? '50%' : '100%')};
  margin: ${(p) => (p.half ? 'auto' : '')};
  padding: ${(p) => (p.small ? '2rem' : '3rem')};
  background: ${(p) => (p.background ? `url('${p.background}') center/cover no-repeat` : p.color)};
  text-align: ${(p) => (p.center ? 'center' : 'left')};
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  flex-direction: column;
  * {
    color: ${(p) => p.textColor};
    font-family: ${(p) => (p.creatour ? 'var(--ff-creatour)' : '')};
  }
  h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  h3 {
    // font-weight: 500;
  }
  img {
  }
  .img-cont {
    margin-bottom: 1rem;
    flex: 1;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: ${(p) => (p.glyph && !p.center ? 'flex-start' : 'center')};
  }
  transform: ${(p) => (p.offset ? 'translateY(100px)' : 'none')};
  @media (max-width: 758px) {
    transform: none;
    padding: calc(var(--side-margin));
  }
`;

export const BigGridBlock = ({
  title,
  subtitle,
  color = 'var(--g-teal)',
  textColor,
  img,
  style,
  background,
  creatour,
  linkTo,
  offset,
  half,
  glyph,
  center,
  small,
  height,
}) => (
  <Link to={linkTo}>
    <SBox
      height={height}
      color={color}
      style={style}
      textColor={textColor}
      half={half}
      offset={offset}
      center={center}
      creatour={creatour}
      background={background}
      small={small}
      glyph={glyph}
    >
      <div className="img-cont">
        <img
          alt={title}
          loading="lazy"
          src={img}
          height={glyph ? 75 : small ? 125 : 150}
          style={glyph && { filter: 'var(--svgFilter)' }}
        />
      </div>
      {!!small ? (
        <>
          <h3 className="m nomargin">{title}</h3>
          <b2 className="l">{subtitle}</b2>
        </>
      ) : (
        <>
          <h2>{title}</h2>
          <h3 className="l nomargin">{subtitle}</h3>
        </>
      )}
    </SBox>
  </Link>
);
