import React from 'react';
import styled from 'styled-components';
import * as RadixTooltip from '@radix-ui/react-tooltip';

const StyledContent = styled(RadixTooltip.Content)`
  border-radius: 8px;
  padding: 12px;
  font-size: 15px;
  line-height: 1;
  color: var(--c-fg-0);
  background-color: var(--c-bg-c);
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 1000000000000;

  .arrow {
    fill: var(--c-bg-c);
  }
`;

export function Tooltip({ trigger, children }) {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={0}>
        <RadixTooltip.Trigger asChild>{trigger}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <StyledContent>
            {children}
            <RadixTooltip.Arrow className="arrow" />
          </StyledContent>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
