import { create } from 'zustand';
import { inIframe } from './browser';

const COUNTDOWN_LENGTH = 3; // in seconds

export const useRedirect = create<{
  externalUrl: string;
  seconds: number;
  setRedirect: (externalUrl: string) => void;
  cancel: () => void;
}>((set, get) => ({
  externalUrl: '',
  seconds: 0,
  setRedirect: (externalUrl) => {
    set({ externalUrl, seconds: COUNTDOWN_LENGTH });

    setInterval(() => {
      const current = get().seconds;

      if (current > 1) {
        set({ seconds: current - 1 });
      } else {
        const url = get().externalUrl;

        // if we're able to redirect
        if (url && window && window.top) {
          // unsets so that the modal is gone if we navigate back
          set({ externalUrl: '', seconds: COUNTDOWN_LENGTH });
          window.top.location.href = externalUrl;
        }
      }
    }, 1000);
  },
  cancel: () => {
    // resetting the url ensures we won't actually redirect
    set({ externalUrl: '', seconds: 5 });
  },
}));

/**
 * Handles redirecting out to an external URL
 * @param externalUrl
 */
export function goTo(externalUrl: string, options?: { showCountdown?: boolean }) {
  if (window && window.top && inIframe()) {
    if (options?.showCountdown) {
      useRedirect.getState().setRedirect(externalUrl);
    } else {
      window.top.location.href = externalUrl;
    }
  } else {
    window.location.href = externalUrl;
  }
}
