import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import paths from 'src/constants/paths';

const StyledTerms = styled.div<{ $top: number; $bottom: number }>`
  margin-top: ${(p) => p.$top}px;
  margin-bottom: ${(p) => p.$bottom}px;

  font-weight: 350;
  opacity: 0.5;
  font-size: var(--fs-fp);

  a {
    font-weight: 600;
  }
`;

export interface TermsProps {
  marginTop?: number;
  marginBottom?: number;
}

export function Terms({ marginTop = 24, marginBottom = 24 }: TermsProps) {
  const terms = (
    <Link to={paths.TERMS_OF_USE} target="_blank">
      Terms of Use
    </Link>
  );

  const privacy = (
    <Link to={paths.PRIVACY_POLICY} target="_blank">
      Privacy Policy
    </Link>
  );

  return (
    <StyledTerms $top={marginTop} $bottom={marginBottom}>
      By submitting this form, you agree to Catch's {terms} and {privacy}
    </StyledTerms>
  );
}
