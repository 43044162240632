export const META = {};

export type KeywordSet = 'Catch' | 'Health' | 'HealthGeorgia' | 'Georgia' | 'Tax' | 'Retirement';

export const DEFAULT_DESCRIPTION =
  'Save thousands on health insurance. Benefits for freelancers, contractors, and the self-employed.';

/**
 * Central Keyword Defininition
 * (static keywords only)
 *
 * @see /website/src/pages/health/ga.js for example usage
 */
export const KEYWORDS: Record<KeywordSet, Array<string>> = {
  Catch: [
    'catch',
    'benefits',
    'personal payroll',
    'independents',
    'indepedent contractors',
    'freelancers',
    'contactors',
    'tax',
    'health',
    'retirement',
    'time off',
    'savings',
    'insurance',
    'dental',
    'app',
    'safety',
    'portable',
    'personal',
    'gig',
    'self-employed',
    'income',
    'autopilot',
    'enrollment',
    'help',
    'ios',
    'android',
    'web',
  ],
  Tax: [],
  Retirement: [],
  Health: [
    'affordable',
    'health',
    'APTC',
    'CSR',
    'Healthcare.gov',
    'Marketplace',
    'coverage',
    'ACA',
    'obamacare',
    'subsidies',
    'medical',
    'open enrollment',
    '2023',
    '2022',
    'save money on health insurance',
    'advanced premium tax credits',
    'premium slasher',
    'health insurance tax credits',
    'lowest cost health insurance',
    'cheaper health insurance today',
    'lower my health insurance premiums',
    'find tax credits',
    'find health insurance tax credits',
    'cheaper aca insurance',
    'lower health premiums',
    'cheaper health insurance premiums',
    'biden tax credits',
    'biden health tax credits',
    'health insurance marketplace credits',
    'obamacare tax credits',
    'lower obamacare premiums',
    'cut health insurance premiums',
  ],
  Georgia: ['Georgia'],
  HealthGeorgia: [
    `Georgia health insurance`,
    `Georgia health coverage`,
    `Georgia marketplace`,
    'Georgia Access',
    'Georgia health partner',
    'Georgia access model',
  ],
};
