import React from 'react';
import styled from 'styled-components';

import { PxIcon } from './util/PxIcon';

interface FilterTileProps {
  text: string;
  name?: string;
  active?: boolean;
  onPress(text: any): any;
}

export const FilterTile = ({
  onPress,
  text,
  label,
  name,
  active,
  partialActive,
  large,
  icon = 'Standard',
}: FilterTileProps) => {
  return (
    <SPill
      className={active ? 'active pressable' : 'fader pressable'}
      active={active}
      large={large}
      onClick={() => onPress(name || text)}
      partialActive={partialActive}
    >
      {large && <PxIcon name={icon} text={text} style={{ marginBottom: 2, minHeight: 24 }} />}
      <SLabel>{label || text}</SLabel>
    </SPill>
  );
};

const SLabel = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const SPill = styled.div`
  border-radius: 12px;
  padding: 12px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: ${(p) => (p.large ? 'column' : 'row')};
  justify-content: ${(p) => (p.large ? 'flex-start' : 'center')};
  line-height: 1;
  text-align: center;
  color: ${(p) => (p.active || p.partialActive ? 'var(--c-fg-0)' : 'var(--c-fg-1)')};
  background: ${(p) => (p.active ? 'var(--c-bg-c)' : '#7C7C7E11')};
  font-size: ${(p) => (p.large ? 'calc(var(--fs-b3) - 1px)' : 'var(--fs-b3)')};
  flex: ${(p) => (p.large ? 1 : 0)};
  font-weight: 500;
  opacity: ${(p) => (p.disabled ? 0.15 : 1)};
  user-select: none;
  cursor: pointer;
  box-shadow: ${(p) =>
    p.active
      ? 'inset 0 0 0.5px 1px var(--c-fg-0)'
      : p.partialActive
        ? 'inset 0 0 0.5px 0.5px transparent'
        : 'inset 0 0 0px 1px transparent'};
  &:hover {
    box-shadow: ${(p) =>
      p.active ? 'inset 0 0 0.5px 1px var(--c-fg-0)' : 'inset 0 0 0.5px 1px  var(--c-fg-1)'};
  }
`;

export default FilterTile;
