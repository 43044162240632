import FuzzySet from 'fuzzyset';
import { ALL_SOURCES } from '.';

/**
 * @summary takes any code and finds the closest matching income source
 * 
/* fs = FuzzySet([...sources]); 
/* fs.get(code) -> [[m1_score, m1_src], ...]
/*
/* @see https://glench.github.io/fuzzyset.js/
*/

const MATCH_THRESHOLD = 0.75;
const fs = FuzzySet([...ALL_SOURCES]);

const HARDCODE = {
  'square-payroll': 'square',
  'doordash-health': 'doordash',
};

export const closestSource = (source) => {
  if (HARDCODE[source]) return HARDCODE[source];
  const s = fs.get(source)?.[0];

  return s?.[0] > MATCH_THRESHOLD ? s?.[1] : null;
};
