import React from 'react';
import styled from 'styled-components';

const SLineup = styled.div`
  display: ${(p) => (p.tight ? 'flex' : 'grid')};
  grid-template-columns: repeat(${(p) => p.numPerRow}, 1fr);
  justify-content: center;
  height: auto;
  overflow: hidden;
  grid-gap: 12px;
  align-items: center;
  justify-items: center;
  > div,
  > img {
    height: ${(p) => p.height || 36}px;
    margin-right: ${(p) => (p.tight ? -24 : 0)}px;

    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    height: auto;
    > div,
    > img {
      align-items: center;
      margin-right: ${(p) => (p.tight ? -24 : 0)}px;
      justify-self: center;
    }
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Lineup = ({ height, children, style, tight, nowrap, numPerRow = 5 }) => {
  return (
    <SLineup height={height} style={style} tight={tight} nowrap={nowrap} numPerRow={numPerRow}>
      {children}
    </SLineup>
  );
};

export default Lineup;
