import React from 'react';

const NoSavings = () => (
  <p className="faded">
    Your household income may not qualify for marketplace savings, but we’ll still help you find the
    most affordable, effective coverage out there.
  </p>
);

export default NoSavings;
