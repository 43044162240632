import { useEffect, useState } from 'react';

export const useFormValue = (initialValue?: any) => {
  const [value, setValue] = useState(initialValue || '');

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return [value, setValue];
};
