import React, { useMemo } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import {
  getCarrierLogo,
  getCarrierName,
  formatHealthPlanNameWithDetail as formatPlanName,
} from '@catch-co/health-utils';

import { $t, formatCurrency } from 'src/utils';
import { Deductible, MetalTier, NetworkType } from 'src/types/health';
import { useAnnotation } from 'src/hooks';
import { DISPLAY, formatDeductibles, metalAv, metalColors, metalLabels } from 'src/utils/health';
import { PxIcon } from './util/PxIcon';

import { ReactComponent as Check } from '../iconic/check.svg';
import { Annotations } from './Annotation';

interface HealthPolicyBlockProps {
  name: string;
  grossPremium: number;
  netPremium: number;
  issuer: string;
  onPress(): any;
  metal: MetalTier;
  deductible: number;
  type: NetworkType;
  loading: boolean;
}

/**
 * this component deserves better code
 */
const Badge = ({ metal, network, isStandard, hsa, rating, onInfo }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: 'var(--fs-fp)',
        marginBottom: 1,
      }}
    >
      {isStandard && (
        <SBadge
          isStandard={isStandard}
          metal={metal}
          style={{ color: 'black' }}
          onClick={() => onInfo('standard')}
        >
          <PxIcon
            name="Standard"
            text={metal}
            color="black"
            style={{
              transform: 'scale(0.75)',
              marginRight: -2,
            }}
          />
          {$t({ id: 'health-plan.easy-pricing', defaultMessage: 'Easy Pricing' })}
        </SBadge>
      )}
      <SBadge
        metal={metal}
        style={{
          color: 'var(--c-fg-0)',
        }}
      >
        <PxIcon
          name="Metal"
          text={metal}
          color={metalColors[metal?.toLowerCase()]}
          style={{
            transform: `scale(${metalAv[metal?.toLowerCase()]})`,
            marginRight: -2,
          }}
        />
        {metalLabels[metal]}
      </SBadge>

      {rating ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'var(--c-fg-0)',
            border: '1px solid var(--c-border)',
            fontWeight: 500,
            borderRadius: 999,
            padding: ' 0 8px 0',
            margin: '0 4px 0 0 ',
            height: 22,
          }}
        >
          {rating}/5
          <PxIcon
            name="Star"
            text="Star"
            color="gold"
            style={{
              transform: 'scale(0.65)',
              opacity: 0.65,
              marginLeft: -6,
              marginRight: -2,
            }}
          />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: 'var(--c-fg-0)',
          border: '1px solid var(--c-border)',
          fontWeight: 500,
          borderRadius: 999,
          padding: ' 0 8px 0',
          margin: '0 4px 0 0 ',
          height: 22,
        }}
      >
        {network}
      </div>
      {!!hsa && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'var(--c-fg-0)',
            fontWeight: 400,
            height: 22,
            borderRadius: 999,
            padding: ' 0 8px 0',
            margin: '0 4px 0 0 ',
            border: '1px solid var(--c-border)',
          }}
        >
          <PxIcon
            name="HSA"
            text="HSA"
            style={{
              transform: 'scale(0.65)',
              opacity: 0.65,
              marginLeft: -6,
              marginRight: -2,
            }}
          />
          {$t({ id: 'health-plan.hsa-eligible', defaultMessage: 'HSA eligible' })}
        </div>
      )}
    </div>
  );
};

const DeductibleInfo = ({
  deductibles = [],
  isDental = false,
  brochureURL = '',
}: {
  deductibles: Deductible[];
  isDental: boolean;
  brochureURL: string;
}) => {
  /* some dental plans (e.g. Humana) may not have deductible data, in which case we use the brochure URL instead and provide a link to view */
  if (deductibles.length === 0 && isDental) {
    return (
      <BrochureLink
        target="_blank"
        rel="noopener noreferrer"
        href={brochureURL}
        onClick={(e) => e.stopPropagation()} //stop card from being also clicked if we click this link
      >
        {$t({
          id: 'health-plan.no-deductible-see-brochure',
          defaultMessage: 'See Plan Brochure',
        })}
      </BrochureLink>
    );
  }

  //"normal" case where deductible information is available
  const formattedDeductibles = useMemo(() => formatDeductibles(deductibles), [deductibles]);
  const totalDeductible = formattedDeductibles.family
    ? formattedDeductibles.family?.total
    : formattedDeductibles.individual?.total;

  return (
    <>
      {formatCurrency({ value: totalDeductible })}{' '}
      <span style={{ fontWeight: 450, fontSize: `var(--fs-b3)` }}>
        {$t({ id: 'health-plan.total-deductible', defaultMessage: 'total deductible' })}
      </span>
    </>
  );
};

const HealthPolicyBlock = ({
  name,
  grossPremium,
  netPremium,
  issuer,
  onPress,
  metal,
  rawDeductibles,
  type,
  loading,
  plan,
  isDental,
  brochureURL,
  selected,
  standard,
  onCompare,
  inCompare,
  hsa,
  standardNames,
  qualityRating,
  onInfo,
}: HealthPolicyBlockProps) => {
  const { annotations } = useAnnotation(plan);

  const fName = formatPlanName(name);
  const title = fName?.name;
  const subtitle = fName?.detail;

  const NEG = 0;

  const deductibles = useMemo(() => formatDeductibles(rawDeductibles), [rawDeductibles]);
  const totalDeductible = deductibles.family
    ? deductibles.family?.total
    : deductibles.individual?.total;

  return (
    <SBlock className={loading && 'pulse'} onClick={onPress} loading={loading} selected={selected}>
      <div
        className="flex-h"
        style={{
          marginBottom: 12,
          marginLeft: -NEG,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        <div>
          <div
            style={{
              ...styles.logo,
              backgroundImage: `url(https://s.catch.co/img/carriers/${getCarrierLogo(issuer)}.png)`,
            }}
          />
        </div>
        <div style={styles.header}>
          <div style={styles.carrier}>{getCarrierName(issuer) || <Skeleton />}</div>
          <Badge
            metal={metal}
            network={type}
            isStandard={standard}
            hsa={hsa}
            rating={qualityRating?.globalRating}
            onInfo={onInfo}
          />
        </div>

        <div
          className="checkmark"
          onClick={(e) => {
            e.stopPropagation();
            onCompare();
          }}
          style={{
            width: 24,
            height: 24,
            borderRadius: 8,
            right: 0,
            top: 0,
            background: inCompare ? 'var(--c-fg-0)' : 'transparent',
            border: '1px solid var(--c-fg-3)',
          }}
        >
          <Check stroke={inCompare && 'var(--c-bg-0)'} />
        </div>
      </div>
      <div>
        <div className="mobile-reset-pad" style={{ marginBottom: 12, marginLeft: 48 + 12 - NEG }}>
          {standardNames ? (
            <div style={styles.title}>
              {getCarrierName(issuer)}{' '}
              {standard ? $t({ id: 'health-plan.standard', defaultMessage: 'Standard' }) : ''}{' '}
              {metalLabels[metal]} {type} {hsa ? 'HSA ' : ' '}{' '}
              {formatCurrency({ value: totalDeductible })}
            </div>
          ) : (
            <div>
              <div style={styles.title}>{title || <Skeleton />}</div>
              {!!subtitle && (
                <div className="oneline" style={styles.subtitle}>
                  {subtitle || <Skeleton />}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className="mobile-reset-pad"
        style={{
          padding: '16px 24px 0px',
          margin: '16px -24px -4px',
          borderTop: '1px solid var(--c-border-light)',
          paddingLeft: 0,
          marginLeft: 48 + 12 - NEG,
        }}
      >
        <div className="flex-h">
          <div style={{ fontSize: `var(--fs-b2)`, fontWeight: 550 }}>
            {loading ? (
              <Skeleton style={{ width: 64 }} />
            ) : (
              <DeductibleInfo
                deductibles={rawDeductibles}
                isDental={isDental}
                brochureURL={brochureURL}
              />
            )}
          </div>
          {loading ? (
            <Skeleton style={{ width: 120 }} />
          ) : (
            <div>
              {grossPremium != netPremium && (
                <span style={styles.oldPrice}>{formatCurrency({ value: grossPremium })}</span>
              )}
              <span style={styles.price}>
                <span style={{ fontWeight: 600, fontSize: 'var(--fs-b2)' }}>
                  {formatCurrency({
                    value:
                      grossPremium === netPremium ? netPremium : Math.max(0, netPremium - DISPLAY),
                  })}
                </span>
                /{$t({ id: 'health-plan.month-abbr', defaultMessage: 'mo' })}
              </span>
            </div>
          )}
        </div>
        {annotations && annotations.length > 0 && (
          <div style={{ paddingTop: 12 }}>
            <Annotations annotations={annotations} />
          </div>
        )}
      </div>
    </SBlock>
  );
};

const styles = {
  logo: {
    flexShrink: 0,
    marginRight: 12,
    height: 48,
    width: 48,
    borderRadius: 99,
    backgroundSize: 'cover',
    backgroundColor: 'var(--c-border-light)',
  },
  annots: {
    margin: '16px -12px -12px -4px',
    borderRadius: 12,
    overflow: 'hidden',
  },
  oldPrice: {
    color: `var(--c-fg-3)`,
    fontSize: 'var(--fs-b3)',
    textDecorationLine: 'line-through',
    textDecorationColor: `var(--c-fg-1)`,
    textDecorationThickness: 1,
    marginRight: 8,
  },
  subtitle: {
    fontSize: 'var(--fs-b3)',
    color: 'var(--c-fg-1)',
    marginTop: 4,
    fontVariationSettings: '"opsz" 20',
  },
  title: { fontWeight: 450, fontSize: 'var(--fs-b1)', marginTop: 0 },
  price: {
    color: `var(--c-fg-0)`,
    fontSize: 'var(--fs-b2)',
    textDecoration: 'none',
    fontWeight: 500,
  },
  header: {
    flexGrow: 1,
    height: 48,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  carrier: {
    fontSize: 'var(--fs-b3)',
    fontWeight: 500,
    height: 24,
    display: 'flex',
    alignItems: 'center',
  },
};

const BrochureLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

const SBadge = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  border-radius: 999px;
  padding: 0 8px 0 0;
  margin: 0 4px 0 0;
  height: 22px;
  background: linear-gradient(
    ${(p) => metalColors[p.metal?.toLowerCase()]}${(p) => (p.isStandard ? 'AA' : '33')},
    ${(p) => metalColors[p.metal?.toLowerCase()]}${(p) => (p.isStandard ? 'DD' : '55')}
  );
  @media (prefers-color-scheme: dark) {
    background: linear-gradient(
      to top,
      ${(p) => metalColors[p.metal?.toLowerCase()]}${(p) => (p.isStandard ? 'AA' : '33')},
      ${(p) => metalColors[p.metal?.toLowerCase()]}${(p) => (p.isStandard ? 'DD' : '55')}
    );
  }
`;

const SBlock = styled.div`
  padding: 24px;
  padding-left: 16px;
  margin: 0 0 24px;
  background-color: ${(p) => (p.loading ? 'var(--c-bg-gray)' : 'var(--bgca)')};
  transition: background 0.15s cubic-bezier(0.21, 0.49, 0.14, 1);
  border-radius: 24px;
  cursor: pointer;
  opacity: ${(p) => (p.loading ? 0.75 : 1)};
  box-shadow: ${(p) =>
    p.selected
      ? ' inset 0 0 0 1.5px var(--c-fg-1)'
      : p.loading
        ? 'inset 0 0 0px 1px transparent'
        : 'inset 0 0 0px 1.5px var(--c-border), var(--shadow-input)'};
  &:hover {
    box-shadow: ${(p) =>
      p.selected
        ? ' inset 0 0 0 1.5px var(--c-fg-1)'
        : p.loading
          ? 'inset 0 0 0px 1px transparent'
          : 'inset 0 0 0px 1.5px var(--c-fg-3)'};
  }
  &:active {
    box-shadow: inset 0 0 0 2px var(--c-border);
  }
  .checkmark:hover {
    border-color: var(--c-fg-0) !important;
  }
  @media (max-width: 1080px) {
    padding: 28px 24px 24px;
    margin: 0 -24px 0px;
    border-radius: 0;
    border: none;
    box-shadow: inset 0 4px 0 0 var(--c-border-light);
    .mobile-reset-pad {
      margin-left: 0px !important;
      margin-right: -24px !important;
    }
    @media (prefers-color-scheme: dark) {
      box-shadow: inset 0 4px 0 0 black;
    }
    &:hover {
      background: var(--c-bg-gray);
      box-shadow: inset 0 4px 0 0 var(--c-border-light);
      @media (prefers-color-scheme: dark) {
        box-shadow: inset 0 4px 0 0 black;
      }
    }
  }
`;

export default HealthPolicyBlock;
