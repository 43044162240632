import React from 'react';
import styled from 'styled-components';

interface CardOptionProps {
  title: string;
  subtitle?: string;
  value: string;
  active?: boolean;
  onPress(value: string): any;
}

const CardOption = ({
  title,
  subtitle,
  onPress,
  active,
  value,
  prominent,
  iconPath,
  large,
  caret,
}: CardOptionProps) => {
  return (
    <SOption large={large} prominent={prominent} onClick={() => onPress(value)} active={active}>
      {!!iconPath && (
        <img src={iconPath} style={{ filter: 'var(--filter)', marginRight: 12, width: 32 }} />
      )}

      <div>
        <b2 className="title">{title}</b2>
        <b3 className="subtitle">{subtitle}</b3>
      </div>

      {!!caret && (
        <img src="/iconic/arrow-right.svg" className="arrow" style={{ filter: 'var(--filter)' }} />
      )}
    </SOption>
  );
};

const SOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${(p) => (p.large ? 24 : 16)}px;
  border-radius: 16px;
  cursor: pointer;
  background: ${(p) => (p.prominent ? '#FFFFFFAA' : 'var(--c-border-light)')};
  transform-origin: 48px;
  margin-bottom: 8px;
  border: 1px solid ${(p) => (p.active ? 'var(--c-fg-0)' : 'var(--c-border)')};
  box-shadow: ${(p) => (p.active ? 'inset 0 0 0px 0.5px var(--c-fg-0)' : 'var(--shadow-input)')};
  > div {
    flex-grow: 1;
  }
  .arrow {
    opacity: 0.5;
    transition: 0.2s;
  }
  .title {
    color: ${(p) => (p.prominent ? 'var(--c-fg-0)' : 'var(--c-fg-0)')} !important;
    margin-bottom: 6px;
    font-weight: 500;
    line-height: 1;
    font-size: ${(p) => (p.large ? 'var(--fs-b1)' : 'var(--fs-b2)')};
  }
  .subtitle {
    color: ${(p) => (p.prominent ? 'var(--c-fg-1)' : 'var(--c-fg-1)')} !important;
    line-height: 1;
    font-size: ${(p) => (p.large ? 'var(--fs-b2)' : 'var(--fs-b3)')};
  }

  a {
    color: var(--c-cerise-0);
  }

  @media (prefers-color-scheme: dark) {
    background: ${(p) => (p.prominent ? 'var(--c-border)' : 'var(--c-border-light)')};
  }
  @media (max-width: 1080px) {
    padding: 16px;
    border-radius: 16px;
    .title {
      font-size: var(--fs-b2);
    }
    .subtitle {
      font-size: var(--fs-b3);
    }
  }
  &:hover {
    .arrow {
      opacity: 1;
    }
    border-color: var(--c-fg-1);
    background: ${(p) => (p.prominent ? '#FFFFFF' : 'var(--c-border)')};
    @media (prefers-color-scheme: dark) {
      background: ${(p) => (p.prominent ? 'var(--c-border-light)' : 'var(--c-border)')};
    }
  }
`;

export default CardOption;
