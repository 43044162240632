import React, { useContext } from 'react';
import { usePartner } from 'src/hooks';
import { SessionContext } from 'src/context/SessionContext';
import { PXLayout, Toolbar } from '../components';
import { STATES } from 'src/utils/states';
import { stateExchanges } from 'src/utils/healthExchanges';

import { Button } from 'src/components';
import { useExplorer } from 'src/hooks/useExplorer';

const HandoffPage = ({}) => {
  const { signupUrl, partner } = useContext(SessionContext);
  const { data } = useExplorer();
  const { color } = usePartner({ slug: partner });

  const exchange = stateExchanges[data?.state];

  return (
    <>
      <PXLayout
        long
        hex={color}
        title="Get in touch with a Catch benefits advisor"
        subtitle={`Your state operates its own exchange, so we’ll help you get enrolled through ${exchange?.name} to get coverage.`}
        a
        precomponent={
          <img
            src={`/img/states/${STATES[data?.state]?.replace(' ', '_')?.toUpperCase()}.svg`}
            alt={data?.state}
            height={100}
            style={{ filter: 'var(--filter)' }}
          />
        }
        toolbar={
          <Toolbar half>
            <div></div>
            <Button
              primary
              onPress={() => {
                window.location = signupUrl;
              }}
            >
              Get in touch
            </Button>
          </Toolbar>
        }
      />
    </>
  );
};

export default HandoffPage;
