import React, { ReactNode } from 'react';
import styled from 'styled-components';

const SDiv = styled.div`
  width: 100%;

  > div {
    width: 100%;
    max-width: ${({ full, narrow, ultranarrow }) =>
      full
        ? '100%'
        : ultranarrow
          ? 'var(--width-block)'
          : narrow
            ? 'var(--width-read)'
            : 'var(--width-nav)'};
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 800px) {
    > div {
      max-width: 100% !important;
    }
  }
`;

interface ContainerProps {
  children: ReactNode;
  full?: boolean;
  narrow?: boolean;
  ultranarrow?: boolean;
}

export default function Container({ children, full, narrow, ultranarrow }: ContainerProps) {
  return (
    <SDiv full={full} narrow={narrow} ultranarrow={ultranarrow}>
      <div className="container">{children}</div>
    </SDiv>
  );
}
