import React from 'react';
import styled from 'styled-components';

const SProgress = styled.div`
  height: 1.5px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999999999999999999999999999999999999999999999;
  display: none;
  opacity: ${(p) => (p.progress === 0 ? 0 : 1)};
  > div {
    background: ${(p) => p.color || 'var(--c-red-0)'};
    width: ${(p) => p.progress * 100}%;
    height: 100%;
    transition: 0.5s cubic-bezier(0.42, 0, 0.02, 1);
  }
  @media (max-width: 768px) {
    bottom: 0;
    top: unset;
  }
`;

export const Progress = ({ progress, color }) => {
  return (
    <SProgress progress={progress} color={color}>
      <div></div>
    </SProgress>
  );
};

export default Progress;
