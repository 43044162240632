/**
 * This is a set of partner slugs that are predefined
 * We have special handling for these ones specifically,
 * so we want to be able to use this enum to match against
 *
 * Note: this is NOT an exhaustive list of partners. That can be
 * found by looking at the response from the public hygraph query
 */
export enum Partner {
  Decisely = 'decisely',
  FreelancersUnion = 'freelancersunion',
}
