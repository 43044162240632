import { useState, useEffect, useMemo } from 'react';
import useFetch from 'use-http';
import { apiKey } from 'src/utils';
const BASE_URL = 'https://marketplace.api.healthcare.gov/api/v1/';

const bases = {
  drugs: 'drugs/autocomplete',
  providers: 'providers/search',
};

const MIN_PROVIDER_SEARCH_LEN = 2;
const MIN_PRESCRIPTION_SEARCH_LEN = 3; //marketplace query requires min char length of 3

const format = {
  /**
   * Returns drugs grouped by name/route
   * for example ADDERALL Oral Pill is indexed as ADDERALL-Oral Pill
   * returns : {
   *   groupID: "ADDERAL-Oral Pill"
   *   name:
   * }
   *
   */
  drugs: (data) => {
    if (!data) return [];

    const groupedDrugs = data.reduce((acc, drug) => {
      const groupID = `${drug?.name}-${drug?.route}`;

      if (groupID in acc) {
        return {
          ...acc,
          [groupID]: {
            ...acc?.[groupID],
            strengths: [...acc?.[groupID]?.strengths, drug],
          },
        };
      } else {
        return {
          ...acc,
          [groupID]: {
            groupID,
            name: drug?.name,
            route: drug?.route,
            strengths: [drug],
          },
        };
      }
    }, {});

    return Object.values(groupedDrugs);
  },
  providers: (data) => {
    if (!data) return [];

    return (data?.providers || []).map(({ provider, address }) => ({
      ...provider,
      address,
    }));
  },
};

// type is either "drugs" or "providers"
export const useMarketplace = ({ type = 'drugs', zip = '02339', year = '2022' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const minSearchLen = type === 'drugs' ? MIN_PRESCRIPTION_SEARCH_LEN : MIN_PROVIDER_SEARCH_LEN;

  const { get, data, loading } = useFetch(
    `${BASE_URL}/${bases[type]}?apikey=${apiKey}&year=${year}&q=${searchTerm}&zipcode=${zip}`,
  );

  useEffect(() => {
    if (searchTerm?.length >= minSearchLen) get();
  }, [get, searchTerm]);

  const results = useMemo(() => {
    return format[type](data);
  }, [data, type]);

  const rs = format[type](data);

  return {
    searchTerm,
    setSearchTerm,
    data: searchTerm?.length >= minSearchLen ? data : null,
    results: searchTerm?.length >= minSearchLen ? results : [],
    rs: searchTerm?.length >= minSearchLen ? rs : null,
    loading,
  };
};
