import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Close } from '../iconic/minus.svg';

const StyledSelectedDrug = styled.div`
  font-weight: 500;
  display: flex;
  font-size: var(--fs-b3);
  align-items: center;
  flex-shrink: 0;
  margin-right: 24px;
  .close-icon {
    margin-right: 8px;
    height: 24px;
    width: 24px;
    opacity: 0.75;
    color: ${(p) => p.color};
    background: var(--c-red-1);
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
`;

const SelectedDrug = ({ name, onRemove }) => {
  return (
    <StyledSelectedDrug>
      <div className="close-icon" onClick={onRemove}>
        <Close color="var(--c-red-0)" strokeWidth="2" />
      </div>
      <div>{name}</div>
    </StyledSelectedDrug>
  );
};

export default SelectedDrug;
