import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { $t, formatMedicalName, isActive } from 'src/utils';
import { useMarketplace } from 'src/hooks';
import {
  FilterInput,
  SearchResultCard,
  SelectedDrug,
  Modal,
  FilterPill,
  TextButton,
} from 'src/components';
import { useExplorer } from 'src/hooks/useExplorer';

const HorizontalScroll = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
`;

const DrugsPage = ({ open, onClose }) => {
  const { data, upsertExplorer } = useExplorer();
  const [drugs, setDrugs] = useState([]);

  useEffect(() => {
    if (data?.drugsWanted?.length > 0) {
      const existingDrugs = (data?.drugsWanted || [])?.map(({ __typename, ...d }) => d);
      setDrugs([...existingDrugs]);
    }
  }, [data]);

  const toggleDrug = (d) => {
    const exists = drugs?.some((drug) => drug.rxcui === d.rxcui);

    if (exists) {
      const filtered = drugs.filter((drug) => drug?.rxcui !== d.rxcui);
      setDrugs([...filtered]);
    } else {
      setDrugs([...drugs, d]);
    }
  };

  const { searchTerm, setSearchTerm, results } = useMarketplace({
    type: 'drugs',
    year: data.coverageYear,
  });

  const handleSave = () => {
    upsertExplorer({ drugsWanted: drugs }, true);
    onClose();
  };

  return (
    <Modal
      title={$t({ id: 'drugs.label', defaultMessage: 'Prescriptions' })}
      open={open}
      onClose={handleSave}
      topItems={
        <TextButton onPress={handleSave} text={$t({ id: 'drugs.btn', defaultMessage: 'Save' })} />
      }
      bottomItems={
        drugs?.length > 0 && (
          <HorizontalScroll>
            {drugs?.map((item, idx) => (
              <SelectedDrug
                key={item?.rxcui}
                name={formatMedicalName(item?.name)}
                onRemove={() => {
                  var arr = drugs.filter((it, i) => i !== idx);
                  setDrugs(arr);
                }}
              />
            ))}
          </HorizontalScroll>
        )
      }
    >
      <h3>
        {$t({ id: 'drugs.title', defaultMessage: 'What prescriptions do you need covered?' })}
      </h3>

      <div className="sticky-search">
        <FilterInput
          wide
          type="text"
          value={searchTerm}
          placeholder={$t({ id: 'drugs.search', defaultMessage: 'Search for a medication' })}
          onChange={(val) => {
            setSearchTerm(val);
          }}
        />
      </div>
      {results?.length > 0 &&
        results?.map((item) => (
          <SearchResultCard
            key={item?.groupID}
            title={formatMedicalName(item?.name)}
            subtitle={item?.route}
            detail={item?.strengths?.length + ' strengths'}
          >
            {!!item?.strengths && (
              <div
                className="flex"
                style={{ flexWrap: 'wrap', justifyContent: 'flex-start', marginTop: 12 }}
              >
                {item?.strengths?.map((option) => (
                  <FilterPill
                    text={option.strength}
                    key={option?.rxcui}
                    active={isActive({
                      array: drugs,
                      item: option,
                      key: 'rxcui',
                    })}
                    onPress={() => {
                      toggleDrug({
                        rxcui: option.rxcui,
                        name: formatMedicalName(`${option?.name} ${option?.strength}`),
                      });
                    }}
                  ></FilterPill>
                ))}
              </div>
            )}
          </SearchResultCard>
        ))}
    </Modal>
  );
};

export default DrugsPage;
