import React from 'react';

interface EventTrackingPixelProps {
  cat: string;
  type: string;
}

export default function EventTrackingPixel({ cat, type }: EventTrackingPixelProps) {
  if (!cat || !type) return null;

  return (
    <noscript>
      <img
        src={`https://ad.doubleclick.net/ddm/activity/src=9762283;type=${type};cat=${cat};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1?`}
        width="1"
        height="1"
        alt=""
      />
    </noscript>
  );
}
