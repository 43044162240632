import { StringParam, NumberParam, useQueryParams } from 'use-query-params';
import { decodeDelimitedArray } from 'serialize-query-params';

type Relation = 'SELF' | 'SPOUSE' | 'CHILD';
type Sex = 'MALE' | 'FEMALE';

interface Applicant {
  relation: Relation;
  age: number;
  sex: Sex;
}

const normalizeRelation = (val: string): Relation => {
  if (val === 'spouse') return 'SPOUSE';
  if (val === 'child') return 'CHILD';
  return 'SELF';
};

const normalizeSex = (val: string): Sex => {
  return val === 'm' ? 'MALE' : 'FEMALE';
};

const getApplicantString = (applicant: Applicant): string => {
  const sex = applicant.sex === 'MALE' ? 'm' : 'f';
  const relation =
    applicant.relation === 'SPOUSE'
      ? 'spouse'
      : applicant.relation === 'CHILD'
        ? 'child'
        : 'primary';
  return `${relation}-${applicant.age}-${sex}`;
};

const ApplicantsParam = {
  encode: (array: Array<Applicant>): string => {
    return array.reduce((acc, applicant) => {
      const str = getApplicantString(applicant);
      return acc ? `${acc},${str}` : str;
    }, '');
  },

  decode: (value?: string | (string | null)[] | null | undefined): Array<Applicant> => {
    const applicants = decodeDelimitedArray(value, ',') || [];

    return applicants.map((applicant) => {
      const [relation, age, sex] = applicant ? applicant.split('-') : [];

      return {
        relation: normalizeRelation(relation),
        age: parseInt(age),
        sex: normalizeSex(sex),
      };
    });
  },
};

export const useParams = () => {
  const [query] = useQueryParams({
    zip: StringParam,
    income: NumberParam,
    applicants: ApplicantsParam,
    year: NumberParam,
  });

  return query;
};
