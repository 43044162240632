import { datadogRum } from '@datadog/browser-rum';
import { RumFetchResourceEventDomainContext } from '@datadog/browser-rum';
import { ExperimentalFeature } from '@datadog/browser-core/cjs/tools/experimentalFeatures';
import { env } from 'src/env';

export const Datadog = {
  init: () => {
    datadogRum.init({
      enableExperimentalFeatures: [ExperimentalFeature.WRITABLE_RESOURCE_GRAPHQL],
      env: env.GATSBY_DATADOG_RUM_ENVIRONMENT,
      applicationId: env.GATSBY_DATADOG_RUM_APPLICATION_ID,
      clientToken: env.GATSBY_DATADOG_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'app',
      //: env.VERSION_NUMBER,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackSessionAcrossSubdomains: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      useSecureSessionCookie: true,
      allowedTracingUrls: [
        { match: /https:\/\/api.*\.catch\.co/, propagatorTypes: ['tracecontext'] },
      ],
      beforeSend: (event, context) => {
        //ignore certain types of requests - don't send to DD
        if (['xhr'].includes(event.type) === true) {
          //console.log(`skipping event ${event.type}`);
          return false;
        }

        // enahnce the data collected
        if (event.type === 'resource' && event.resource.type === 'fetch' && context !== null) {
          // is there a better way to force the type hinting? We know what this is
          // when we're here
          const ctx: RumFetchResourceEventDomainContext = context;
          // these are sparse
          if (ctx.response!.headers) {
            event!.context!.responseHeaders = Object.fromEntries(ctx.response!.headers);
          }
          // these are full
          if (ctx!.requestInit!.headers) {
            event!.context!.requestHeaders = ctx!.requestInit!.headers;
          }
        }
        return true;
      },
    });
  },
};
