import React from 'react';
import styled from 'styled-components';
import { Container } from '..';

const SHeader = styled.header`
  background: ${(p) => p.bg};
  padding-top: calc(var(--s-nav-height) + var(--padding-section-sm));
  padding-bottom: calc(var(--padding-section-sm));
  padding-left: var(--side-margin);
  padding-right: var(--side-margin);
  color: var(--c-fg-0);
  h3 {
    color: var(--c-fg-1);
    max-width: var(--width-read);
  }
`;

const StyledHeading = styled.h1`
/** Force h2 font size and render semantic h1 element */
  font-size: var(--fs-h2);
`

export const Header = ({ eb, title, text, narrow, dark, bg = 'var(--c-bg-gray)', children }) => {
  return (
    <div className={dark && 'dark'}>
      <SHeader bg={bg} hasSubtitle={!!text}>
        <Container narrow={narrow}>
          {!!eb && <eb>{eb}</eb>}
          <StyledHeading className="r">{title}</StyledHeading>
          {!!text && <h3 className="l">{text}</h3>}
          {children}
        </Container>
      </SHeader>
    </div>
  );
};

export default Header;
