import React from 'react';
import styled from 'styled-components';

const SSplit = styled.div`
  padding: calc(${(p) => (p.small ? '0' : 'var(--padding-section)')}) 0;
  display: flex;
  align-items: flex-start;
  flex-direction: ${(p) => (p.reverse ? 'row-reverse' : 'row')};
  margin-bottom: 2px;
  position: relative;
  z-index: 1;
  > .split {
    margin-right: 2px;
    width: 50%;
    box-sizing: border-box;
    z-index: 1;
  }
  @media (max-width: 800px) {
    display: block;
    > .split {
      width: 100%;
      margin: auto;
    }
  }
`;

const Splitter = styled.div`
  width: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  @media (max-width: 800px) {
    display: none;
  }
`;

const Split = ({ left, right, children, reverse, top, small, sticky }) => {
  return (
    <>
      <SSplit reverse={reverse} top={top} small={small}>
        <div className="split" style={{ position: sticky ? 'sticky' : '', top: 100 }}>
          {left || children?.[0]}
        </div>
        {!top && <Splitter />}
        <div className="split">{right || children?.[1]}</div>
      </SSplit>
    </>
  );
};

export default Split;
