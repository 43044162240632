import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { DOMAIN } from 'src/constants/emails';

const EMAIL_PREFIX = 'mailto:';
const PHONE_NUMBER_PREFIX = 'tel:';

const COLORS = {
  purple: 'var(--c-purple)',
  white: 'var(--c-white)',
};

const styles = css`
  color: ${({ $color }) => COLORS[$color]};
  text-decoration: ${({ $decoration }) => $decoration};
`;

const SA = styled.a`
  ${styles}
`;

const SGatsbyLink = styled(GatsbyLink)`
  ${styles}
`;

export interface LinkProps extends GatsbyLinkProps<any> {
  children?: ReactNode;
  color?: 'purple' | 'white' | 'inherit';
  decoration?: 'underline';
  target?: '_blank' | '_self';
  to: string;
}

export default function Link({ children, color, decoration, to: propsTo, ...props }: LinkProps) {
  const commonProps = {
    $color: color,
    $decoration: decoration,
    ...props,
  };
  const isEmail = propsTo.split('?')[0].includes(DOMAIN);
  const isPhoneNumber = propsTo.match(/^\d/);
  let body = children;
  let to = propsTo;

  if (isEmail) {
    body = children || propsTo.replace(EMAIL_PREFIX, '');
    to = `${EMAIL_PREFIX}${propsTo}`;
  }

  if (isPhoneNumber) {
    body = children || propsTo.replace(PHONE_NUMBER_PREFIX, '');
    to = `${PHONE_NUMBER_PREFIX}${propsTo}`;
  }

  if (isEmail || isPhoneNumber || propsTo.includes('http')) {
    return (
      <SA {...commonProps} href={to}>
        {body}
      </SA>
    );
  }

  return (
    <SGatsbyLink {...commonProps} to={to}>
      {body}
    </SGatsbyLink>
  );
}
