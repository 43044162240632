import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'src/utils/format';

const RowOuter = styled.div`
  background: ${(p) => p.background};
  flex: 0;
  overflow: hidden;
  color: var(--fg);
  font-size: ${(p) => (p.large ? 16 : 14)}px;
  margin: 0 ${(p) => (p.large ? 0 : 0)}px;
  padding: ${(p) => (p.large ? 16 : 12)}px 0;
  border-bottom: 1px solid var(--c-border);
  &:last-of-type {
    border: none;
  }
  .clr-debit {
    color: var(--fg);
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0;
  color: var(--fg);
  font-weight: ${(p) => (p.large ? '550' : '400')};
  font-size: ${(p) => (p.large ? 'var(--fs-b2)' : 'var(--fs-b2)')};
  * {
    font-size: ${(p) => (p.large ? 'var(--fs-b2)' : 'var(--fs-b3)')} !important;
  }
  &:last-child {
    border: none;
  }
  .clr-debit {
    color: var(--fg);
  }
`;

const RowLabel = styled.div`
  font-size: ${(p) => (p.large ? 16 : 14)}px;
  line-height: 1;
  color: var(--fg);
`;
const RowValue = styled.div`
  text-align: right;
  white-space: nowrap;
  max-width: 75%;
`;

const CostRow = ({
  label,
  unit,
  value,
  prevValue,
  background,
  large,
  primary,
  rawValue,
  subtitle,
  onInfo,
  accessory,
}) => {
  const sticker = !!prevValue && value !== prevValue;
  return (
    <RowOuter background={background} large={large} primary={primary}>
      <Row background={background} large={large} primary={primary}>
        <RowLabel
          className={!!onInfo && 'underline'}
          large={large}
          onClick={!!onInfo ? onInfo : () => {}}
        >
          {label}
        </RowLabel>

        {(!!value || value === 0 || !!rawValue) && (
          <RowValue>
            <span style={{ textDecoration: 'line-through', opacity: 0.75 }}>
              {!!sticker && formatCurrency({ value: prevValue })}
            </span>
            <span
              className="clr-debit"
              style={{
                fontWeight: large ? 600 : 500,
                fontSize: primary ? (large ? 20 : 20) : large ? 16 : 14,
                marginLeft: 16,
              }}
            >
              {rawValue ||
                formatCurrency({
                  value: Math.max(0, value),
                  roundDirection: 'DOWN',
                })}
            </span>
            {!!unit && <span>/{unit}</span>}
          </RowValue>
        )}
        {accessory}
      </Row>
      {!!subtitle && (
        <div
          style={{
            fontSize: 'var(--fs-b3)',
            fontWeight: 400,
            textAlign: 'right',
            color: 'var(--c-fg-1)',
            marginLeft: 100,
          }}
        >
          {subtitle}
        </div>
      )}
    </RowOuter>
  );
};

export default CostRow;
