import React from 'react';
import styled from 'styled-components';
import { Icon, IconType, Tooltip } from 'src/kit';
import { Annotation, AnnotationType, Copy } from 'src/types';

const StyledAnnotation = styled.div`
  background: ${(p) => p.background};
  color: ${(p) => p.color};

  mix-blend-mode: var(--blend);
  margin: 1px 0 1px;
  padding: 10px 12px;
  border-radius: 2px;
  font-weight: 450;
  font-size: var(--fs-fp);
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  a {
    color: ${(p) => p.color};
    text-decoration: underline;
    text-decoration-style: dotted;
  }
`;

const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: var(--fs-fp);

  &:not(:last-child) {
    padding-bottom: 8px;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 4px;
`;

const backgrounds: Record<AnnotationType, string> = {
  positive: 'var(--c-success-lighter)',
  negative: '#F9151511',
  info: 'var(--c-success-lighter)',
};

const colors: Record<AnnotationType, string> = {
  positive: 'var(--c-success)',
  negative: 'var(--c-red-0)',
  info: 'var(--c-success)',
};

const icons: Record<AnnotationType, IconType> = {
  positive: 'Checkmark',
  negative: 'Close',
  info: 'Help',
};

export function AnnotationRow({ annotation }: { annotation: Annotation }) {
  return (
    <StyledAnnotation
      color={colors[annotation.type]}
      background={backgrounds[annotation.type]}
      isPositive={annotation.type !== 'negative'}
    >
      {annotation.text}
    </StyledAnnotation>
  );
}

export function Annotations({ annotations }: { annotations: Array<Annotation> }) {
  if (!annotations || annotations.length === 0) {
    return null;
  }

  return (
    <div>
      {annotations.map((annotation) => (
        <AnnotationRow key={annotation.id} annotation={annotation} />
      ))}
    </div>
  );
}

export function AnnotationTooltip({
  type,
  link,
  items,
}: {
  title: Copy;
  type: AnnotationType;
  link: Copy;
  items: Array<string>;
}) {
  return (
    <Tooltip trigger={<a>{link}</a>}>
      <div>
        {items.map((item) => (
          <TooltipItem key={item}>
            <StyledIcon type={icons[type]} size={20} color={colors[type]} />
            {item}
          </TooltipItem>
        ))}
      </div>
    </Tooltip>
  );
}
