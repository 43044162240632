const TOLL_FREE = '8333090500';
const TOLL_FREE_FORMATTED = '833-309-0500 (TTY 711)';

export default {
  AETNA: '8333137170',
  AETNA_FORMATTED: '833-313-7170 (TTY 711)',
  DEFAULT: TOLL_FREE,
  DEFAULT_FORMATTED: TOLL_FREE_FORMATTED,
  TOLL_FREE,
  TOLL_FREE_FORMATTED,
};
