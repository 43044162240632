import React, { forwardRef, useId, useState, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ReactComponent as Caret } from '../iconic/chevron-right.svg';
import { Markdown } from 'src/kit';

interface StyledAccordionItemProps {
  isCollapsibleList?: boolean;
}

interface StyledAccordionTriggerProps {
  isCollapsibleList?: boolean;
}

interface StyledAccordionContentWrapperProps {
  isCollapsibleList?: boolean;
}

interface StyledAccordionContentTextProps {
  isCollapsibleList?: boolean;
}

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
`;

const StyledAccordionItem = styled(AccordionPrimitive.Item)<StyledAccordionItemProps>`
  cursor: pointer;
  background: ${(p) => (p.$isCollapsibleList ? 'transparent' : 'var(--c-border)')};
  border-top: ${(p) => (p.$isCollapsibleList ? '1px solid var(--c-border)' : '')};
  border-radius: ${(p) => (p.$isCollapsibleList ? 0 : 'var(--br-box)')};
  color: var(--c-fg-0);
  font-size: ${(p) => (p.$isCollapsibleList ? 'var(--fs-b2)' : 'var(--fs-b1)')};
  margin-bottom: ${(p) => (p.$isCollapsibleList ? 0 : '6px')};
  h3 {
    /*Override Radix style*/when
    margin: 0;
  }
  &:hover {
    background: ${(p) => (p.$isCollapsibleList ? 'transparent' : 'var(--c-border-light)')};
  }
  &:first-child {
    border-top: none;
  }
`;

const StyledAccordionTrigger = styled(AccordionPrimitive.Trigger)<StyledAccordionTriggerProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  justify-content: space-between;
  font-weight: ${(p) => (p.$isCollapsibleList ? 500 : 450)};
  font-size: ${(p) => (p.$isCollapsibleList ? 'var(--fs-b2)' : 'var(--fs-b1)')};
  padding: ${(p) => (p.$isCollapsibleList ? '10px 0' : 'var(--s-grid-gap)')};
  width: 100%; // Make the entire Trigger clickable

  &:focus-visible {
    outline: 2px solid var(--c-system);
    border-radius: var(--br-box);
  }
  svg {
    width: 32px;
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='open'] > svg {
    transform: rotate(90deg);
  }
`;

const StyledAccordionContentWrapper = styled(
  AccordionPrimitive.Content,
)<StyledAccordionContentWrapperProps>`
  color: var(--c-fg-0-65);
  overflow: hidden;
  line-height: var(--lh-b2);
  font-family: var(--ff-medium);
  font-size: var(--fs-b2);
  font-variation-settings: var(--fo-b2);

  &[data-state='open'] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;

const StyledAccordionContentText = styled.div<StyledAccordionContentTextProps>`
  padding: ${(p) =>
    p.$isCollapsibleList
      ? '0 0 calc(var(--s-grid-gap) / 2) 0'
      : '0 var(--s-grid-gap) var(--s-grid-gap) var(--s-grid-gap)'};
`;

const StyledHead = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeadIcon = styled.div`
  align-self: flex-start;
  margin-right: calc(var(--s-grid-gap) / 2);
  display: flex;
`;

const StyledHeadTitle = styled.div`
  text-align: left;
`;

const StyledTitle = styled.div``;

const StyledSubtitle = styled.div`
  font-weight: 400;
  color: var(--c-fg-1);
  font-size: var(--fs-b3);
  line-height: var(--lh-b3);
  margin: 4px 0;
`;

const StyledTrailIcon = styled.div`
  display: flex;
  opacity: 0.5;

  svg {
    transition: transform 200ms ease-in;
  }

  ${StyledAccordionTrigger}:hover & {
    opacity: 1;
  }

  ${StyledAccordionTrigger}[data-state='open'] & svg {
    transform: rotate(90deg);
  }
`;

interface AccordionProps {
  items: Array<{
    title: string;
    subtitle?: string;
    icon?: React.ReactNode;
    content: React.ReactNode;
    hidden?: boolean;
  }>;
  showCaret?: boolean;
  isCollapsibleList?: boolean;
}
interface AccordionItemProps {
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  showCaret?: boolean;
  isCollapsibleList?: boolean;
  value: string;
  onToggle: (value: string) => void;
}
interface AccordionTriggerProps extends React.ComponentProps<typeof AccordionPrimitive.Trigger> {
  showCaret?: boolean;
  isCollapsibleList?: boolean;
}

const AccordionTrigger = forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, showCaret, isCollapsibleList, ...props }, forwardedRef) => (
    <StyledAccordionTrigger $isCollapsibleList={isCollapsibleList} {...props} ref={forwardedRef}>
      {children}
      {showCaret && (
        <StyledTrailIcon>
          <Caret stroke="var(--c-fg-0)" aria-hidden />
        </StyledTrailIcon>
      )}
    </StyledAccordionTrigger>
  ),
);

AccordionTrigger.displayName = 'AccordionTrigger';

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  subtitle,
  icon,
  children,
  showCaret,
  isCollapsibleList,
  value,
  onToggle,
}) => {
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      // allow clicking links without toggling the accordion item
      if (event.target.closest('a')) return;

      event.preventDefault();
      onToggle(value);
    },
    [onToggle, value],
  );

  const IconComponent = icon as React.ComponentType<any>;
  return (
    <StyledAccordionItem value={value} $isCollapsibleList={isCollapsibleList}>
      <AccordionPrimitive.Header asChild>
        <AccordionTrigger showCaret={showCaret} onClick={handleClick}>
          <StyledHead>
            {icon && (
              <StyledHeadIcon>
                {React.isValidElement(icon) ? icon : <IconComponent stroke="var(--c-fg-0)" />}
              </StyledHeadIcon>
            )}
            <StyledHeadTitle>
              <StyledTitle>{title}</StyledTitle>
              {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
            </StyledHeadTitle>
          </StyledHead>
        </AccordionTrigger>
      </AccordionPrimitive.Header>
      <StyledAccordionContentWrapper>
        <StyledAccordionContentText $isCollapsibleList={isCollapsibleList} onClick={handleClick}>
          <Markdown>{children}</Markdown>
        </StyledAccordionContentText>
      </StyledAccordionContentWrapper>
    </StyledAccordionItem>
  );
};

export const Accordion: React.FC<AccordionProps> = ({ items, showCaret, isCollapsibleList }) => {
  const uniqueId = useId();
  const [openItem, setOpenItem] = useState<string | undefined>(undefined);

  /** Override default Radix Accordion behavior to toggle item on Content click
   * (Radix only toggles item on Trigger click)
   */
  const handleContentClick = useCallback((itemValue: string) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === itemValue ? undefined : itemValue));
  }, []);

  const visibleItems = (items || []).filter((item) => !item.hidden);
  return (
    <AccordionPrimitive.Root
      type="single"
      collapsible
      value={openItem}
      onValueChange={(value) => setOpenItem(value || undefined)}
    >
      {visibleItems.map((item, index) => {
        const itemValue = `item-${index}`;
        return (
          <AccordionItem
            key={`${uniqueId}-${index}`}
            title={item.title}
            subtitle={item.subtitle}
            icon={item.icon}
            showCaret={showCaret}
            isCollapsibleList={isCollapsibleList}
            value={itemValue}
            onToggle={handleContentClick}
          >
            {item.content}
          </AccordionItem>
        );
      })}
    </AccordionPrimitive.Root>
  );
};

export default Accordion;
