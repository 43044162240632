import React, { ReactNode } from 'react';
import styled from 'styled-components';

const COLORS = {
  grey: 'var(--c-bg-2)',
  white: 'var(--c-bg-0)',
};

const SDiv = styled.div`
  background: ${({ $color }) => COLORS[$color]};
  padding: 100px var(--side-margin);
`;

interface Block2Props {
  children: ReactNode;
  color?: 'grey' | 'white';
}

export default function Block2({ color = 'white', ...props }: Block2Props) {
  return <SDiv $color={color} {...props} />;
}
