import React, { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { $t } from 'src/utils';
import { ReactComponent as LoadingPlansIcon } from '../../iconic/sparkles-px-loading.svg';

import Page from '../Page';
import PXLayout from '../PXLayout';
import SessionContext from 'src/context/SessionContext';

const pulse = keyframes`
0%, 100% {
  transform: scale(1);
}
50% {
  transform: scale(1.25);
}
`;

const SLoadingPlansIcon = styled(LoadingPlansIcon)`
  max-width: 50%;
  height: auto;
  animation: ${pulse} 2s infinite;
  transform-origin: center;
`;

const ProgressContainer = styled.div`
  width: 50%;
  background-color: rgba(16, 15, 19, 0.05);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 8px;
`;

const ProgressBar = styled.div`
  height: 8px;
  width: 50%;
  background-color: #ee4b7b;
  position: absolute;
  animation: progress-animation 2.5s infinite;
  border-radius: 4px;

  @keyframes progress-animation {
    0% {
      left: -50%;
    }
    50% {
      left: 100%;
    }
    100% {
      left: -50%;
    }
  }
`;

/**
 * shows interstitial while health plans are loading
 */
const LoadingPlans: React.FC = () => {
  const { isAetnaSessionActive } = useContext(SessionContext);

  return (
    <Page hideBanner hideLinks context="Explorer">
      <PXLayout
        slide={false}
        long
        lightColor={isAetnaSessionActive && 'var(--c-white)'}
        darkColor={isAetnaSessionActive && 'var(--c-white)'}
        precomponent={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '10px',
            }}
          >
            <SLoadingPlansIcon />
          </div>
        }
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '27px',
            }}
          >
            {$t({ id: 'health-plans.loading.title', defaultMessage: 'Loading your plans...' })}
          </div>
        }
        middlecomponent={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ProgressContainer>
              <ProgressBar />
            </ProgressContainer>
          </div>
        }
        subtitle={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              paddingTop: '27px',
            }}
          >
            {$t({
              id: 'health-plans.loading.subtitle1',
              defaultMessage: 'We’re personalizing your plan choices',
            })}
            <br />
            {$t({
              id: 'health-plans.loading.subtitle2',
              defaultMessage: 'based on the information provided',
            })}
          </div>
        }
      />
    </Page>
  );
};

export default LoadingPlans;
