import React, { ReactNode } from 'react';
import styled from 'styled-components';

const SHeader = styled.header`
  max-width: var(--width-read);
  margin: 0 ${({ center }) => (center ? 'auto' : '0px')} 2rem;

  * {
    text-align: ${({ center }) => (center ? 'center' : '')};
  }

  h1 {
    font-family: ${({ creatour }) => (creatour ? 'var(--ff-creatour)' : '')};
    margin-bottom: 24px;
    text-wrap: balance;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 24px;
    text-wrap: balance;
  }

  h3 {
    opacity: 0.75;
    margin: 0;
  }

  b1 {
    font-weight: 400;
    opacity: 0.9;
  }
`;

const PretitlePrimary = styled.span`
  background: linear-gradient(#f5f0fe, #efdefe);
  border-radius: 99px;
  // stay var(--c-fg-0) regardless of dark mode
  color: #111012;
  font-size: var(--fs-eb);
  font-weight: 500;
  padding: 8px 12px;
`;

const SB1 = styled('b1')`
  margin-bottom: 24px;
`;

export interface SectionTitleProps {
  center?: boolean;
  color?: string;
  creatour?: boolean;
  large?: boolean;
  pretitle?: ReactNode;
  pretitleVariant?: 'primary' | 'text';
  promo?: ReactNode;
  subtitle: ReactNode;
  title: ReactNode;
}

export default function SectionTitle({
  center,
  color,
  creatour,
  large,
  pretitle,
  pretitleVariant = 'text',
  promo,
  subtitle,
  title,
}: SectionTitleProps) {
  return (
    <SHeader center={center} creatour={creatour}>
      {!!pretitle &&
        (pretitleVariant === 'primary' ? (
          <PretitlePrimary>{pretitle}</PretitlePrimary>
        ) : (
          <SB1>{pretitle}</SB1>
        ))}
      {!!large ? (
        <>
          <h1 className={!!color && 'mask-gradient'} style={{ backgroundImage: color }}>
            {title}
          </h1>
          <h3 className="r">{subtitle}</h3>
        </>
      ) : (
        <>
          <h2 className={!!color && 'mask-gradient'} style={{ backgroundImage: color }}>
            {title}
          </h2>
          <b1 className="r">{subtitle}</b1>
        </>
      )}
      {!!promo && (
        <b2 className="o50">
          <br />
          {promo}
        </b2>
      )}
    </SHeader>
  );
}
