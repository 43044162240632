import React, { useMemo } from 'react';
import styled from 'styled-components';
import { HealthPlanAnonymous } from 'src/types/health';
import { metalColors } from 'src/utils';

type PlanCountableProperty = 'premium' | 'deductible';

interface PriceChartProps {
  plans?: Array<HealthPlanAnonymous>;
  max?: number;
  property: PlanCountableProperty;
}

const numBuckets = 28;

export const PriceChart = ({ plans, max, property, color, isDental }: PriceChartProps) => {
  const bucketSize = useMemo(() => (max || 0) / numBuckets, [max]);

  const buckets = useMemo(
    () =>
      Array(numBuckets || 1)
        ?.fill({})
        ?.map((el, idx) => {
          const _plans = plans?.filter(
            (p) => p[property] > idx * bucketSize && p[property] <= (idx + 1) * bucketSize,
          );
          const infil = _plans?.filter((plan) => plan?.isInFilter);
          const gold =
            infil?.filter((plan) => plan?.metal?.toLowerCase() === 'gold')?.length / _plans?.length;
          const silver =
            infil?.filter((plan) => plan?.metal?.toLowerCase() === 'silver')?.length /
            _plans?.length;
          const bronze =
            infil?.filter((plan) => plan?.metal?.toLowerCase() === 'bronze')?.length /
            _plans?.length;
          const plat =
            infil?.filter((plan) => plan?.metal?.toLowerCase() === 'platinum')?.length /
            _plans?.length;
          const high =
            infil?.filter((plan) => plan?.metal?.toLowerCase() === 'high')?.length / _plans?.length;
          const low =
            infil?.filter((plan) => plan?.metal?.toLowerCase() === 'low')?.length / _plans?.length;
          return {
            start: idx * bucketSize,
            plans: _plans,
            inFilter: infil?.length / _plans?.length,
            gold,
            silver,
            bronze,
            plat,
            high,
            low,
          };
        }),
    [plans, max, isDental],
  );

  const maxPlansPerBucket = useMemo(
    () => Math.max(...buckets?.map((b) => b?.plans?.length)) || 1,
    [buckets],
  );

  return (
    <SPriceChart className="flex-h" color={color}>
      {buckets?.map((bucket, idx) => {
        const numPlans = bucket?.plans?.length || 0;
        const height = useMemo(() => (100 * numPlans) / maxPlansPerBucket, [numPlans]);
        return isDental ? (
          <SPriceBar key={idx} height={height}>
            <SPriceBarInner className="bar" inFilter={100 * bucket.high} color={metalColors.gold} />
            <SPriceBarInner
              className="bar"
              inFilter={100 * bucket.low}
              color={metalColors.silver}
            />
          </SPriceBar>
        ) : (
          <SPriceBar key={idx} height={height}>
            <SPriceBarInner
              className="bar"
              inFilter={100 * bucket.plat}
              color={metalColors.platinum}
            />
            <SPriceBarInner className="bar" inFilter={100 * bucket.gold} color={metalColors.gold} />
            <SPriceBarInner
              className="bar"
              inFilter={100 * bucket.silver}
              color={metalColors.silver}
            />
            <SPriceBarInner
              className="bar"
              inFilter={100 * bucket.bronze}
              color={metalColors.bronze}
            />
          </SPriceBar>
        );
      })}
    </SPriceChart>
  );
};

const SPriceChart = styled.div`
  position: relative;
  height: 64px;
  align-items: flex-end !important;
  overflow: hidden;
  padding-top: 8px;
  &:not(:hover) .bar {
    background: ${(p) => p.color || 'var(--c-fg-0)'};
  }
`;

const SPriceBar = styled.div`
  bottom: 0px;
  width: 100%;
  border-radius: 2px;
  flex: 1;
  height: ${(p) => p.height}%;
  margin-right: 2px;
  background: var(--c-border-light);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
`;

const SPriceBarInner = styled.div`
  height: calc(${(p) => p.inFilter}%);
  background: ${(p) => p.color || 'var(--c-fg-0)'};
  transition: 0.25s cubic-bezier(0.21, 0.49, 0.14, 1.2);
  will-change: height, background;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  border-radius: 0px;
  box-shadow: inset 0 -1px 0 0 #ffffff66;
  @media (prefers-color-scheme: dark) {
    box-shadow: inset 0 -1px 0 0 #00000066;
  }
`;

export default PriceChart;
