import clsx from 'clsx';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

const SDiv = styled.div`
  border-radius: 72px;
  padding: ${({ $size }) => ($size === 'small' ? '48px 118px' : '100px 60px')};

  ${({ $color }) =>
    $color === 'purple' &&
    css`
      background: var(--c-purple);
      color: var(--c-white);
    `}

  ${({ $color }) =>
    $color === 'black' &&
    css`
      background: #000;
      color: var(--c-white);
    `}
  
  ${({ $color }) =>
    $color === 'white' &&
    css`
      background: var(--c-bg-0);
      color: var(--c-fg-0);
    `}
  
  ${({ $size }) =>
    $size === 'medium' &&
    css`
      @media (max-width: 768px) {
        padding-right: var(--side-margin);
        padding-left: var(--side-margin);
      }
    `}
  
  @media (max-width: 568px) {
    padding-right: var(--side-margin);
    padding-left: var(--side-margin);
  }
`;

interface BoxProps {
  children: ReactNode;
  className?: string;
  color: 'purple' | 'black' | 'white';
  size?: 'medium' | 'small';
}

export default function Box({ className, color, size = 'medium', ...props }: BoxProps) {
  return (
    <SDiv
      $color={color}
      $size={size}
      className={clsx(color === 'black' && 'dark', className)}
      {...props}
    />
  );
}
