import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Row2, Text } from 'src/components';

const SH2 = styled(Text)`
  font-size: 16px;
`;

const STitle = styled(Text)`
  font-size: var(--fs-h1);
  margin: 0;
`;

const SP = styled(Text)`
  // TODO
  margin: 24px 0 0 !important;
`;

interface TitleProps {
  children: ReactNode;
  pretitle?: ReactNode;
  subtitle?: ReactNode;
}

export default function Title({ children, pretitle, subtitle }: TitleProps) {
  return (
    <Row2 element="header" size={!!subtitle ? undefined : 'xsmall'}>
      {pretitle && (
        <Row2>
          <SH2 element="h2" casing="upper">
            {pretitle}
          </SH2>
        </Row2>
      )}
      <STitle element="h2">{children}</STitle>
      {subtitle && (
        <SP element="p" flush size="large">
          {subtitle}
        </SP>
      )}
    </Row2>
  );
}
