import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Icon, IconProps, IconType } from 'src/kit';

const GAPS = {
  large: 12,
  medium: 6,
};

const SDiv = styled.span`
  display: ${({ $block }) => ($block ? 'flex' : 'inline-flex')};
  align-items: center;
  gap: ${({ $gap }) => GAPS[$gap]}px;
`;

interface TextWithIconProps {
  block?: boolean;
  children: ReactNode;
  gap?: 'large' | 'medium';
  icon?: IconType;
  iconProps?: Partial<IconProps>;
}

export default function TextWithIcon({
  block,
  children,
  gap = 'medium',
  icon,
  iconProps = {},
  ...props
}: TextWithIconProps) {
  return (
    <SDiv as={block ? 'div' : 'span'} $block={block} $gap={gap} {...props}>
      {!!icon && <Icon type={icon} {...iconProps} />}
      {children}
    </SDiv>
  );
}
