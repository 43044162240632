import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  overflow: visible;
  padding-right: var(--side-margin);
  padding-left: var(--side-margin);
  background: none;
  > div {
    max-width: ${(p) => (p.smallInner ? 980 : '')}px;
    margin: auto;
  }
`;

const Container = ({ children, body, small, xs, smallInner, maxMobile }) => {
  return (
    <StyledContainer
      maxMobile={maxMobile}
      body={body}
      small={small}
      xs={xs}
      smallInner={smallInner}
    >
      <div>{children}</div>
    </StyledContainer>
  );
};

export default Container;
