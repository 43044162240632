import React, { useId } from 'react';
import styled, { css } from 'styled-components';
import { Input2, Input2Props } from 'src/components';

const SLabel = styled.label`
  ${({ $hidden }) =>
    $hidden &&
    css`
      width: 1px;
      height: auto;
      border: 0;
      clip: rect(0 0 0 0);
      margin: 0;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
    `}
`;

const SInput2 = styled(Input2)`
  border: 0 !important;
`;

export interface FieldProps extends Input2Props {
  label: string;
  labelAsPlaceholder?: boolean;
  labelHidden?: boolean;
  placeholder?: string;
}

export default function Field({
  label,
  labelAsPlaceholder,
  labelHidden,
  placeholder = '',
  ...props
}: FieldProps) {
  const id = useId();

  return (
    <>
      <SLabel htmlFor={id} $hidden={labelHidden || labelAsPlaceholder}>
        {label}
      </SLabel>
      <SInput2 {...props} id={id} placeholder={labelAsPlaceholder ? label : placeholder} />
    </>
  );
}
