import React from 'react';
import styled from 'styled-components';

const SGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(p) => p.num}, 1fr);
  grid-gap: var(--s-grid-gap);
  @media (max-width: 900px) {
    grid-template-columns: ${(p) => (p.noResponsive ? ' ' : '1fr 1fr')};
  }
  @media (max-width: 720px) {
    grid-template-columns: ${(p) => (p.noResponsive ? ' ' : '1fr')};
  }

  ${(p) => p.top && 'padding-top: 24px;'}
`;

export const Grid = ({ num = 3, children, top, noResponsive, style }) => {
  return (
    <SGrid num={num} noResponsive={noResponsive} top={top} style={style}>
      {children}
    </SGrid>
  );
};

export default Grid;
