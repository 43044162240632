import React from 'react';

interface SectionProps {
  title: string;
  subtitle?: string;
  children?: any;
  onInfo?: any;
}

export const Section = ({ title, subtitle, children, onInfo }: SectionProps) => {
  return (
    <section
      style={{
        maxWidth: '100%',
        marginBottom: 'var(--side-margin)',
      }}
    >
      <b2
        is="div"
        className={`${!!onInfo && 'underline '}m fader`}
        onClick={!!onInfo ? onInfo : () => {}}
      >
        {title}
      </b2>
      <b3
        is="div"
        className="fader"
        style={{ marginTop: -1, marginBottom: -2, color: 'var(--c-fg-1)' }}
      >
        {subtitle}
      </b3>
      <div style={{ padding: '12px 0' }}>{children}</div>
    </section>
  );
};
