import React, { useMemo, useState, useEffect, useContext } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useQuery, GET_SESSION } from '../data';
import { useLocalStorage } from 'src/utils/useLocalStorage';
import ENV from 'src/utils/env';
import client from 'src/gatsby-plugin-apollo/client';
import { Benefit } from 'src/types/session';
import { Segment, SegmentEvent } from 'src/lib';
import { BenefitsAdvisorContext } from 'src/types';
import { goTo } from 'src/utils/links';
import { usePublicSession } from 'src/hooks/usePublicSession';
import { inIframe } from 'src/utils/browser';
import { useAetnaSession } from 'src/hooks';

const SessionContext = React.createContext({});

export interface QueryParams {
  sid?: string;
  theme?: string;
  partner?: string;
  zip?: string;
  state?: string;
  income?: number;
  applicants?: string;
  r?: string;
  utm_source?: string;
  year?: number;
}

interface ParamOptions {
  email?: string;
  r: string;
  state?: string;
  phone?: string;
  source?: string;
  pathway?: string;
  partner?: string;
  benefit?: string;
  planId?: string;
  hid?: string;
  income?: string;
  zip?: string;
  isAetnaSessionActive?: boolean;
  partner_param?: string;
  cid?: string;
  year?: number;
}

const makeParamString = (options: Partial<ParamOptions>): string => {
  const params = new URLSearchParams('');
  //params that follow standard (key, value) pattern:
  const standardParams: (keyof ParamOptions)[] = [
    'email',
    'r',
    'state',
    'phone',
    'source',
    'pathway',
    'partner',
    'planId',
    'hid',
    'income',
    'year',
    'zip',
    'partner_param',
    'cid',
  ];

  standardParams.forEach((key) => {
    if (options[key] !== undefined) {
      params.append(key, options[key].toString());
    }
  });

  //params that have special cases:
  if (options.r) {
    params.append('hr', 'true');
  }
  if (options.benefit) params.append('benefit', options.benefit.toUpperCase());
  if (options.isAetnaSessionActive) {
    params.append('white_label', 'aetna');

    if (!options.r) {
      params.append('r', 'aetna');
    }
  }

  return params.toString();
};

/**
 *
 * WHAT IS THIS, YOU ASK?
 *
 * for context (ha), this context/provider was originally the one for the PX, when the website proper was a separate app
 *
 */
const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const [query] = useQueryParams({
    theme: StringParam,
    partner: StringParam,
    r: StringParam,
    utm_source: StringParam,
    logo: StringParam,
    partner_param: StringParam,
    cid: StringParam,
  });

  const { sessionID: session } = usePublicSession(true);

  // store in local storage
  const [partner, setPartner] = useLocalStorage('catch_partner', query.partner || query.r); // this should be the slug!
  const [firstCode, setFirstCode] = useLocalStorage('catch_first_code');
  const [code, setCode] = useLocalStorage('catch_latest_code', query.r);
  const [vertical, setVertical] = useLocalStorage('catch_vertical');
  const [source, setSource] = useLocalStorage('catch_source');
  const [partner_param] = useLocalStorage('catch_partner_param', query.partner_param);

  // note: the reason we pass through this function is because of useLocalStorage
  // if we do not, these values do not update the initial session
  // and therefore are not appended to signup URL properly
  const { isAetnaSessionActive, clearAetnaSession } = useAetnaSession({
    storeSignupCode: (code) => {
      setPartner(code);
      setCode(code);
      if (!firstCode) setFirstCode(code);
    },
  });

  // when logo=hidden is specified in an iframe, hide the partner logo
  const hideLogo = useMemo(() => {
    return inIframe() && query.logo === 'hidden';
  }, [query.logo]);

  // handles setting the first code if not yet set
  useEffect(() => {
    if (!firstCode) setFirstCode(query.r);
  }, [firstCode, query.r]);

  const [healthContext, setHealthContext] = useLocalStorage<Benefit | undefined>(
    'catch_hctx',
    undefined,
  );

  const [theme, setTheme] = useState(query.theme);
  const [mode, setMode] = useState();
  const [progress, setProgress] = useState();

  const { data, loading, error, refetch } = useQuery(GET_SESSION, {
    client,
    variables: { id: session },
    skip: !session,
  });

  const identify = () => {
    Segment.identify({
      access_code: code,
      access_code_first: firstCode,
    });
  };

  const params = makeParamString({
    hid: session,
    r: code || firstCode || partner,
    benefit: healthContext,
    partner,
    isAetnaSessionActive,
    partner_param,
    cid: query?.cid || undefined,
  });

  const signupUrl = `${ENV.appSignUpUrl}?${params}`;
  const signinUrl = `${ENV.appSignInUrl}?${params}`;
  const conciergeUrl = `${ENV.appSignUpUrl}?${params}&direct=benefits_advisor`;

  const handleConcierge = (context: BenefitsAdvisorContext) => {
    Segment.track(SegmentEvent.TALK_TO_BENEFITS_ADVISOR_CLICKED, { context });
    goTo(conciergeUrl, { showCountdown: true });
  };

  return (
    <SessionContext.Provider
      value={{
        partner,
        setProgress,
        signupUrl,
        signinUrl,
        handleConcierge,
        identify,
        pxId: session,
        adminPxLink: `${ENV.adminUrl}/explorer/${session}`,
        isAetnaSessionActive,
        clearAetnaSession,
        id: session,

        data,
        loading,
        error,
        mode,
        theme,
        code,
        canSearchPlans: true,

        progress,
        refetch,
        setPartner,
        setMode,
        setTheme,
        setCode,
        pxUrl: signinUrl,
        healthContext,
        setHealthContext,
        vertical,
        setVertical,
        source,
        setSource,
        hideLogo,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

const useSessionContext = () => useContext(SessionContext);

export default SessionContext;
export { SessionProvider, SessionContext, useSessionContext };
