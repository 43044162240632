import clsx from 'clsx';
import React, { ReactNode } from 'react';

// Transitionary component to get rid of invalid `b2` elements
// If it needs to persist longterm, it should do so eventually as a `variant` on Text

interface TextEbProps {
  children: ReactNode;
  className?: string;
}

export default function TextEb({ className, ...props }: TextEbProps) {
  return <div {...props} className={clsx('b2', className)} />;
}
