import React, { useEffect, useTransition } from 'react';
import { formatCurrency } from 'src/utils';

interface RangeProps {
  min?: number;
  max: number;
  step?: number;
  value: number;
  onChange(value: number): any;
  color?: string;
}

export const Range = ({ min = 0, max, step = 1, value, onChange, color }: RangeProps) => {
  const [localValue, setLocalValue] = React.useState<Number>(Math.ceil(parseFloat(value)));
  const [_, startTransition] = useTransition();

  useEffect(() => {
    setLocalValue(Math.ceil(parseFloat(value)));
  }, [max, value]);

  return (
    <div style={wrapper}>
      <input
        type="range"
        min={min}
        max={Math.ceil(max)}
        step={step}
        value={localValue}
        onChange={(e) => {
          const v = Math.ceil(parseFloat(e.target.value));
          setLocalValue(v);
          startTransition(() => {
            onChange(v);
          });
        }}
      />
      <div style={{ width: `calc(100% - 24px )` }}>
        <div
          style={{
            ...activeTrack,
            background: color,
            width: `${(100 * localValue) / max}%`,
            willChange: 'width',
          }}
        >
          <div style={text}>{formatCurrency({ value: localValue })}</div>
        </div>
      </div>
    </div>
  );
};

const activeTrack = {
  height: 1.5,
  borderRadius: 2,
  background: 'var(--c-fg-3)',
  marginTop: -16,
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: 0,
};

const text = {
  marginTop: 16,
  fontSize: 'var(--fs-b3)',
  color: 'var(--c-fg-0)',
  fontWeight: 500,
  width: 64,
  height: 24,
  marginRight: -40,
  textAlign: 'center',
  fontVariantNumeric: 'tabular-nums',
};

const wrapper = {
  height: 24,
};

export default Range;
