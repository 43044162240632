import React, { useId } from 'react';
import styled from 'styled-components';

import { ReactComponent as Close } from '../iconic/minus.svg';
import { $t } from 'src/utils';

const StyledPerson = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs-b2);
  box-sizing: border-box;
  color: var(--fg);
  font-weight: 500;
  padding: 8px 8px 8px 16px;
  border: 1px solid var(--c-border);
  border-radius: 0;
  border-bottom: none;
  width: 100%;
  height: 64px;
  box-sizing: border-box;
  &:last-child {
    border-bottom: 1px solid var(--c-border);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  &:first-child {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  .close-icon {
    margin-right: 12px;
    height: 24px;
    width: 24px;
    opacity: 0.75;
    color: ${(p) => p.color};
    background: var(--c-red-1);
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
`;

const StyledInput = styled.input`
  color: var(--c-fg-0);
  background: var(--c-border-light);
  font-weight: 500;
  font-size: var(--fs-b1);
  border-radius: 12px;
  font-feature-settings: 'tnum';
  padding: 12px 16px;
  max-width: 100%;
  width: 72px;
  text-align: center;
  box-sizing: border-box;
  transition: 0.15s;
  border: 1px solid var(--c-input-border-unfocused);

  &:focus {
    border-color: var(--c-fg-0);
    background: var(--bgaa);
  }
  
  ::placeholder {
    color: var(--c-input-placeholder-text);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`;

const StyledLegend = styled.legend`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const StyledRadioWraper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  margin-left: 8px;
`;

const StyledRadioLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => (p.active ? 'var(--c-border-light)' : 'transparent')};
  color: var(--c-fg-0);
  font-weight: 500;
  font-size: var(--fs-b3);
  width: 48px;
  height: 48px;
  border-radius: 12px;
  border: 1px solid var(--c-input-border-unfocused);
  margin-left: 4px;
  cursor: pointer;
  transition:
    background 0.15s,
    border-color 0.15s;
  box-sizing: border-box;
  text-align: center;
  align-self: stretch;

  &:hover {
    background: var(--c-border-light);
  }

  &:focus {
    outline: 1px solid var(--c-fg-0);
  }
`;

const VisuallyHiddenRadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  &:focus + ${StyledRadioLabel} {
    outline: 1px solid var(--c-fg-0);
  }
`;

const translation = {
  SELF: $t({ id: 'relationship.SELF', defaultMessage: 'Me' }),
  SPOUSE: $t({ id: 'relationship.SPOUSE', defaultMessage: 'My Spouse' }),
  CHILD: $t({ id: 'relationship.CHILD', defaultMessage: 'My Child' }),
};

export interface Person {
  relation: 'SELF' | 'SPOUSE' | 'CHILD';
  age: Number;
  sex: 'MALE' | 'FEMALE';
  isApplying?: boolean;
}

export interface EditablePersonProps {
  onRemove: () => void;
  person: Person;
  isMe: boolean;
  onChange: (updatedPerson: Person) => void;
  placeholder?: boolean;
}

export const EditablePerson: React.FC<EditablePersonProps> = ({
  onRemove,
  person,
  isMe,
  onChange,
  placeholder,
}) => {
  const inputId = useId();
  return (
    <StyledPerson isPlaceholder={placeholder}>
      <div className="flex-h">
        {!isMe && (
          <div className="close-icon" onClick={!isMe && !placeholder ? onRemove : undefined}>
            <Close color="var(--c-red-0)" strokeWidth="2" />
          </div>
        )}
        <label htmlFor={inputId}>{translation[person?.relation]}</label>
      </div>
      <div className="flex-h">
        <StyledInput
          required
          name={inputId}
          type="number"
          value={
            person?.age !== undefined && person?.age !== null ? parseInt(person.age.toString()) : ''
          }
          inputMode="numeric"
          placeholder="Age"
          pattern="[0-9]{1-3}"
          onChange={(e) =>
            onChange({
              ...person,
              age: Math.max(0, parseInt(e.target.value)),
              isApplying: true,
            })
          }
        />
        <StyledFieldset>
          <StyledLegend>Select gender</StyledLegend>
          <StyledRadioWraper>
            <VisuallyHiddenRadioInput
              type="radio"
              id={`${inputId}_male`}
              name={`${inputId}_sex`}
              value="MALE"
              checked={person?.sex === 'MALE'}
              onChange={() =>
                onChange({
                  ...person,
                  sex: 'MALE',
                  isApplying: true,
                })
              }
            />
            <StyledRadioLabel active={person?.sex === 'MALE'} htmlFor={`${inputId}_male`}>
              M
            </StyledRadioLabel>
            <VisuallyHiddenRadioInput
              type="radio"
              id={`${inputId}_female`}
              name={`${inputId}_sex`}
              value="FEMALE"
              checked={person?.sex === 'FEMALE'}
              onChange={() =>
                onChange({
                  ...person,
                  sex: 'FEMALE',
                  isApplying: true,
                })
              }
            />
            <StyledRadioLabel active={person?.sex === 'FEMALE'} htmlFor={`${inputId}_female`}>
              F
            </StyledRadioLabel>
          </StyledRadioWraper>
        </StyledFieldset>
      </div>
    </StyledPerson>
  );
};

export default EditablePerson;
