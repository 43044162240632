import React from 'react';
import { PXSection as Section } from 'src/components';

const Medichip = ({ isMedicaid, isChip }) => (
  <div>
    {!!isMedicaid && (
      <Section
        title="Your family may qualify for Medicaid"
        subtitle="Medicaid provides free or affordable coverage to families that qualify based on
        household income."
      />
    )}
    {!!isChip && <Section title="Your children may qualify for CHIP" />}
  </div>
);

export default Medichip;
