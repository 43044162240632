import { formatCurrency, formatPercentage } from 'src/utils/format';

export const usePlanCoverage = (plan) => {
  const ben = plan?.benefits?.map((b) => {
    return {
      ...b,
      cost: b?.costSharings?.filter((cs) => cs?.networkTier === 'In-Network')[0],
    };
  });

  const ben2 = ben?.map((b) => {
    return {
      ...b,
      cost:
        b?.cost?.coinsuranceRate === 0 && b?.cost?.copayAmount === 0
          ? 'No Cost'
          : !!b?.cost?.coinsuranceRate
            ? `${formatPercentage({
                number: b?.cost?.coinsuranceRate,
                numDecimals: 0,
              })} cost`
            : `${formatCurrency({ value: b?.cost?.copayAmount })}`,
    };
  });

  const getServiceCost = (service) => {
    const getBenefit = (ben) => new RegExp(service).test(ben.name);
    const arr = ben2?.filter(getBenefit)?.[0];

    return {
      ...arr,
      costSharings: arr?.costSharings?.filter((b) => b.networkTier !== 'Out-of-Network'),
    };
  };

  return {
    coverage: ben2,
    topLevelItems: [
      getServiceCost('Primary'),
      getServiceCost('Preventive'),
      getServiceCost('Specialist'),
      getServiceCost('Drugs'),
    ],
  };
};
